import { useQuery, UseQueryResult } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { QuestionnaireQuestionOption, QuestionnaireType } from './types';
import { questionnaireQuestionsQueryKey } from './queryKeys';

interface FetchQuestionnaireQuestionsParams {
  roomID: number;
  questionnaireType: QuestionnaireType;
}

type FetchQuestionnaireQuestionsPesponse = Array<Question>;

export interface Question {
  description?: string;
  order: number;
  id: number;
  text: string;
  questionnaireQuestionOptions: Array<QuestionnaireQuestionOption>;
  type: 'boolean' | 'multi';
  deletedAt: Date | null;
}

const fetchQuestionnaireQuestions =
  (params: FetchQuestionnaireQuestionsParams) =>
  async (): Promise<FetchQuestionnaireQuestionsPesponse> => {
    const { roomID, questionnaireType } = params;
    const data = await apiWrapper.get(
      `${
        apiHelper().apiEndpoint
      }/v2/rooms/${roomID}/questionnaires/types/${questionnaireType}/questions`
    );
    return data.data || [];
  };

const useQueryQuestionnaireQuestions = (
  params: FetchQuestionnaireQuestionsParams
): UseQueryResult<FetchQuestionnaireQuestionsPesponse, Error> =>
  useQuery<FetchQuestionnaireQuestionsPesponse, Error>({
    queryKey: questionnaireQuestionsQueryKey(params.questionnaireType, params.roomID),
    queryFn: fetchQuestionnaireQuestions(params),
  });

export default useQueryQuestionnaireQuestions;
