import {
  TextDS,
  View,
  Link,
  BannerCard,
  ExclamationCircle,
  useEmotionTheme,
  EmotionThemeProvider,
} from '@talkspace/react-toolkit';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Col, Panel } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import Browser from 'ts-ionic/plugins/browser';
import { useIonicEffect } from 'ts-ionic';
import { addIonicAppListener } from 'ts-ionic/plugins/capacitor';
import styled from '@/core/styled';
import useScrollIntoView from '../../../hooks/useScrollIntoView';
import CalendarSyncTooltip from './components/CalendarSyncTooltip';
import { getUserData } from '../../../utils/token';
import apiHelper from '../../../modules/utils/api';
import useQueryCalendarSyncStatus, {
  OAuthProviderName,
} from '../../../hooks/calendarSync/useQueryCalendarSyncStatus';
import CalendarProvider from './components/CalendarProvider';
import googleCalendarImg from './assets/googleCalendarImg.png';
import CalendarPreferenceSync from './components/CalendarPreferenceSync';
import useMutationCalendarSync from '../../../hooks/calendarSync/useMutationCalendarSync';
import { OAUTH_RETURN_STATUS } from './types';
import useMutationCalendarDisconnect from '../../../hooks/calendarSync/useMutationCalendarDisconnect';
import useMutationOAuthRevoke from '../../../hooks/calendarSync/useMutationOAuthRevoke';
import ErrorModal from './modals/ErrorModal';
import ssoHelper from '../../../modules/utils/sso';
import { isIonic } from '../../../modules/auth/reactFrame/helpers';
import appConfig from '../../../utils/configs';

export const LEARN_MORE_CALENDAR_SYNC_LINK = '/hc/en-us/articles/31614180283803/';

interface CalendarSyncProps {
  allSectionsLoaded: boolean;
}

const StyledLink = styled(Link)(({ theme: { colorRoles } }) => {
  return {
    color: colorRoles.typography.textBrandDefault,
    fontWeight: 600,
    underline: 'none',
  };
});

const CalendarSync = ({ allSectionsLoaded }: CalendarSyncProps) => {
  const { colorRoles } = useEmotionTheme();
  const calendarSyncRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const scrollTo = searchParams.get('scrollTo');
  const therapistID = getUserData().id;
  const [oAuthProvider, setOAuthProvider] = useState<OAuthProviderName>('google');
  const [isCalendarSyncError, setIsCalendarSyncError] = useState(false);

  const { data: calendarSyncData, isLoading: isCalendarSyncStatusLoading } =
    useQueryCalendarSyncStatus({
      therapistUserID: therapistID,
      provider: oAuthProvider,
    });
  const { mutate: postCalendarSync, isLoading: isCalendarSyncLoading } = useMutationCalendarSync();
  const { mutate: postCalendarDisconnect, isLoading: isCalendarDisconnectLoading } =
    useMutationCalendarDisconnect();
  const { mutate: postRevokeOAuthToken, isLoading: isRevokeOAuthTokenLoading } =
    useMutationOAuthRevoke();

  const handleInitialSync = useCallback(
    (urlStatus?: string) => {
      const status = urlStatus || searchParams.get('status');
      if (status === OAUTH_RETURN_STATUS.SUCCESS) {
        postCalendarSync(
          { therapistID, provider: oAuthProvider },
          {
            onSuccess: () => {
              history.replace(location.pathname);
            },
            onError: () => {
              history.replace(location.pathname);
              setIsCalendarSyncError(true);
            },
          }
        );
      } else if (status === OAUTH_RETURN_STATUS.AUTH_CONNECTION_ERROR) {
        setIsCalendarSyncError(true);
      }
    },
    [searchParams, postCalendarSync, therapistID, oAuthProvider, history, location.pathname]
  );

  useEffect(() => {
    handleInitialSync();
  }, [handleInitialSync]);

  useScrollIntoView({
    ref: calendarSyncRef,
    trigger: scrollTo === 'calendarSync',
    options: { behavior: 'smooth' },
    allSectionsLoaded,
  });

  useIonicEffect(() => {
    const listener = addIonicAppListener('appUrlOpen', async (event) => {
      const url = new URL(event.url);
      const urlStatus = url.searchParams.get('status');
      if (urlStatus) {
        Browser?.close();
        handleInitialSync(urlStatus);
      }
    });
    return () => {
      listener.remove();
    };
  }, [handleInitialSync]);

  const handleConnect = async (provider: OAuthProviderName) => {
    setOAuthProvider(provider);

    const origin = isIonic() ? `${appConfig.ionicDeepLinkScheme}:/` : window.location.origin;
    const currentUrl = encodeURIComponent(origin + window.location.pathname);

    switch (provider) {
      case 'google':
        await Browser.open({
          url: `${
            apiHelper().authAPIEndpoint
          }/v1/oauth2/passport/google/therapist?userID=${therapistID}&redirectURL=${currentUrl}`,
        });
        break;
      default:
        break;
    }
  };

  const handleDisconnect = (provider: OAuthProviderName) => {
    postCalendarDisconnect(
      { therapistID, provider },
      {
        onSettled: () => {
          postRevokeOAuthToken({ userID: therapistID, provider });
        },
      }
    );
  };

  const handleTryAgain = () => {
    setIsCalendarSyncError(false);
    handleConnect(oAuthProvider);
    history.replace(location.pathname);
  };

  if (isCalendarSyncStatusLoading) {
    return null;
  }

  const isLoading =
    isCalendarSyncLoading || isCalendarDisconnectLoading || isRevokeOAuthTokenLoading;

  return (
    <EmotionThemeProvider version="2.0.0">
      <ErrorModal
        isOpen={isCalendarSyncError}
        onBackdropPress={() => {
          setIsCalendarSyncError(false);
          history.replace(location.pathname);
        }}
        title="Something went wrong"
        subtitle="We are unable to complete your request. Please check your internet connection and try again."
        onPress={handleTryAgain}
      />

      <div id="calendarSync">
        <Col xs={12}>
          <Col xs={6} sm={6} className="ts-font-black">
            <View align="center" row ref={calendarSyncRef}>
              Calendar Sync
            </View>
          </Col>
        </Col>

        <Col xs={12}>
          {Boolean(calendarSyncData?.shouldReconnect) && (
            <BannerCard
              variant="critical"
              subtitle="Your calendar has been disconnected. Please reconnect to enable syncing."
              style={{ maxWidth: 'unset', borderRadius: '12px 12px 0 0' }}
              icon={<ExclamationCircle color={colorRoles.surfaces.criticalBoldDefault} />}
            />
          )}
          <Panel className="ts-my-account-panel">
            <TextDS variant="bodySm" inline>
              Gain control over your schedule and avoid double booking. When you connect your
              external calendar, Talkspace bookings and availability will appear there.{' '}
              <CalendarSyncTooltip /> and HIPAA compliant.
            </TextDS>

            <CalendarProvider
              isLoading={isLoading}
              title="Google"
              subtitle="Gmail or GSuite accounts"
              syncedEmail={calendarSyncData?.syncedEmail}
              isConnected={Boolean(calendarSyncData?.isConnected)}
              handleConnect={() => handleConnect('google')}
              handleDisconnect={() => handleDisconnect('google')}
              logoSrc={googleCalendarImg}
              shouldReconnect={Boolean(calendarSyncData?.shouldReconnect)}
              handleReconnect={() => {
                handleDisconnect('google');
                handleConnect('google');
              }}
            />

            {Boolean(calendarSyncData?.isConnected) && (
              <CalendarPreferenceSync oAuthProvider={oAuthProvider} />
            )}

            <TextDS variant="bodySm">
              Have questions?
              <StyledLink
                target="_blank"
                onClick={() => ssoHelper.openZendesk(LEARN_MORE_CALENDAR_SYNC_LINK)}
              >
                Read more about calendar sync
              </StyledLink>
            </TextDS>
          </Panel>
        </Col>
      </div>
    </EmotionThemeProvider>
  );
};

export default CalendarSync;
