import { useState } from 'react';
import { Spinner, styled, TextDS, useWindowWidthState, View } from '@talkspace/react-toolkit';

import { formatLastUpdatedAt } from '../utils/date';
import ResponsiveTooltip from './ResponsiveTooltip';
import { getUserData } from '../../../../utils/token';
import useQueryClinicalHours from '../queries/useQueryClinicalHours';

const DateView = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    gap: spacing('space100'),
    alignItems: 'center',
  };
});

const TooltipContentView = styled(View)(({ theme: { spacing } }) => {
  return {
    padding: spacing('space200'),
    gap: spacing('space100'),
  };
});

const TooltipContent = () => (
  <TooltipContentView>
    <TextDS>
      Your clinical engagement hours for <b>completed sessions</b> will update only once daily at
      the time listed. Any new <b>upcoming live sessions</b> or <b>upcoming PTO</b> that you add
      will update in real time.
    </TextDS>
  </TooltipContentView>
);

const MainView = styled(View)(
  ({
    theme: {
      window: { isMobile, isTablet },
      spacing,
    },
  }) => {
    return {
      flexDirection: isMobile || isTablet ? 'column' : 'row',
      gap: spacing(isMobile || isTablet ? 'space050' : 'space300'),
      alignItems: 'baseline',
    };
  }
);

const TitleView = () => {
  const { isMobile } = useWindowWidthState();

  const therapistID = getUserData().id;

  const { data, isLoading } = useQueryClinicalHours(therapistID);

  const [isLegendOpen, setIsLegendOpen] = useState(false);

  if (isLoading || !data) {
    return <Spinner />;
  }

  return (
    <MainView>
      <TextDS variant={isMobile ? 'headingXl' : 'heading2xl'}>Clinical engagement hours</TextDS>

      {data.lastUpdatedAt && (
        <DateView>
          <TextDS variant="bodySm" colorRole="textSubtlest">
            Last updated on {formatLastUpdatedAt(data.lastUpdatedAt)}
          </TextDS>
          <ResponsiveTooltip
            isOpen={isLegendOpen}
            onDrawerOpen={() => setIsLegendOpen(true)}
            onDrawerClose={() => setIsLegendOpen(false)}
            legendContent={<TooltipContent />}
          />
        </DateView>
      )}
    </MainView>
  );
};

export default TitleView;
