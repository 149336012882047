import AUTOMATIC_WELCOME_MESSAGE_FEATURE_NAME from '../constants/featureViews';

export const featureNames = [
  AUTOMATIC_WELCOME_MESSAGE_FEATURE_NAME,
  'calendarSync',
  'fewEapSessionsLeft',
  'noEapSessionsLeft',
  'eapUpdateCoverageCompleted',
] as const;

export type FeatureName = typeof featureNames[number];
