import {
  useWindowWidthState,
  View,
  styled,
  Modal,
  BaseButton,
  Button,
  CloseButton,
  Small,
  EmotionStyle,
  useEmotionTheme,
} from '@talkspace/react-toolkit';

const Footer = styled(View)(({ theme: { colors } }) => {
  return {
    borderTop: `solid ${colors.permaPowderBlue} 1px`,
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 25,
    paddingRight: 15,
    width: '100%',
  };
});

const PrimaryButton = styled(Button)(({ theme: { colors } }) => {
  return {
    height: 34,
    minHeight: 34,
    width: 156,
    padding: 0,
    color: 'white',
    fontSize: 14,
    fontWeight: 700,
    borderRadius: 8,
    backgroundColor: colors.permaEden,
  };
});

const Content = styled(View)(() => {
  return {
    width: '100%',
    paddingBottom: 40,
    paddingRight: 40,
  };
});

const Header = styled(View)(() => {
  return {
    marginTop: 10,
    marginRight: 20,
    width: '100%',
  };
});

const Text = styled(Small)(({ theme: { colors } }) => {
  return {
    fonstSize: 15,
    color: colors.permaDarkForest,
    fontWeight: 700,
  };
});

const getContentViewStyle = (isMobile: boolean) => {
  return {
    minHeight: 60,
    width: isMobile ? 355 : 455,
    background: 'white',
    boxShadow: '0px 7px 16px rgba(0, 29, 47, 0.3875)',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'fit-content',
    position: 'absolute',
    top: '50%',
  };
};

interface ModalContainerProps {
  isModalOpen: boolean;
  children: React.ReactNode;
  primaryButtonText: string;
  handleCloseModal: () => void;
  primaryButtonOnClick: () => void;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  positioningStyle?: EmotionStyle;
}

const ModalContainer = ({
  isModalOpen,
  children,
  primaryButtonText,
  primaryButtonOnClick,
  handleCloseModal,
  secondaryButtonText,
  secondaryButtonOnClick,
  positioningStyle,
}: ModalContainerProps) => {
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  return (
    <Modal
      dialogStyle={{ ...getContentViewStyle(isMobile), ...positioningStyle }}
      isVisible={isModalOpen}
    >
      <Header row justify="end">
        <CloseButton onPress={handleCloseModal} width={31} height={31} />
      </Header>

      <Content>{children}</Content>

      <Footer row align="center" justify={secondaryButtonText ? 'space-between' : 'end'}>
        {secondaryButtonText && (
          <BaseButton onPress={secondaryButtonOnClick}>
            <Text>{secondaryButtonText}</Text>
          </BaseButton>
        )}
        <PrimaryButton
          size="small"
          roundedFocusStyle
          primaryColor={colors.permaEden}
          text={primaryButtonText}
          onPress={primaryButtonOnClick}
        />
      </Footer>
    </Modal>
  );
};

export default ModalContainer;
