import {
  AnimatedSwipeDownModal,
  Button,
  EmotionThemeProvider,
  useWindowWidthState,
  View,
  BookingAvatars,
} from '@talkspace/react-toolkit';
import { useHistory } from 'react-router';
import { SessionModality, TherapistTimeslot } from 'ts-frontend/types';
import { SuccessfulClaimClientInfo } from '../types';
import styled from '../../../../core/styled';
import ModalContainer from './ModalContainer';
import { getUserData } from '../../../../utils/token';
import apiHelper from '../../../../utils/api';

const sendAMessageButtonStyle = { size: 17, weight: 700, height: 50, marginBottom: 10 };

const Title = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 30,
    fontSize: 21,
    lineHeight: '28px',
    fontWeight: 500,
    color: colors.permaOuterSpaceNew,
    textAlign: 'center',
  };
});

const Subtitle = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 15,
    marginBottom: 35,
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'center',
    color: colors.permaRiverBed,
    lineHeight: '18px',
  };
});

const ContentWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: isMobile ? 0 : 50,
      paddingRight: isMobile ? 0 : 50,
      paddingBottom: isMobile ? 20 : 50,
    };
  }
);

interface AvailabilityPreferenceSuccessModalProps {
  onBackdropPress: () => void;
  isHidden: boolean;
  clientDetails: SuccessfulClaimClientInfo;
  selectedTimeslot: TherapistTimeslot;
  modality?: SessionModality;
}

const ClaimAndBookSuccessModal = ({
  onBackdropPress,
  clientDetails,
  selectedTimeslot,
  isHidden,
  modality = 'video',
}: AvailabilityPreferenceSuccessModalProps) => {
  const history = useHistory();
  const therapistID = getUserData()?.id;
  const { isMobile } = useWindowWidthState();

  const handleSendMessage = () => {
    history.push(`/room/${clientDetails?.roomID}`);
  };

  const renderContent = () => (
    <EmotionThemeProvider version="2.0.0">
      <ContentWrapper>
        <BookingAvatars
          therapistUserID={therapistID}
          selectedTimeslot={selectedTimeslot}
          modality={modality}
          shouldShowBookingSuccess
          cdnBaseUrl={apiHelper().cdnEndpoint}
        />
        <Title>
          {clientDetails.name.firstName} has been added to your caseload and you've suggested a time
          for your first session!
        </Title>
        <Subtitle>
          Message {clientDetails.name.firstName} now to introduce yourself and confirm your
          suggested first session works for them
        </Subtitle>

        <Button
          data-qa="growCaseloadClaimAndBookSuccessSendAMessage"
          stretch
          style={sendAMessageButtonStyle}
          variant="primary"
          onPress={handleSendMessage}
          text="Send a message"
        />
        <Button
          data-qa="growCaseloadClaimAndBookSuccessDoLater"
          variant="tertiary"
          onPress={onBackdropPress}
          text="I'll do this later"
        />
      </ContentWrapper>
    </EmotionThemeProvider>
  );

  if (isMobile) {
    return (
      <AnimatedSwipeDownModal isOpen={!isHidden} onClose={onBackdropPress}>
        {renderContent()}
      </AnimatedSwipeDownModal>
    );
  }

  return (
    <ModalContainer style={{ width: 435 }} onBackdropPress={onBackdropPress} hide={isHidden}>
      {renderContent()}
    </ModalContainer>
  );
};

export default ClaimAndBookSuccessModal;
