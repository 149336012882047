import ThreeDotsMenu from './ThreeDotsMenu';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

const versionMap: VersionMap<React.ComponentProps<typeof ThreeDotsMenu>> = {
  DefaultComponent: ThreeDotsMenu,
};

export type { ThreeDotsMenuProps } from './ThreeDotsMenu';
export default withVersioning(versionMap);
