import {
  View,
  ExclamationSquare,
  FloatingMenu,
  useWindowWidthState,
  Standard,
  Small,
  TouchableView,
  AnimatedSwipeDownModal,
  TextAreaRounded,
  useEmotionTheme,
  Spinner,
} from '@talkspace/react-toolkit';
import { keyframes } from '@emotion/core';
import { useEffect, useRef, useState } from 'react';
import styled from '../../../../core/styled';
import useMutationReviewTask, { Action } from '../../../../hooks/dashboard/useMutationReviewTask';
import { ReviewType } from '../../../../hooks/dashboard/useQueryTaskListV3';
import { getUserData } from '../../../../utils/token';
import { getTextAreaStyle } from '../../../Room/CRMContainer/NotesTab/utils';
import ToastMessage from '../../../Reusable/ToastMessage/ToastMessage';
import trackEvent from '../../../../modules/analytics/trackEvent';

type DisplayedScreen =
  | 'reviewSession'
  | 'confirmSession'
  | 'grantException'
  | 'submittedSuccess'
  | 'submittedExceptionSuccess'
  | 'submittedError';

const fadeIn = keyframes`
0% {
   opacity: 0;
 }
 100% {
   opacity: 1;
 }
`;

const FadeWrapper = styled(View)(() => {
  return {
    animation: `${fadeIn} 500ms cubic-bezier(.65,-0.01,.38,1) both`,
  };
});

const Title = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontWeight: 700,
    fontSize: 16,
    color: colors.black,
    marginLeft: 6,
  };
});

const StyledButton = styled(TouchableView)<{ disabled: boolean }>(
  ({ theme: { colors }, disabled }) => {
    return {
      height: 30,
      background: disabled ? colors.extraLightGrey : colors.accessibilityGreenDark,
      color: colors.white,
      fontSize: 15,
      fontWeight: 700,
      paddingRight: 20,
      paddingLeft: 20,
      lineHeight: '30px',
      borderRadius: 5,
      textAlign: 'center',
      width: 148,
    };
  }
);

const CancelButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.permaGrayLike,
    fontSize: 15,
    fontWeight: 700,
    padding: 10,
  };
});

const getfloatingMenyStyle = (isMobile: boolean) => {
  return {
    position: 'absolute',
    left: -195,
    borderRadius: 10,
    top: 35,
    height: 'fit-content',
    maxHeight: 'unset',
    width: 316,
    zIndex: 300,
    minWidth: 'unset',
    padding: '20px 12px 24px 20px',
    cursor: 'default',
    ...(isMobile && {
      position: 'fixed',
      left: '50%',
      bottom: 10,
      top: 'unset',
      transform: 'translate(-50%, -50%)',
    }),
  };
};

const ReviewScreen = ({ reviewType, handleDisplayContent }) => {
  const isNoShow = reviewType === 'noShow';

  return (
    <FadeWrapper>
      <View row align="center">
        <ExclamationSquare width={14} height={14} />
        <Title>Review {isNoShow ? 'no-show session' : 'late cancellation'}</Title>
      </View>
      <View style={{ marginLeft: 20, marginTop: 16 }}>
        <TouchableView
          onPress={() => handleDisplayContent('confirmSession')}
          dataQa={`submit${isNoShow ? 'noShow' : 'lateCancellation'}button`}
        >
          <Small variant="smallTSBlack" style={{ height: 34 }}>
            Submit as {isNoShow ? 'no-show' : 'late cancellation'}
          </Small>
        </TouchableView>
        <TouchableView
          onPress={() => handleDisplayContent('grantException')}
          dataQa="grantExceptionButton"
        >
          <Small variant="smallTSBlack">Grant an exception</Small>
        </TouchableView>
      </View>
    </FadeWrapper>
  );
};

const ConfirmSessionScreen = ({ reviewType, isSuccess, isLoading, handleCancel, handleSubmit }) => {
  const isNoShow = reviewType === 'noShow';
  const text = isNoShow ? 'no-show' : 'late cancellation';
  const action = isNoShow ? 'confirmNoShow' : 'confirmLateCancellation';

  return (
    <FadeWrapper>
      <View row align="center">
        <ExclamationSquare width={14} height={14} />
        <Title>Confirm {text} session</Title>
      </View>
      {isNoShow ? (
        <Small variant="smallTSBlack" style={{ marginTop: 16, textAlign: 'left' }}>
          By submitting this session as a “Client no-show” you are confirming that the client didn't
          show up for the appointment, and are aware that this will result in a direct charge to the
          member for the cost of the session as per Talkspace's policy.
        </Small>
      ) : (
        <Small variant="smallTSBlack" style={{ marginTop: 16, textAlign: 'left' }}>
          By submitting this session as a “Client late cancellation” you are confirming that the
          client canceled within 24 hours of the session, and are aware that this will result in a
          direct charge to the client for the cost of the session per Talkspace's policy.
        </Small>
      )}

      <View
        align="center"
        justify="space-between"
        row={isNoShow}
        style={{ marginTop: 16, flexDirection: !isNoShow && 'column-reverse' }}
      >
        <CancelButton dataQa="cancelButton" onPress={handleCancel}>
          Cancel
        </CancelButton>
        <StyledButton
          dataQa={`submitAs${text}button`}
          onPress={() => handleSubmit(action)}
          disabled={isLoading || isSuccess}
          style={{ marginBottom: !isNoShow && 16, width: !isNoShow ? '100%' : 173 }}
        >
          {isLoading ? <Spinner /> : `Submit as ${text}`}
        </StyledButton>
      </View>
    </FadeWrapper>
  );
};

const GrantExceptionScreen = ({ reviewType, handleCancel, isSuccess, isLoading, handleSubmit }) => {
  const { colors } = useEmotionTheme();
  const { isDesktop } = useWindowWidthState();
  const [exceptionReason, setExceptionReason] = useState<string>('');
  const [exceptionReasonError, setExceptionReasonError] = useState<boolean>(false);
  const textAreaRef = useRef<any>(null);
  const isNoShow = reviewType === 'noShow';
  const text = isNoShow ? 'no-show' : 'late cancellation';
  const action: Action = isNoShow ? 'grantNoShowException' : 'grantLateCancellationException';

  const handleChangeText = (value: string) => {
    setExceptionReasonError(false);
    setExceptionReason(value);
  };

  useEffect(() => {
    if (isDesktop) {
      textAreaRef?.current.focus();
    }
  }, [isDesktop]);

  const handlePressSubmit = () => {
    if (!exceptionReason) {
      setExceptionReasonError(true);
      return;
    }
    handleSubmit(action, exceptionReason);
  };

  return (
    <FadeWrapper>
      <View row align="center">
        <ExclamationSquare width={14} height={14} />
        <Title>Grant an exception</Title>
      </View>
      {isNoShow ? (
        <Small variant="smallTSBlack" style={{ marginTop: 16, textAlign: 'left' }}>
          The client did not show, but you wish to grant the member an exception due to an
          emergency. A fee will not be charged to the member.
        </Small>
      ) : (
        <Small variant="smallTSBlack" style={{ marginTop: 16, textAlign: 'left' }}>
          This client canceled late and you want to waive the fee due to an emergency. Therefore,
          this client will not be charged for this session.
        </Small>
      )}

      <Small variant="smallTSBlack" style={{ marginTop: 16, textAlign: 'left' }}>
        Please provide details for the {text} exception below:
      </Small>
      <TextAreaRounded
        ref={textAreaRef}
        style={{
          marginTop: 16,
          borderColor: exceptionReasonError ? colors.jellybeanRed : colors.permaWildBlueYonder,
        }}
        numberOfLines={4}
        textAreaStyle={getTextAreaStyle(false)}
        value={exceptionReason || ''}
        onChangeText={handleChangeText}
        multiline
        dataQa="GrantEcxeptionTextArea"
      />
      <View align="center" justify="space-between" row style={{ marginTop: 16 }}>
        <CancelButton dataQa="cancelButton" onPress={handleCancel}>
          Cancel
        </CancelButton>
        <StyledButton
          dataQa="grantExceptionButton"
          onPress={handlePressSubmit}
          disabled={isLoading || isSuccess}
        >
          {isLoading ? <Spinner /> : 'Grant exception'}
        </StyledButton>
      </View>
    </FadeWrapper>
  );
};

const ReviewTaskModal = ({
  isModalOpen,
  reviewType,
  bookingID,
  onBackdropPress,
  setIsDisabled,
}: {
  isModalOpen: boolean;
  reviewType: ReviewType;
  bookingID?: number;
  onBackdropPress: () => void;
  setIsDisabled: (val: boolean) => void;
}) => {
  const { isMobile } = useWindowWidthState();
  const floatingMenyStyle = getfloatingMenyStyle(isMobile);
  const [displayedScreen, setDisplayeScreen] = useState<DisplayedScreen>('reviewSession');

  const handleDisplayContent = (value: DisplayedScreen) => {
    setDisplayeScreen(value);
  };

  const { mutate: reviewTask, isLoading, isSuccess } = useMutationReviewTask();
  const therapistID = getUserData().id;

  useEffect(() => {
    if (isLoading) {
      setIsDisabled(true);
    }
  }, [isLoading, setIsDisabled]);

  const handleSubmit = (action: Action, exceptionReason?: string) => {
    if (bookingID) {
      trackEvent(
        'noShowActionClick',
        { actionName: 'providerTaskInteraction' },
        {
          providerID: therapistID,
          bookingID,
          action,
        }
      );
      reviewTask(
        { therapistID, bookingID, taskType: reviewType, action, exceptionReason },
        {
          onSuccess: () => {
            setIsDisabled(false);
            if (['grantNoShowException', 'grantLateCancellationException'].includes(action)) {
              handleDisplayContent('submittedExceptionSuccess');
            } else if (['confirmNoShow', 'confirmLateCancellation'].includes(action)) {
              handleDisplayContent('submittedSuccess');
            }
          },
          onError: () => {
            setIsDisabled(false);
            handleDisplayContent('submittedError');
          },
        }
      );
    }
  };
  const getContent = () => {
    switch (displayedScreen) {
      case 'confirmSession':
        return (
          <ConfirmSessionScreen
            handleSubmit={handleSubmit}
            handleCancel={onBackdropPress}
            reviewType={reviewType}
            isSuccess={isSuccess}
            isLoading={isLoading}
          />
        );
      case 'grantException':
        return (
          <GrantExceptionScreen
            handleSubmit={handleSubmit}
            handleCancel={onBackdropPress}
            reviewType={reviewType}
            isSuccess={isSuccess}
            isLoading={isLoading}
          />
        );
      case 'submittedError':
        return (
          <ToastMessage isError message="Oops, something went wrong" onDismiss={onBackdropPress} />
        );
      case 'submittedSuccess':
        return (
          <ToastMessage
            onDismiss={onBackdropPress}
            message={`Session was submitted as a ${
              reviewType === 'noShow' ? 'no-show' : 'late cancellation'
            }`}
          />
        );
      case 'submittedExceptionSuccess':
        return (
          <ToastMessage
            onDismiss={onBackdropPress}
            message={
              reviewType === 'noShow'
                ? 'No-show session was submitted as an exception'
                : 'Exception granted for late cancellation'
            }
          />
        );
      default:
        return <ReviewScreen reviewType={reviewType} handleDisplayContent={handleDisplayContent} />;
    }
  };

  const shouldDisplayAnimatedView =
    isMobile &&
    !['submittedError', 'submittedSuccess', 'submittedExceptionSuccess'].includes(displayedScreen);

  return (
    <>
      {shouldDisplayAnimatedView ? (
        <AnimatedSwipeDownModal
          closeModalText={displayedScreen === 'reviewSession' ? 'cancel' : undefined}
          isOpen={isModalOpen}
          onClose={onBackdropPress}
        >
          {getContent()}
        </AnimatedSwipeDownModal>
      ) : (
        <View onClick={(e) => e.preventDefault()}>
          <FloatingMenu
            style={floatingMenyStyle}
            onBackdropPress={onBackdropPress}
            hide={!isModalOpen}
          >
            {getContent()}
          </FloatingMenu>
        </View>
      )}
    </>
  );
};

export default ReviewTaskModal;
