import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OptionType, Select, useWindowWidthState, View } from '@talkspace/react-toolkit';
import { OptionsType } from 'react-select';
import { useParams } from '@/core/routerLib';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import { checkIfShouldBeSearchable, yesNoOptions } from '../../../../../utils/optionsHelpers';
import { getMarketing, updateMarketing } from '../../../../../actions/MarketingActions';
import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import UserBadge from '../../../../Reusable/UserBadge/UserBadge';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';

interface MarketingProps {
  clientUserID: number;
  customerInformation: any;
  firstTimeInTherapy: OptionType<string>;
  // eslint-disable-next-line react/no-unused-prop-types
  isRequestingMarketing: boolean;
  likelyToBuy: OptionType<string>;
  reasonNoPurchase: OptionType<string>;
  // eslint-disable-next-line react/no-unused-prop-types
  requestMarketingError: boolean;
  trafficControl: OptionType<string>;
  patchMarketing: (clientUserID: number, value: {}) => void;
  retrieveMarketing: (clientUserID: number) => void;
}

const Marketing = ({
  clientUserID,
  customerInformation,
  firstTimeInTherapy,
  likelyToBuy,
  reasonNoPurchase,
  trafficControl,
  patchMarketing,
  retrieveMarketing,
}: MarketingProps) => {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: participants = [] } = useQueryParticipants(Number(roomID));

  const trafficControlOptions = getOptionsByField('trafficControl');

  useEffect(() => {
    if (customerInformation) retrieveMarketing(customerInformation.clientUserID);
  }, [retrieveMarketing, customerInformation]);

  useEffect(() => {
    if (trafficControl) {
      const exists = trafficControlOptions.find((option) => option.value === trafficControl.value);
      if (!exists) {
        // eslint-disable-next-line no-param-reassign
        trafficControl.label = trafficControl.value;
        trafficControlOptions.push(trafficControl);
      }
    }
  }, [trafficControl, trafficControlOptions]);

  const getUpdateFunctionForComponent = (fieldName) => (value) => {
    patchMarketing(clientUserID, { [fieldName]: value });
  };

  const createUserButtons = () => (
    <View>
      {participants.map((customer) => (
        <UserBadge
          key={customer.userID}
          onClick={() => {
            if (customer.userID !== clientUserID) retrieveMarketing(customer.userID);
          }}
          preferredName={customer.displayName}
          active={customer.userID === clientUserID}
        />
      ))}
    </View>
  );

  const { isMobile } = useWindowWidthState();
  const showUserButtons = !!participants && !!participants.length && participants.length > 1;

  return (
    <AccordionContainer title="Marketing Information">
      {showUserButtons && createUserButtons()}
      <Select
        placeholder="Traffic control"
        options={trafficControlOptions}
        onChange={getUpdateFunctionForComponent('trafficControl')}
        value={trafficControl}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('trafficControl')}
      />
      <Select
        placeholder="Why hasn't the customer purchased?"
        options={getOptionsByField('reasonNoPurchase')}
        onChange={getUpdateFunctionForComponent('reasonNoPurchase')}
        value={reasonNoPurchase}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('reasonNoPurchase')}
      />
      <Select
        placeholder="Likely to commit to treatment?"
        options={getOptionsByField('likelyToBuy')}
        onChange={getUpdateFunctionForComponent('likelyToBuy')}
        value={likelyToBuy}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('likelyToBuy')}
      />
      <Select
        placeholder="First time in therapy?"
        options={yesNoOptions as OptionsType<any>}
        onChange={getUpdateFunctionForComponent('firstTimeInTherapy')}
        value={firstTimeInTherapy}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('firstTimeInTherapy')}
      />
    </AccordionContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    trafficControl: state.marketing.trafficControl,
    reasonNoPurchase: state.marketing.reasonNoPurchase,
    firstTimeInTherapy: state.marketing.firstTimeInTherapy,
    likelyToBuy: state.marketing.likelyToBuy,
    isRequestingMarketing: state.marketing.isRequestingMarketing,
    requestMarketingError: state.marketing.requestMarketingError,
    clientUserID: state.marketing.clientUserID,
    disabledComponents: state.marketing.disabledComponents,
    invalidComponents: state.customerInformation.invalidComponents,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchMarketing: updateMarketing,
      retrieveMarketing: getMarketing,
    },
    dispatch
  );

const MarketingContainer = connect(mapStateToProps, mapDispatchToProps)(Marketing);

export default MarketingContainer;
