import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckV2,
  Chip,
  Modal,
  styled,
  TextAreaRounded,
  TextDS,
  useEmotionTheme,
  View,
} from '@talkspace/react-toolkit';
import IconButton from '@talkspace/react-toolkit/src/designSystems/components/IconButton';
import { XMarkLarge } from '@talkspace/react-toolkit/src/designSystems/icons';
import BottomSheet from '@talkspace/react-toolkit/src/designSystems/components/BottomSheet';
import {
  InsightFeedbackPayload,
  InsightFeedbackPayloadNegative,
  InsightFeedbackPayloadNotUsed,
  InsightFeedbackPayloadPositive,
  InsightFeedbackType,
} from '../../../../types/clinical';

const CloseButton = styled(IconButton)({
  position: 'absolute',
  right: -4,
  top: -4,
});

const MainViewDesktop = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    padding: 20,
    gap: spacing('space200'),
    width: 407,
    background: colorRoles.surfaces.surfaceInteractiveDefault,
    boxShadow: '0px 8px 32px rgba(43, 42, 46, 0.25)',
    borderRadius: spacing('space200'),
  };
});

const MainViewMobile = styled(View)(({ theme: { spacing } }) => {
  return {
    paddingTop: 4,
    paddingRight: 20,
    paddingBottom: 0,
    paddingLeft: 20,
    gap: spacing('space200'),
  };
});

const ButtonsViewMobile = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    gap: spacing('space150'),
  };
});

const ButtonsViewDesktop = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: spacing('space150'),
  };
});

const helperText: Record<InsightFeedbackType, string> = {
  positive: 'We’d love to hear more about your experience. Please do not include PII or PHI.',
  negative:
    'What is your issue with the Smart Insights? Please describe it without including PII or PHI.',
  'not-used':
    'How can we make these more useful to you? Please respond without including PII or PHI.',
};

type FeedbackCheckboxesPositive = Pick<
  InsightFeedbackPayloadPositive,
  'isAccurate' | 'isHelpful' | 'feltMorePrepared'
>;

const defaultFeedbackCheckboxesPositive: FeedbackCheckboxesPositive = {
  isAccurate: false,
  isHelpful: false,
  feltMorePrepared: false,
};

type FeedbackCheckboxesNegative = Pick<
  InsightFeedbackPayloadNegative,
  'isInaccurate' | 'isNotHelpful' | 'isMissingInfo'
>;

const defaultFeedbackCheckboxesNegative: FeedbackCheckboxesNegative = {
  isInaccurate: false,
  isNotHelpful: false,
  isMissingInfo: false,
};

type FeedbackCheckboxesNotUsed = Pick<
  InsightFeedbackPayloadNotUsed,
  'didNotNotice' | 'didNotHaveTime' | 'didNotKnowThePurpose'
>;

const defaultFeedbackCheckboxesNotUsed: FeedbackCheckboxesNotUsed = {
  didNotNotice: false,
  didNotHaveTime: false,
  didNotKnowThePurpose: false,
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (payload: InsightFeedbackPayload) => void;
  initialType: InsightFeedbackType | null;
  title: string;
  subtitle?: string;
  showModalTypes?: boolean;
}

export default function FeedbackModal({
  isOpen,
  onClose,
  onSubmit,
  initialType,
  title,
  subtitle = '',
  showModalTypes = false,
}: Props) {
  const [modalType, setModalType] = useState<InsightFeedbackType | null>(initialType);

  useEffect(() => {
    setModalType(initialType);
  }, [initialType]);

  const {
    window: { isMobile },
  } = useEmotionTheme();

  const [positivePayload, setPositivePayload] = useState<FeedbackCheckboxesPositive>(
    defaultFeedbackCheckboxesPositive
  );

  const [negativePayload, setNegativePayload] = useState<FeedbackCheckboxesNegative>(
    defaultFeedbackCheckboxesNegative
  );

  const [notUsedPayload, setNotUsedPayload] = useState<FeedbackCheckboxesNotUsed>(
    defaultFeedbackCheckboxesNotUsed
  );

  const [freeText, setFreeText] = useState('');

  const handleClose = () => {
    setModalType(null);
    setFreeText('');
    setPositivePayload(defaultFeedbackCheckboxesPositive);
    setNegativePayload(defaultFeedbackCheckboxesNegative);
    setNotUsedPayload(defaultFeedbackCheckboxesNotUsed);
    onClose();
  };

  const handlePositiveCheckboxChange = (
    value: boolean,
    field: keyof FeedbackCheckboxesPositive
  ) => {
    setPositivePayload((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const handleNegativeCheckboxChange = (
    value: boolean,
    field: keyof FeedbackCheckboxesNegative
  ) => {
    setNegativePayload((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const handleNotUsedCheckboxChange = (value: boolean, field: keyof FeedbackCheckboxesNotUsed) => {
    setNotUsedPayload((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const feedbackCheckboxesPositive = useMemo(
    () => [
      {
        label: 'Smart Insights were accurate',
        value: positivePayload.isAccurate,
        onChange: (value: boolean) => handlePositiveCheckboxChange(value, 'isAccurate'),
      },
      {
        label: 'Smart Insights were helpful',
        value: positivePayload.isHelpful,
        onChange: (value: boolean) => handlePositiveCheckboxChange(value, 'isHelpful'),
      },
      {
        label: 'I felt more prepared for my session',
        value: positivePayload.feltMorePrepared,
        onChange: (value: boolean) => handlePositiveCheckboxChange(value, 'feltMorePrepared'),
      },
    ],
    [positivePayload.feltMorePrepared, positivePayload.isAccurate, positivePayload.isHelpful]
  );

  const feedbackCheckboxesNegative = useMemo(
    () => [
      {
        label: 'Smart Insights were inaccurate',
        value: negativePayload.isInaccurate,
        onChange: (value: boolean) => handleNegativeCheckboxChange(value, 'isInaccurate'),
      },
      {
        label: 'Smart Insights weren’t helpful',
        value: negativePayload.isNotHelpful,
        onChange: (value: boolean) => handleNegativeCheckboxChange(value, 'isNotHelpful'),
      },
      {
        label: 'Smart Insights are missing information',
        value: negativePayload.isMissingInfo,
        onChange: (value: boolean) => handleNegativeCheckboxChange(value, 'isMissingInfo'),
      },
    ],
    [negativePayload.isInaccurate, negativePayload.isMissingInfo, negativePayload.isNotHelpful]
  );

  const feedbackCheckboxesNotUsed = useMemo(
    () => [
      {
        label: 'I didn’t notice them',
        value: notUsedPayload.didNotNotice,
        onChange: (value: boolean) => handleNotUsedCheckboxChange(value, 'didNotNotice'),
      },
      {
        label: 'I didn’t have time',
        value: notUsedPayload.didNotHaveTime,
        onChange: (value: boolean) => handleNotUsedCheckboxChange(value, 'didNotHaveTime'),
      },
      {
        label: 'I didn’t know what they’re for',
        value: notUsedPayload.didNotKnowThePurpose,
        onChange: (value: boolean) => handleNotUsedCheckboxChange(value, 'didNotKnowThePurpose'),
      },
    ],
    [
      notUsedPayload.didNotHaveTime,
      notUsedPayload.didNotKnowThePurpose,
      notUsedPayload.didNotNotice,
    ]
  );

  const handleSubmitPress = () => {
    if (!modalType) {
      return;
    }

    const payloadMap = {
      positive: positivePayload,
      negative: negativePayload,
      'not-used': notUsedPayload,
    };

    const payload = {
      type: modalType,
      freeText,
      ...payloadMap[modalType],
    } as InsightFeedbackPayload;

    onSubmit(payload);
  };

  const content = (
    <>
      <View style={{ position: 'relative' }}>
        <TextDS variant="headingLg" style={{ maxWidth: 310 }}>
          {title}
        </TextDS>
        <CloseButton
          dataQa="closeButton"
          Icon={<XMarkLarge size="major" />}
          onPress={handleClose}
        />
      </View>

      {subtitle && (
        <View>
          <TextDS colorRole="textSubtle" variant="bodySm">
            {subtitle}
          </TextDS>
        </View>
      )}

      {showModalTypes && (
        <View style={{ flexDirection: 'row', gap: 12, justifyContent: 'space-between' }}>
          <Chip
            size="medium"
            variant="secondary"
            label="Didn’t use them"
            onPress={() => setModalType('not-used')}
            isSelected={modalType === 'not-used'}
            dataQa="didntUseThemChip"
          />

          <Chip
            size="medium"
            variant="secondary"
            label="Not great"
            onPress={() => setModalType('negative')}
            isSelected={modalType === 'negative'}
            dataQa="notGreatChip"
          />

          <Chip
            size="medium"
            variant="secondary"
            label="Helpful"
            onPress={() => setModalType('positive')}
            isSelected={modalType === 'positive'}
            dataQa="helpfulChip"
          />
        </View>
      )}

      {modalType === 'positive' && (
        <View>
          {feedbackCheckboxesPositive.map((checkbox) => (
            <Checkbox
              key={checkbox.label}
              stretch={false}
              isLabelOnRight
              label={checkbox.label}
              isChecked={checkbox.value}
              setIsChecked={checkbox.onChange}
              checkComponent={<CheckV2 />}
              dataQa={`${checkbox.label}-checkbox`}
            />
          ))}
        </View>
      )}

      {modalType === 'negative' && (
        <View>
          {feedbackCheckboxesNegative.map((checkbox) => (
            <Checkbox
              key={checkbox.label}
              stretch={false}
              isLabelOnRight
              label={checkbox.label}
              isChecked={checkbox.value}
              setIsChecked={checkbox.onChange}
              checkComponent={<CheckV2 />}
              dataQa={`${checkbox.label}-checkbox`}
            />
          ))}
        </View>
      )}

      {modalType === 'not-used' && (
        <View>
          {feedbackCheckboxesNotUsed.map((checkbox) => (
            <Checkbox
              key={checkbox.label}
              stretch={false}
              isLabelOnRight
              label={checkbox.label}
              isChecked={checkbox.value}
              setIsChecked={checkbox.onChange}
              checkComponent={<CheckV2 />}
              dataQa={`${checkbox.label}-checkbox`}
            />
          ))}
        </View>
      )}

      {modalType && (
        <>
          <TextDS variant="bodySm">{helperText[modalType]}</TextDS>

          <TextAreaRounded
            value={freeText}
            onChangeText={(value) => setFreeText(value)}
            dataQa="additionalFeedbackTextArea"
          />
        </>
      )}
    </>
  );

  const buttons = modalType ? (
    <>
      <Button
        text="Dismiss"
        sizeDS={isMobile ? 'medium' : 'slim'}
        variant="tertiary"
        onPress={handleClose}
        dataQa="dismissButton"
      />
      <Button
        text="Submit Feedback"
        sizeDS={isMobile ? 'medium' : 'slim'}
        onPress={handleSubmitPress}
        dataQa="submitFeedbackButton"
      />
    </>
  ) : null;

  if (isMobile) {
    return (
      <BottomSheet isOpen={isOpen} onClose={handleClose}>
        <MainViewMobile>
          {content}
          <ButtonsViewMobile>{buttons}</ButtonsViewMobile>
        </MainViewMobile>
      </BottomSheet>
    );
  }

  return (
    <Modal onBackdropPress={handleClose} isVisible={isOpen}>
      <MainViewDesktop>
        {content}
        <ButtonsViewDesktop>{buttons}</ButtonsViewDesktop>
      </MainViewDesktop>
    </Modal>
  );
}
