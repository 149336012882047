import { QuestionnaireType } from './types';

export const questionnairesQueryKey = (
  questionnaireTypes: Array<QuestionnaireType>,
  roomID: number
) => [questionnaireTypes, roomID];

export const questionnaireQuestionsQueryKey = (
  questionnaireType: QuestionnaireType,
  roomID: number
) => [questionnaireType, roomID];
