import { COLORS } from '@talkspace/react-toolkit';
import Svg, { Path } from 'svgs';

interface NotesIconProps {
  color?: string;
  width?: number;
  isHovering?: boolean;
  isActive?: boolean;
  height?: number;
  style?: {};
}

const NotesIcon = ({
  isHovering,
  isActive,
  color = isActive ? COLORS.accessibilityGreenDark : COLORS.slateGrey,
  width = 30,
  height = 24,
  ...otherProps
}: NotesIconProps) => (
  <Svg width={width} height={height} viewBox="0 0 30 24" {...otherProps}>
    <Path
      d={
        isActive
          ? 'M14.33 6.83c.464 0 .848.35.9.802l.006.121c0 .466-.35.85-.801.902l-.106.006H9.745a.915.915 0 00-.909.808l-.006.107v10.067c0 .469.354.856.809.909l.106.006h10.067c.47 0 .856-.354.91-.809l.005-.106v-4.584c0-.465.35-.849.802-.901l.121-.006c.466 0 .85.35.902.801l.006.106v4.584c0 1.46-1.14 2.654-2.579 2.74l-.167.005H9.745a2.746 2.746 0 01-2.74-2.578L7 19.643V9.576c0-1.46 1.14-2.654 2.578-2.74l.167-.006h4.584zm7.867-1.448l.115.106 1.59 1.591a1.66 1.66 0 01.142 2.185l-.101.12-6.994 7.513h-4.458V12.4l7.515-6.956a1.664 1.664 0 012.19-.06z'
          : 'M14.33 6.83c.5 0 .906.406.906.908v.015a.907.907 0 01-.907.908H9.745a.915.915 0 00-.915.915v10.067c0 .505.41.915.915.915h10.067c.506 0 .915-.41.915-.915v-4.584c0-.501.407-.907.908-.907h.015c.502 0 .908.406.908.907v4.584a2.745 2.745 0 01-2.746 2.745H9.745A2.745 2.745 0 017 19.643V9.576A2.745 2.745 0 019.745 6.83zm7.867-1.448l.115.106 1.59 1.591a1.66 1.66 0 01.142 2.185l-.101.12-6.994 7.513h-4.458V12.4l7.515-6.956a1.664 1.664 0 012.19-.06zM21.13 6.873l-6.826 6.318v1.891h1.854l6.354-6.826-1.382-1.383z'
      }
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </Svg>
);

export default NotesIcon;
