import { CloseButton, Spinner, Tiny, View, useWindowWidthState } from '@talkspace/react-toolkit';
import moment from 'moment';
import styled from '@/core/styled';
import {
  SessionMessageType,
  SessionMessagesQueryResponse,
} from '../../../../../../hooks/notes/useQuerySessionMessages';

interface SessionMessagesChatProps {
  isLoading: boolean;
  data?: SessionMessagesQueryResponse[];
  handleClose?: () => void;
}

const Container = styled(View)(
  ({
    theme: {
      colors,
      window: { isDesktop, isMobile },
    },
  }) => {
    return {
      width: isDesktop ? 550 : '100%',
      background: colors.white,
      borderLeft: !isMobile && `1px solid ${colors.permaLondonGray}`,
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    };
  }
);

const Header = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    textAlign: 'center',
    height: 60,
    borderBottom: `1px solid ${colors.permaLondonGray}`,
    fontSize: 17,
    fontWeight: 700,
    position: 'relative',
  };
});

const Message = styled(View)<{ isRecipient: boolean; isMedia?: boolean }>(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
    isRecipient,
    isMedia = false,
  }) => {
    let backgroundColor = isRecipient ? colors.permaGreyed : colors.green;
    let color = isRecipient ? colors.black : colors.white;
    if (isMedia) {
      backgroundColor = 'transparent';
      color = colors.permaGrayLike;
    }

    return {
      maxWidth: isMobile ? 289 : 400,
      width: 'fit-content',
      padding: '8px 12px',
      backgroundColor,
      color,
      borderRadius: 20,
      marginBottom: 30,
      justifySelf: !isRecipient && 'end',
      border: isMedia && `1px solid ${colors.paleBluGray}`,
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
    };
  }
);

const Footer = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    height: 67,
    borderTop: `1px solid ${colors.permaLondonGray}`,
    background: colors.white,
  };
});

const MessagesContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      overflow: !isMobile && 'auto',
      height: !isMobile && 'calc(100% - 127px)',
      padding: 10,
      overflowX: 'hidden',
    };
  }
);

const DisplayName = styled(Tiny)(({ theme: { colors } }) => {
  return {
    marginBottom: 10,
    color: colors.grey950,
    fontSize: 12,
    paddingLeft: 12,
  };
});

const TimeText = styled(Tiny)(({ theme: { colors } }) => {
  return {
    color: colors.permaBaliHaiGrey,
    fontSize: 12,
    justifySelf: 'center',
  };
});

const StyledCloseButton = styled(CloseButton)(({ theme: { colors } }) => {
  return {
    position: 'absolute',
    right: 10,
    top: 10,
  };
});

const getMediaMessageTitle = (messageType: SessionMessageType) => {
  switch (messageType) {
    case 'audio':
      return 'Audio message no longer available because client transferred.\n\n Auto-generated transcript: ';
    case 'video':
      return 'Video message no longer available because client transferred.\n\n Auto-generated transcript: ';
    case 'photo':
      return 'Image no longer available because client transferred.';
    case 'pdf':
      return 'PDF document no longer available because client transferred.';
    default:
      return null;
  }
};

const getMessageTime = (sentAt: Date) => {
  const messageMoment = moment(sentAt);
  const format = messageMoment.isSame(moment(), 'd') ? 'h:mm A' : 'MMM D, h:mm A';

  return messageMoment.format(format);
};

const SessionMessagesChat = ({ isLoading, data, handleClose }: SessionMessagesChatProps) => {
  const { isMobile } = useWindowWidthState();
  if (isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      {!isMobile && (
        <Header row align="center" justify="center">
          <View style={{ marginRight: -35 }}>Messages from session</View>
          <StyledCloseButton onPress={handleClose} backgroundColor="transparent" />
        </Header>
      )}

      <MessagesContainer>
        {data?.map((item) => (
          <View key={item.id} style={{ display: 'grid' }}>
            <TimeText>{getMessageTime(item.sentAt)}</TimeText>
            <DisplayName>{item.displayName}</DisplayName>
            <Message isRecipient={item.isRecipient} isMedia={item.messageType !== 'chat'}>
              {getMediaMessageTitle(item.messageType)}
              {!['photo', 'pdf'].includes(item.messageType) && item.message}
            </Message>
          </View>
        ))}
      </MessagesContainer>

      {!isMobile && <Footer />}
    </Container>
  );
};

export default SessionMessagesChat;
