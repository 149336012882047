import { useState } from 'react';
import { TextDS, ThumbsDown, ThumbsUp, View } from '@talkspace/react-toolkit';
import IconButton from '@talkspace/react-toolkit/src/designSystems/components/IconButton';
import { useParams } from '@/core/routerLib';
import storage from '@/core/storage';
import FeedbackModal from './FeedbackModal';
import useMutationCreateInsightFeedback from '../../../../hooks/useMutationCreateInsightFeedback';
import { InsightFeedbackPayload, InsightFeedbackType } from '../../../../types/clinical';
import { FEEDBACK_REMINDER_FOR_SMART_INSIGHT_ID } from '../../../../constants/storage';

interface Props {
  insightID: number;
}

export default function FeedbackSection({ insightID }: Props) {
  const { roomID } = useParams<{ roomID: string }>();

  const [feedbackModalType, setFeedbackModalType] = useState<InsightFeedbackType | null>(null);

  const [hideFeedbackSection, setHideFeedbackSection] = useState(false);

  const { mutate: createInsightFeedback, status: createInsightFeedbackStatus } =
    useMutationCreateInsightFeedback();

  const handleSubmitPress = (payload: InsightFeedbackPayload) => {
    if (!feedbackModalType) {
      return;
    }

    createInsightFeedback({ roomID: Number(roomID), insightID, payload });

    storage.removeItem(FEEDBACK_REMINDER_FOR_SMART_INSIGHT_ID);

    setFeedbackModalType(null);

    setTimeout(() => {
      setHideFeedbackSection(true);
    }, 10000);
  };

  return (
    <>
      {(() => {
        if (hideFeedbackSection) {
          return null;
        }

        if (createInsightFeedbackStatus === 'success') {
          return (
            <View align="center">
              <TextDS variant="bodyXs" colorRole="textSubtle">
                Thanks for your feedback!
              </TextDS>
            </View>
          );
        }

        return (
          <View rowGap={12} align="center">
            <View align="center">
              <TextDS variant="headingSm">Are these insights helpful?</TextDS>

              <TextDS variant="bodyXs" colorRole="textSubtle">
                Your input is valuable in helping us understand your needs.
              </TextDS>
            </View>

            <View row columnGap={8}>
              <IconButton Icon={<ThumbsUp />} onPress={() => setFeedbackModalType('positive')} />

              <IconButton Icon={<ThumbsDown />} onPress={() => setFeedbackModalType('negative')} />
            </View>
          </View>
        );
      })()}

      <FeedbackModal
        isOpen={!!feedbackModalType}
        onClose={() => setFeedbackModalType(null)}
        onSubmit={handleSubmitPress}
        initialType={feedbackModalType}
        title="Provide additional feedback"
      />
    </>
  );
}
