import { StarShooting, TrendArrow } from '@talkspace/react-toolkit/src/designSystems/icons';
import {
  Button,
  styled,
  TextDS,
  useWindowWidthState,
  VerticalLine,
  View,
} from '@talkspace/react-toolkit';
import { useHistory } from '@/core/routerLib';

import { navigateToBillableHoursZendesk } from '../utils/zendesk';
import { BonusInfo } from '../entities/clinicalHours';
import { NPP_UPPER_WEEKLY_HOURS_LIMIT } from '../utils/constants';

const BoldText = ({ children }) => (
  <TextDS inline style={{ fontWeight: 600 }}>
    {children}
  </TextDS>
);

const CTAButton = styled(Button)(({ theme: { spacing } }) => {
  return {
    padding: `${spacing('space075', true)} ${spacing('space100', true)}`,
  };
});

const CTAButtons = () => {
  const { isMobile, isTablet, isDesktop } = useWindowWidthState();
  const history = useHistory();

  const handleIncreaseHoursPress = async () => {
    await navigateToBillableHoursZendesk();
  };

  const handleAddAvailabilityPress = () => {
    history.push('/calendar');
  };

  const handleGrowCaseloadPress = () => {
    history.push('/grow-caseload');
  };

  return (
    <View
      style={{
        flexDirection: isMobile || isTablet ? 'column' : 'row',
        alignItems: isMobile || isTablet ? 'start' : 'center',
      }}
    >
      <CTAButton
        variant="tertiary"
        text="How can I increase my hours?"
        onPress={handleIncreaseHoursPress}
        size="small"
      />
      {isDesktop && <VerticalLine />}
      <CTAButton
        variant="tertiary"
        text="Add live session availability"
        onPress={handleAddAvailabilityPress}
        size="small"
      />
      {isDesktop && <VerticalLine />}
      <CTAButton
        variant="tertiary"
        text="Grow caseload"
        onPress={handleGrowCaseloadPress}
        size="small"
      />
    </View>
  );
};

const BonusInfoText = styled(TextDS)(
  ({
    theme: {
      window: { isMobile, isTablet },
      spacing,
    },
  }) => {
    let maxWidth = 540;

    if (isTablet) {
      maxWidth = 350;
    }

    if (isMobile) {
      maxWidth = 308;
    }

    return {
      maxWidth,
      marginBottom: spacing('space050'),
    };
  }
);

const RowView = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    gap: spacing('space200'),
  };
});

interface Props {
  bonusInfo: BonusInfo;
  weeklyHoursGoal: number;
}

const BonusInfoView = ({ bonusInfo, weeklyHoursGoal }: Props) => {
  if (bonusInfo.currentBonus) {
    const { currentBonus, potentialBonus } = bonusInfo;

    const { amount, threshold, requiredAverage } = potentialBonus || {};

    return (
      <>
        <RowView>
          <TrendArrow colorType="success" />

          <TextDS>
            You’re currently on track for a <BoldText>{currentBonus} bonus</BoldText>
          </TextDS>
        </RowView>

        {potentialBonus && (
          <RowView>
            <StarShooting colorType="info" />

            <View>
              <BonusInfoText>
                In order to earn a <BoldText>{amount}</BoldText> bonus with an average of{' '}
                {threshold} hours per week, complete {requiredAverage} hours per week during the
                remaining weeks.
              </BonusInfoText>

              <CTAButtons />
            </View>
          </RowView>
        )}
      </>
    );
  }

  if (
    bonusInfo.potentialBonus &&
    Number(bonusInfo.potentialBonus.requiredAverage) < NPP_UPPER_WEEKLY_HOURS_LIMIT
  ) {
    const {
      potentialBonus: { amount, threshold, requiredAverage },
    } = bonusInfo;

    return (
      <RowView>
        <StarShooting colorType="info" />

        <View>
          <BonusInfoText>
            You’re close to being on track for a <BoldText>{amount}</BoldText> bonus. Complete{' '}
            {requiredAverage} hours per week during the remaining weeks to bring your average up to{' '}
            {threshold} hrs/wk.
          </BonusInfoText>

          <CTAButtons />
        </View>
      </RowView>
    );
  }

  return (
    <RowView>
      <TrendArrow colorType="warning" />

      <View>
        <BonusInfoText>
          Your average is below {weeklyHoursGoal} hours per week. Please reach out to your team lead
          for support or increase your live session availability and caseload.
        </BonusInfoText>

        <CTAButtons />
      </View>
    </RowView>
  );
};

export default BonusInfoView;
