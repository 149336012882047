import {
  Standard,
  styled,
  View,
  Small,
  Link,
  useWindowWidthState,
  AnimatedSwipeDownModal,
  TouchableView,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import ssoHelper from '@/utils/sso';
import { GENERATED_SESSION_SUMMARY_BANNER_ARTICLE } from '../../../utils';

const Label = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    fontWeight: 700,
    lineHeight: '20px',
    color: colors.black,
    marginBottom: 5,
    marginRight: 8,
  };
});

const BetaContainer = styled(View)(({ theme: { colors } }) => {
  return {
    width: 37,
    height: 18,
    borderRadius: 5,
    background: colors.permaLavenderBlue,
    color: colors.ultramarine,
    fontSize: 12,
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: '18px',
  };
});

const TooltipContainer = styled(View)(({ theme: { colors } }) => {
  return {
    display: 'flex',
    width: 335,
    height: 'max-content',
    padding: '15px 20px',
    flexDirection: 'column',
    borderRadius: 8,
    boxShadow: '0px 7px 16px 0px rgba(0, 29, 47, 0.39)',
    position: 'absolute',
    zIndex: 10,
    background: colors.white,
  };
});

const Text = styled(Small)(({ theme: { colors } }) => {
  return {
    fonstSize: 14,
    fontWeight: 400,
    color: colors.black,
    whiteSpace: 'pre-line',
  };
});

const LinkText = styled(Link)(({ theme: { colors } }) => {
  return {
    fonstSize: 14,
    fontWeight: 500,
    color: colors.accessibilityGreenDark,
    margin: 0,
    textDecoration: 'underline',
  };
});

const Button = styled(TouchableView)(() => {
  return {
    textDecoration: 'underline dotted',
    cursor: 'pointer',
    display: 'inline-block',
  };
});

const SmartNoteTooltip = ({ modality }: { modality?: 'live' | 'messaging' }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const { isMobile } = useWindowWidthState();
  const {
    aiDocumentation: { treatmentPlanBasedDocumentation } = {
      treatmentPlanBasedDocumentation: false,
    },
  } = useFlags();

  const tooltipText =
    modality === 'live'
      ? "We use HIPAA-compliant technology to draft a session based on your live session transcript. You'll need to review and edit it before submitting. "
      : "We use HIPAA-compliant technology to draft a session summary based on the messages sent and received during this session. You'll need to review and edit it before submitting.\n";
  const treatmentPlanTooltip =
    modality === 'live'
      ? "We use HIPAA-compliant technology to draft a session based on your live session transcript and Treatment Plan. You'll need to review and edit it before submitting. "
      : "We use HIPAA-compliant technology to draft a session summary based on the messages sent and received during this session and the Treatment Plan. You'll need to review and edit it before submitting. ";

  const getContent = () => (
    <>
      <View row>
        <Label>New! Add a Smart Note</Label>
        <BetaContainer>Beta</BetaContainer>
      </View>
      <Text>
        {treatmentPlanBasedDocumentation ? treatmentPlanTooltip : tooltipText}
        <LinkText onClick={() => ssoHelper.openZendesk(GENERATED_SESSION_SUMMARY_BANNER_ARTICLE)}>
          How does this work?
        </LinkText>
      </Text>
    </>
  );

  return (
    <>
      <Button
        onMouseEnter={() => !isMobile && setIsTooltipOpen(true)}
        onMouseLeave={() => !isMobile && setIsTooltipOpen(false)}
        onPress={() => setIsTooltipOpen(true)}
      >
        Smart Note
      </Button>

      {isMobile ? (
        <AnimatedSwipeDownModal
          closeModalText="close"
          isOpen={isTooltipOpen}
          onClose={() => setIsTooltipOpen(false)}
        >
          {getContent()}
        </AnimatedSwipeDownModal>
      ) : (
        <>
          {isTooltipOpen && (
            <TooltipContainer
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
            >
              {getContent()}
            </TooltipContainer>
          )}
        </>
      )}
    </>
  );
};

export default SmartNoteTooltip;
