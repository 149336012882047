import { useMutation, useQueryClient } from 'react-query';

import apiHelper from '../../utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { OAuthProviderName } from './useQueryCalendarSyncStatus';
import { therapistCalendarSyncPreferences, calendarSyncStatus } from './queryKeys';

interface PostCalendarSyncParams {
  therapistID: number;
  provider: OAuthProviderName;
  syncBookings?: boolean;
  syncAvailabilities?: boolean;
}

const postCalendarSync = async (params: PostCalendarSyncParams): Promise<void> => {
  const { therapistID, provider, syncAvailabilities = false, syncBookings = true } = params;

  await apiWrapper.post(`${apiHelper().apiEndpoint}/v2/therapists/${therapistID}/calendar-sync`, {
    provider,
    syncAvailabilities,
    syncBookings,
  });
};

const useMutationCalendarSync = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, PostCalendarSyncParams>(postCalendarSync, {
    onSuccess: (_, { provider }) => {
      queryClient.invalidateQueries(therapistCalendarSyncPreferences());
      queryClient.invalidateQueries(calendarSyncStatus(provider));
    },
  });
};

export default useMutationCalendarSync;
