import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { OptionsType } from 'react-select';
import { Select, TextInput, useWindowWidthState, OptionType } from '@talkspace/react-toolkit';
import { PromiseMessageTypeNames } from 'ts-promise-message';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { isIonic } from '@/auth/reactFrame/helpers';
import { useHistory, useParams } from '@/core/routerLib';

import Submenu from '../../../../Reusable/Submenu/Submenu';
import Footer from '../../../../Reusable/Footer/Footer';
import AlertContainer from '../../../../Reusable/AlertContainer/AlertContainer';
import Button from '../../../../Reusable/Buttons/Button';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import useQueryDoseSpotPatient from './useQueryDoseSpotPatient';
import useQueryDoseSpotSSO from './useQueryDoseSpotSSO';
import useMutationUpdateDoseSpotPatient from './useMutationUpdateDoseSpotPatient';
import useMutationCreateDoseSpotPatient from './useMutationCreateDoseSpotPatient';
import { DoseSpotFormValues, DoseSpotSSO } from './types';
import { generateDoseSpotPatientURL } from './utils';
import usePatientForm from './usePatientForm';

const doseSpotGenderOptionsConfigStyle = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Unknown', value: 3 },
];

const getDoseSpotGenderConfigStyle = (gender: { label: string } | null) => {
  if (!gender) {
    return null;
  }
  if (gender.label.toLowerCase() === 'male') {
    return 'Male';
  }
  if (gender.label.toLowerCase() === 'female') {
    return 'Female';
  }
  return 'Unknown';
};

const initialFormState: DoseSpotFormValues = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  primaryPhone: '',
};

interface EmergencyContact {
  firstName?: string;
  lastName?: string;
  address?: string;
  address2?: string;
  city?: string;
  zipcode?: string;
  phone?: string;
}

interface Props {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: { label: string } | null;
  streetAddress: string;
  address2: string;
  city: string;
  state: { label: string };
  zipcode: string;
  phoneNumber: string;
  clientUserID: number;
  emergencyContactByClientUserID: Record<number, EmergencyContact>;
}

const DoseSpotPatientForm = ({
  firstName,
  lastName,
  dateOfBirth,
  gender,
  streetAddress,
  address2,
  city,
  state,
  zipcode,
  phoneNumber,
  clientUserID,
  emergencyContactByClientUserID,
}: Props) => {
  const { data: doseSpotPatient, refetch: refetchDoseSpotPatient } = useQueryDoseSpotPatient({
    clientUserID,
  });

  const {
    formValues,
    formErrors,
    setFormValues,
    validateForm,
    handleSelectChange,
    handleTextInputChange,
  } = usePatientForm(initialFormState);

  const [showAlert, setShowAlert] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);

  const history = useHistory();

  const { roomID } = useParams<{ roomID: string }>();

  const { isMobile } = useWindowWidthState();

  const { refetch: refetchDoseSpotSSO } = useQueryDoseSpotSSO(false);

  const startSpinnerAnimation = () => {
    setShowSpinner(true);
  };

  const stopSpinnerAnimation = () => {
    setTimeout(() => {
      setShowSpinner(false);
    }, 3000);
  };

  const {
    mutate: updatePatient,
    isLoading: isUpdatingPatient,
    error: errorPatientUpdate,
  } = useMutationUpdateDoseSpotPatient();

  const {
    mutate: createPatient,
    isLoading: isCreatingPatient,
    error: errorPatientCreate,
  } = useMutationCreateDoseSpotPatient();

  useEffect(() => {
    const emergencyContact = emergencyContactByClientUserID[clientUserID] || {};

    const finalDateOfBirth =
      doseSpotPatient?.dateOfBirth || dateOfBirth
        ? moment(doseSpotPatient?.dateOfBirth || dateOfBirth).format('MM/DD/YYYY')
        : '';

    setFormValues({
      firstName: doseSpotPatient?.firstName || firstName || emergencyContact.firstName || '',
      lastName: doseSpotPatient?.lastName || lastName || emergencyContact.lastName || '',
      dateOfBirth: finalDateOfBirth,
      gender: doseSpotPatient?.gender || getDoseSpotGenderConfigStyle(gender) || '',
      address: doseSpotPatient?.address || streetAddress || emergencyContact.address || '',
      address2: doseSpotPatient?.address2 || address2 || emergencyContact.address2 || '',
      city: doseSpotPatient?.city || city || emergencyContact.city || '',
      state: doseSpotPatient?.state || (state && state.label) || '',
      zipcode: doseSpotPatient?.zipcode || zipcode || emergencyContact.zipcode || '',
      primaryPhone: doseSpotPatient?.primaryPhone || phoneNumber || emergencyContact.phone || '',
    });
  }, [
    address2,
    city,
    clientUserID,
    dateOfBirth,
    doseSpotPatient,
    emergencyContactByClientUserID,
    firstName,
    gender,
    lastName,
    phoneNumber,
    setFormValues,
    state,
    streetAddress,
    zipcode,
  ]);

  const handleNavigateToDoseSpot = async (doseSpotPatientID: number, doseSpotSSO: DoseSpotSSO) => {
    const doseSpotPatientURL = generateDoseSpotPatientURL(doseSpotPatientID, doseSpotSSO);

    if (isIonic()) {
      window.location.href = doseSpotPatientURL;
    } else if (ReactFrameService.instance().isInFrame()) {
      await ReactFrameService.instance().sendAsyncMessage(
        PromiseMessageTypeNames.openBrowserWithURL,
        {
          url: doseSpotPatientURL,
          roomID: Number(roomID),
        }
      );
    } else {
      window.open(doseSpotPatientURL);
    }

    history.replace('clinical-tab');
  };

  const handleAddClientClick = async () => {
    if (!validateForm()) {
      setShowAlert(true);
      return;
    }

    startSpinnerAnimation();

    createPatient(
      {
        formValues,
        clientUserID,
      },
      {
        onSuccess: async () => {
          const { data: sso } = await refetchDoseSpotSSO();
          const { data: newDoseSpotPatient } = await refetchDoseSpotPatient();
          await handleNavigateToDoseSpot(newDoseSpotPatient!.doseSpotPatientID, sso!);
        },
        onSettled: () => {
          stopSpinnerAnimation();
        },
      }
    );
  };

  const handleUpdateClientClick = async () => {
    if (!validateForm()) {
      setShowAlert(true);
      return;
    }

    startSpinnerAnimation();

    updatePatient(
      {
        formValues,
        clientUserID,
      },
      {
        onSuccess: async () => {
          const { data: sso } = await refetchDoseSpotSSO();
          await handleNavigateToDoseSpot(doseSpotPatient!.doseSpotPatientID, sso!);
        },
        onSettled: () => {
          stopSpinnerAnimation();
        },
      }
    );
  };

  const getChildComponents = () => [
    <TextInput
      placeholder="First name"
      onBlur={handleTextInputChange('firstName')}
      value={formValues.firstName}
      isValid={!formErrors.firstName}
    />,
    <TextInput
      placeholder="Last name"
      onBlur={handleTextInputChange('lastName')}
      value={formValues.lastName}
      isValid={!formErrors.lastName}
    />,
    <TextInput
      placeholder="Date of birth"
      value={formValues.dateOfBirth}
      isValid={!formErrors.dateOfBirth}
      onBlur={handleTextInputChange('dateOfBirth')}
      maskType="date"
    />,
    <TextInput
      placeholder="Street address"
      onBlur={handleTextInputChange('address')}
      value={formValues.address}
      isValid={!formErrors.address}
    />,
    <TextInput
      placeholder="Street address line 2"
      onBlur={handleTextInputChange('address2')}
      value={formValues.address2}
      isValid={!formErrors.address2}
    />,
    <TextInput
      placeholder="City"
      onBlur={handleTextInputChange('city')}
      value={formValues.city}
      isValid={!formErrors.city}
    />,
    <TextInput
      placeholder="ZIP code"
      onBlur={handleTextInputChange('zipcode')}
      value={formValues.zipcode}
      isValid={!formErrors.zipcode}
    />,
    <Select
      placeholder="State"
      options={getOptionsByField('state')}
      value={getOptionsByField('state').find((f) => f.label === formValues.state)}
      onChange={handleSelectChange('state')}
      showIndicatorBeforeClick={isMobile}
    />,
    <Select
      placeholder="Gender"
      options={doseSpotGenderOptionsConfigStyle as OptionsType<OptionType<any>>}
      value={doseSpotGenderOptionsConfigStyle.find((f) => f.label === formValues.gender) as any}
      onChange={handleSelectChange('gender')}
      showIndicatorBeforeClick={isMobile}
    />,
    <TextInput
      placeholder="Phone number"
      onBlur={handleTextInputChange('primaryPhone')}
      value={formValues.primaryPhone}
      isValid={!formErrors.primaryPhone}
    />,
  ];

  const getFooterChildren = () => {
    const { doseSpotPatientID } = doseSpotPatient || {};
    const alertMessage = Object.values(formErrors)[0];
    return showAlert
      ? [
          <AlertContainer
            key={1}
            title="Missing information"
            childComponents={[
              <Button
                title="Ok"
                key={2}
                clickHandler={() => setShowAlert(false)}
                style={{ paddingTop: 15 }}
                isSecondary={false}
              />,
            ]}
            body={alertMessage}
            containerStyle={{
              width: 355,
              border: 'none',
            }}
          />,
        ]
      : [
          <Button
            title="Cancel"
            isSecondary
            key={1}
            clickHandler={() => history.replace('clinical-tab')}
          />,
          doseSpotPatientID ? (
            <Button
              title="Update client"
              key={2}
              clickHandler={handleUpdateClientClick}
              isSecondary={false}
            />
          ) : (
            <Button
              title="Add client"
              key={2}
              clickHandler={handleAddClientClick}
              isSecondary={false}
            />
          ),
        ];
  };

  const getFooterComponent = () => {
    const footerStyle = showAlert
      ? {
          height: 151,
          flexDirection: 'column-reverse',
          paddingTop: 3,
        }
      : {};

    const isUpdating = isUpdatingPatient || isCreatingPatient;
    const updateError = !!(errorPatientUpdate || errorPatientCreate);

    return (
      <Footer
        showSpinner={showSpinner}
        isUpdating={isUpdating}
        spinnerMessage={`Client successfully ${
          doseSpotPatient?.doseSpotPatientID ? 'updated' : 'added'
        }`}
        isError={updateError}
        footerStyle={footerStyle}
        childComponents={[...getFooterChildren()]}
      />
    );
  };

  return (
    <Submenu
      childComponents={getChildComponents()}
      footerComponent={getFooterComponent()}
      bodyStyle={{
        paddingTop: 25,
        paddingLeft: 35,
        paddingRight: 35,
      }}
      title={
        doseSpotPatient?.doseSpotPatientID ? 'Update DoseSpot patient' : 'Add client to DoseSpot'
      }
      prevRoute="clinical-tab"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    firstName: state.customerInformation.firstName,
    lastName: state.customerInformation.lastName,
    dateOfBirth: state.customerInformation.dateOfBirth,
    gender: state.customerInformation.gender,
    streetAddress: state.customerInformation.streetAddress,
    address2: state.customerInformation.address2,
    city: state.customerInformation.city,
    state: state.customerInformation.state,
    zipcode: state.customerInformation.zipcode,
    phoneNumber: state.customerInformation.phoneNumber,
    clientUserID: state.customerInformation.clientUserID,
    emergencyContactByClientUserID: state.emergencyContact.emergencyContactByClientUserID,
  };
};

export default connect(mapStateToProps)(DoseSpotPatientForm);
