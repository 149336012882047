import { useEffect, useState } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useLocation, useParams } from '@/core/routerLib';
import storage from '@/core/storage';
import { FEEDBACK_REMINDER_FOR_SMART_INSIGHT_ID } from '../constants/storage';
import useMutationCreateInsightFeedback from './useMutationCreateInsightFeedback';
import { InsightFeedbackPayload } from '../types/clinical';

export default function useSmartInsightsFeedbackReminder() {
  const [showReminder, setShowReminder] = useState(false);

  const { pathname } = useLocation();

  const { roomID } = useParams<{ roomID: string }>();

  const { smartInsightsFeedbackReminder } = useFlags();

  useEffect(() => {
    if (pathname.includes('insights-tab')) {
      return;
    }

    if (!smartInsightsFeedbackReminder) {
      return;
    }

    const storedInsightID = storage.getItem(FEEDBACK_REMINDER_FOR_SMART_INSIGHT_ID);
    if (storedInsightID) {
      setShowReminder(true);
    }
  }, [pathname, smartInsightsFeedbackReminder]);

  const { mutate: createInsightFeedback } = useMutationCreateInsightFeedback();

  const submitFeedback = (payload: InsightFeedbackPayload) => {
    const insightID = storage.getItem(FEEDBACK_REMINDER_FOR_SMART_INSIGHT_ID);
    createInsightFeedback({ roomID: Number(roomID), insightID: Number(insightID), payload });
    setShowReminder(false);
    storage.removeItem(FEEDBACK_REMINDER_FOR_SMART_INSIGHT_ID);
  };

  const dismissReminder = () => {
    setShowReminder(false);
    storage.removeItem(FEEDBACK_REMINDER_FOR_SMART_INSIGHT_ID);
  };

  return {
    showReminder,
    dismissReminder,
    submitFeedback,
  };
}
