import {
  View,
  TouchableView,
  useWindowWidthState,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '../../../../core/styled';
import { ReviewType, SessionStatus } from '../../../../hooks/dashboard/useQueryTaskListV3';
import ReviewTaskModal from './ReviewTaskModal';
import trackEvent from '../../../../modules/analytics/trackEvent';
import { getUserData } from '../../../../utils/token';
import { constructURL } from '../utils';

interface TaskActionsProps {
  sessionStatus: SessionStatus;
  roomID: number;
  taskID: string;
  draftProgressNoteID?: number;
  bookingID: number;
  isTransferred?: boolean;
  liveCallID?: number;
  asyncSessionID?: number;
}

const Button = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    fontWeight: 700,
    color: colors.white,
    background: colors.accessibilityGreenDark,
    borderRadius: 7,
    width: 120,
    height: 30,
    display: 'flex',
    textAlign: 'center',
    lineHeight: '30px',

    '&:hover': {
      background: colors.accessibilityDarkGreenBold,
    },
  };
});

const TaskButton = ({
  sessionStatus,
  roomID,
  taskID: unsubmittedSessionID,
  draftProgressNoteID,
  bookingID,
  isTransferred = false,
  liveCallID,
  asyncSessionID,
}: TaskActionsProps) => {
  const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const history = useHistory();
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const therapistID = getUserData().id;

  const handleSubmitPress = () => {
    trackEvent(
      isTransferred ? 'transferNoteClick' : 'taskClick',
      { actionName: 'providerTaskInteraction' },
      {
        eventDate: new Date(),
        type: isTransferred ? 'transferProgressNote' : 'progressNote',
        unsubmittedSessionID,
        action: draftProgressNoteID ? 'Continue draft' : 'Submit',
        roomID,
      }
    );

    const url = constructURL({ isTransferred, roomID, draftProgressNoteID, unsubmittedSessionID });
    history.push(url);
  };

  const handleReviewClick = () => {
    trackEvent(
      'noShowReviewClick',
      {
        actionName: 'providerTaskInteraction',
      },
      {
        roomID,
        providerID: therapistID,
        bookingID,
        type:
          sessionStatus === 'noShow'
            ? 'No-show Task Review Click'
            : 'Late-cancellation Task Review Click',
      }
    );
    setIsReviewModalOpen((prev) => !prev);
  };

  const submitButtonDataQA = asyncSessionID
    ? `submitTask-roomID-${roomID}-asyncSessionID-${asyncSessionID}`
    : `submitTask-roomID-${roomID}-liveCallID-${liveCallID}`;

  return (
    <View>
      {['noShow', 'lateCancellation'].includes(sessionStatus) ? (
        <View style={{ position: 'relative' }}>
          <Button
            disabled={isDisabled}
            style={{ width: isMobile ? 100 : 120, background: isDisabled && colors.extraLightGrey }}
            onPress={handleReviewClick}
            dataQa={`reviewTask-roomID-${roomID}-liveCallID-${liveCallID}`}
          >
            Review
          </Button>
          {isReviewModalOpen && (
            <ReviewTaskModal
              setIsDisabled={(value: boolean) => setIsDisabled(value)}
              bookingID={bookingID}
              reviewType={sessionStatus as ReviewType}
              isModalOpen={isReviewModalOpen}
              onBackdropPress={() => setIsReviewModalOpen(false)}
            />
          )}
        </View>
      ) : (
        <Button
          onPress={handleSubmitPress}
          style={{ ...(isMobile && { width: draftProgressNoteID ? 120 : 100 }) }}
          dataQa={submitButtonDataQA}
        >
          {draftProgressNoteID ? 'Continue draft' : 'Submit'}
        </Button>
      )}
    </View>
  );
};

export default TaskButton;
