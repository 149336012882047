import {
  CalendarBooking,
  CalendarPlusIcon,
  CalendarSync,
  Huge,
  Mini,
  PlayVideoIcon,
  View,
  useEmotionTheme,
  useWindowWidthState,
  CalendarVOutlineIcon,
  CircleQuestionMark,
} from '@talkspace/react-toolkit';
import { useCallback, useEffect, useRef, useState } from 'react';
import FullCalendar, {
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
} from '@fullcalendar/react';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment-timezone';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useHistory } from 'react-router';
import { getUserData } from '../../../../utils/token';
import useQueryProviderBookingsByID from '../../../../hooks/liveSessions/useQueryProviderBookingsByID';
import useQueryCalendarAvailabilityV3 from '../../../../hooks/availability/useQueryCalendarAvailabilityV3';
import useQueryTimeOffs, { TimeOff } from '../../../../hooks/availability/useQueryTimeOffs';
import useQueryProviderBookings, {
  BookingsV4Response,
} from '../../../../hooks/liveSessions/useQueryProviderBookings';
import styled, { EmotionStyle } from '../../../../core/styled';
import SocketService from '../../../../utils/socket/SocketService';
import CalendarModal from '../../Modals/CalendarModal';
import trackEvent from '../../../../modules/analytics/trackEvent';
import './calender.css';
import AvailabilityBlock from './AvailabilityBlock';
import TimeOffBlock from './TimeOffBlock';
import ToolBar from './Toolbar';
import OnBoardingBanner from '../../../Reusable/TutorialBanner/OnBoardingBanner';
import { WeekAvailability, DateRange, FormattedBookingsV4Response, CalendarView } from '../types';
import {
  SCROLL_Y_WIDTH,
  updateAllBookingState,
  isDateRangeInArray,
  getModalPositionStyle,
  formatAvailabilities,
  createAvailabitiltyItem,
  formatBookingsObjects,
  formatTimeOffs,
  getHourLater,
  handleScrollToElement,
  CALENDAR_ONBOARDING_COMPLETE,
  ONBOARDING_VIDEO_URL,
  DISPLAY_CALENDAR_SYNC,
} from '../utils';
import onBoardingBackgroundImage from '../assets/onboardingBackground.png';
import AddAvailabilityModal from '../modals/AddAvailability';
import ActionsPanel from './ActionsPanel';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';
import ssoHelper from '../../../../modules/utils/sso';
import { LEARN_MORE_CALENDAR_SYNC_LINK } from '../../../Account/CalendarSync/CalendarSync';

const DayOfWeek = styled(Mini)<{ isToday?: boolean }>(({ theme: { colors }, isToday }) => {
  const isTodayStyle: EmotionStyle = isToday ? { color: colors.permaEden } : {};

  return {
    fontSize: 12,
    textTransform: 'uppercase',
    ...isTodayStyle,
  };
});

const DayOfMonth = styled(Huge)<{ isPast?: boolean; isToday?: boolean }>(
  ({ theme: { colors }, isPast, isToday }) => {
    const pastDayStyle: EmotionStyle = isPast ? { color: colors.slateGrey } : {};
    const isTodayStyle: EmotionStyle = isToday ? { color: colors.white } : { color: colors.black };

    return {
      color: isToday ? colors.white : colors.black,
      ...isTodayStyle,
      ...pastDayStyle,
      backgroundColor: isToday ? colors.permaEden : 'transparent',
      justifyContent: 'center',
      paddingTop: 3,
      width: 37,
      height: 37,
      borderRadius: '50%',
      fontSize: 23,
      fontWeight: 500,
    };
  }
);

const SlotLane = styled(View)(() => {
  return {
    height: 45,
    cursor: 'pointer',
  };
});

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  };
});

interface Slot {
  date: Date;
}

const getSlotLabelComponent = (slot: Slot) => <Mini>{moment(slot.date).format('h A')}</Mini>;

const Wrapper = styled(View)(({ theme: { window, colors } }) => {
  const { isMobile, isLarge, isDesktop, width } = window;

  return {
    marginTop: 20,
    flex: 'auto',
    overflowY: 'auto',
    overflowX: width <= 414 ? 'scroll' : 'hidden', // allow scrolling up to iphone xr screen width
    ...(isMobile && {
      paddingLeft: 12,
      paddingRight: 12 - SCROLL_Y_WIDTH,
      paddingBottom: 30,
    }),
    ...((isLarge || isDesktop) && {
      paddingLeft: 20,
      paddingRight: 20 - SCROLL_Y_WIDTH,
      paddingBottom: 40,
    }),
  };
});

const ProviderCalendarV2 = () => {
  const calendarRef = useRef<FullCalendar | null>(null);
  const startOfWeekRef = useRef<Date>(moment().startOf('week').toDate());
  const endOfWeekRef = useRef<Date>(moment().endOf('week').toDate());
  const browserTimezone = useRef<string>(moment.tz.guess());
  const calendarContainerRef = useRef<HTMLDivElement | null>(null);
  const bookingChipPositionRef = useRef<HTMLDivElement | Element | null>(null);
  const { isMobile } = useWindowWidthState();
  const [dateRange, setDateRange] = useState<DateRange>({
    start: startOfWeekRef.current,
    end: endOfWeekRef.current,
  });
  const [bookingID, setBookingID] = useState<string>('');
  const [bookings, setBookings] = useState<BookingsV4Response[]>([]);
  const [addAvailabilityModalOpen, setAddAvailabilityModalOpen] = useState<boolean>(false);
  const [showCalendarModal, setShowCalendarModal] = useState<boolean>(false);
  const [bookingContent, setBookingContent] = useState<FormattedBookingsV4Response | null>(null);
  const [weekAvailabilities, setWeekAvailabilities] = useState<WeekAvailability[]>([]);
  const [timeOffs, setTimeOffs] = useState<TimeOff[]>([]);
  const [hideBookings, setHideBookings] = useState<boolean>(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const [calendarView, setCalendarView] = useState<CalendarView>('timeGridWeek');
  const [previewEvent, setPreviewEvent] = useState<WeekAvailability | null>(null);
  const isNewAvailability = useRef(false);
  const therapistID: number = getUserData().id;
  const { colors } = useEmotionTheme();
  const sourceRef = useRef<string>('calendar');
  const timezoneRef = useRef(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const history = useHistory();

  const { calendarSync: { syncBookingsON, syncAvailabilityON } = {} } = useFlags();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (newTimezone !== timezoneRef.current) {
        window.location.reload();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const { data: availabilitiesData, isFetching: isAvailabitiyFetching } =
    useQueryCalendarAvailabilityV3(
      therapistID,
      dateRange.start.toISOString(),
      dateRange.end.toISOString()
    );

  const { data: providerBookings = [], isFetching: isProviderBookingsFetching } =
    useQueryProviderBookings(
      therapistID,
      dateRange.start.toISOString(),
      dateRange.end.toISOString()
    );

  const { data: providerBookingsByID, isFetching: isProviderBookingByIdFetching } =
    useQueryProviderBookingsByID(
      therapistID,
      bookingID,
      dateRange.start.toISOString(),
      dateRange.end.toISOString()
    );

  const { data: providerTimeOffs = [], isFetching: isProviderTimeOffsFetching } =
    useQueryTimeOffs(therapistID);

  useEffect(() => {
    if (!isProviderBookingsFetching && providerBookings.length > 0) {
      setBookings(providerBookings);
    }
  }, [isProviderBookingsFetching, providerBookings]);

  useEffect(() => {
    if (calendarRef.current) {
      setTimeout(() => {
        handleScrollToElement();
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (!isProviderTimeOffsFetching && providerTimeOffs.length) {
      setTimeOffs(formatTimeOffs(providerTimeOffs));
    }
  }, [isProviderTimeOffsFetching, providerTimeOffs]);

  useEffect(() => {
    if (!isProviderBookingByIdFetching && providerBookingsByID) {
      const updatedAllBookingData = updateAllBookingState(
        bookingID.toString(),
        providerBookings,
        providerBookingsByID
      );

      setBookings(updatedAllBookingData);
      setBookingID('');
    }
  }, [bookingID, isProviderBookingByIdFetching, providerBookings, providerBookingsByID]);

  useEffect(() => {
    const handleBookingChange = (data: { message: { bookingID: string } }) => {
      setBookingID(data.message.bookingID);
    };
    SocketService.instance().on('bookingUpdate', handleBookingChange);
    return () => {
      SocketService.instance().off('bookingUpdate', handleBookingChange);
    };
  });

  const handleBookingClick = (info: EventClickArg) => {
    bookingChipPositionRef.current = info.el;
    trackEvent(
      'calendarEventModalOpened',
      {
        actionName: 'liveSessionInteraction',
      },
      {
        timekitUUID: info.event.extendedProps.bookingID,
        updatedBy: 'provider',
      }
    );
    setBookingContent(info.event.extendedProps as FormattedBookingsV4Response);
    setShowCalendarModal(true);
  };

  const getEventComponent = (info: EventContentArg) => {
    const { extendedProps, title } = info.event;

    if (title === 'availability')
      return (
        <AvailabilityBlock
          isRecurring={extendedProps.isRecurring}
          minutes={extendedProps.minutes}
          isSelected={extendedProps.isSelected}
          containerRef={bookingChipPositionRef}
        />
      );
    if (title === 'timeOff') {
      return <TimeOffBlock />;
    }
    return (
      <CalendarBooking
        bookingState={extendedProps.timekitBookingState}
        isActive={bookingContent !== null && extendedProps.bookingID === bookingContent.bookingID}
        bookingStatus={extendedProps.status}
        endDate={new Date(extendedProps.endsAt)}
        duration={extendedProps.creditMinutes}
        modality={extendedProps.modality}
        hasBreakAfterSession={extendedProps.hasBreakAfterSession}
        scheduledByUserType={extendedProps.scheduledByUserType}
        primaryClient={extendedProps.clients[0]}
      />
    );
  };

  const getDayHeaderComponent = useCallback(
    (dayDate: DayHeaderContentArg) => {
      const { isPast, isToday, date } = dayDate;
      const dayOfWeek = date
        .toLocaleDateString('en-US', {
          weekday: calendarView === 'timeGridWeek' ? 'short' : 'long',
        })
        .toUpperCase();

      const dayOfMonth = date.getDate();

      const dayLabel = isMobile && calendarView === 'timeGridWeek' ? dayOfWeek[0] : dayOfWeek;

      return (
        <View justify="center" align="center" style={{ flexDirection: 'column' }}>
          {date.getDay() === 0 && (
            <Mini style={{ position: 'absolute', left: 12, bottom: 14 }}>
              {moment().tz(browserTimezone.current).format('zz')}
            </Mini>
          )}
          <DayOfWeek isToday={isToday}>{dayLabel}</DayOfWeek>
          <DayOfMonth isPast={isPast} isToday={isToday}>
            {dayOfMonth}
          </DayOfMonth>
        </View>
      );
    },
    [calendarView, isMobile]
  );

  const getDateRange = useCallback(() => {
    const endDate = calendarRef?.current?.getApi().getCurrentData().dateProfile.activeRange?.end;
    // the fullcalendar api is returning the UTC 00:00:00 timestamp and THEN converting to local time
    // this is to determine the actual week of the year with that information
    const isoWeek = moment(endDate).subtract(2, 'day').isoWeek();
    const newStart = moment(endDate)
      .isoWeek(isoWeek)
      .startOf('isoWeek')
      .subtract(1, 'day')
      .toDate();
    const newEnd = moment(endDate).isoWeek(isoWeek).endOf('isoWeek').subtract(1, 'day').toDate();

    return endDate
      ? { start: newStart, end: newEnd }
      : { start: startOfWeekRef.current, end: endOfWeekRef.current };
  }, [calendarRef, startOfWeekRef, endOfWeekRef]);

  useEffect(() => {
    if (!dateRange.start || !dateRange.end) {
      setDateRange(getDateRange());
    }
  }, [dateRange, getDateRange]);

  useEffect(() => {
    if (!isAvailabitiyFetching) {
      setWeekAvailabilities(formatAvailabilities(availabilitiesData?.availabilities));
    }
  }, [dateRange.start, dateRange.end, availabilitiesData?.availabilities, isAvailabitiyFetching]);

  const handlePrevClick = useCallback(() => {
    trackEvent(
      'calendarDateRangeChanged',
      { actionName: 'activeExperiments' },
      { userAction: 'back' }
    );

    calendarRef?.current?.getApi().prev();
    setDateRange(getDateRange());
  }, [setDateRange, getDateRange, calendarRef]);

  const handleNextClick = useCallback(() => {
    trackEvent(
      'calendarDateRangeChanged',
      { actionName: 'activeExperiments' },
      { userAction: 'forward' }
    );

    calendarRef?.current?.getApi().next();
    setDateRange(getDateRange());
  }, [setDateRange, getDateRange, calendarRef]);

  const handleTodayClick = useCallback(() => {
    trackEvent(
      'calendarDateRangeChanged',
      { actionName: 'activeExperiments' },
      { userAction: 'today' }
    );

    calendarRef?.current?.getApi().today();
    setDateRange(getDateRange());
  }, [setDateRange, getDateRange, calendarRef]);

  const handleModalClosePress = () => {
    setShowCalendarModal(false);
    setBookingContent(null);
  };

  const events = weekAvailabilities
    .concat(timeOffs)
    .concat(formatBookingsObjects(bookings, hideBookings));

  const renderEvents = () => {
    const filteredEvents = events.filter((event) => event.id !== previewEvent?.id);
    return previewEvent ? [...filteredEvents, previewEvent] : filteredEvents;
  };

  const handleAvailabilityClick = ({ start, end, isRecurring = true, source = 'calendar' }) => {
    if (start && end) {
      setPreviewEvent(
        createAvailabitiltyItem({
          start,
          end,
          isSelected: true,
          isRecurring,
        })
      );
      trackEvent(
        'calendarAvailabilityModalOpened',
        { actionName: 'activeExperiments' },
        { source }
      );
      sourceRef.current = source;
      setAddAvailabilityModalOpen(true);
    }
  };

  const handleUnAvailabiltyClick = (info: DateClickArg) => {
    const start = info.date;
    const end = previewEvent?.end || new Date(getHourLater(start));
    const current = new Date();
    const isTimeOffBlock = isDateRangeInArray(start, end, timeOffs);
    if (isTimeOffBlock || current > start) {
      return;
    }
    const isAvailabilityBlock = isDateRangeInArray(start, end, weekAvailabilities);
    if (!isAvailabilityBlock) {
      isNewAvailability.current = true;
      handleAvailabilityClick({ start, end });
    }
  };

  const handleCloseAvailabilityModal = () => {
    setAddAvailabilityModalOpen(false);
    setPreviewEvent(null);
  };

  const handleCalendarView = (value: CalendarView) => {
    calendarRef?.current?.getApi().changeView(value);
    setCalendarView(value);
  };

  useDidUpdateEffect(() => {
    if (!isMobile) {
      handleCalendarView('timeGridWeek');
    }
  }, [isMobile]);

  const handlePreviewStartDate = (start: Date) => {
    if (previewEvent) {
      setPreviewEvent({
        ...previewEvent,
        start,
      });
    }
  };

  const handlePreviewEndDate = (end: Date) => {
    if (previewEvent) {
      setPreviewEvent({
        ...previewEvent,
        end,
      });
    }
  };

  const handlePreviewIsReccuring = (isRecurring: boolean) => {
    if (previewEvent) {
      setPreviewEvent({
        ...previewEvent,
        isRecurring,
      });
    }
  };

  const handleEventClick = (info: EventClickArg) => {
    if (info.event.title === 'booking') {
      handleBookingClick(info);
    } else if (info.event.title === 'availability') {
      isNewAvailability.current = false;
      handleAvailabilityClick({
        start: info.event.start,
        end: info.event.end,
        isRecurring: info.event.extendedProps.isRecurring,
      });
    }
  };

  const handleOpenAvailabilityModal = (source = 'calendar') => {
    const start = new Date();
    const end = new Date(getHourLater(start));
    isNewAvailability.current = true;
    handleAvailabilityClick({ start, end, source });
  };

  const handleDateRange = useCallback((start: Date, end: Date) => {
    setDateRange({
      start,
      end,
    });
  }, []);

  const handleHideBookings = (value: boolean) => {
    trackEvent(
      'calendarViewFiltered',
      { actionName: 'activeExperiments' },
      { filterName: 'hide bookings' }
    );
    setHideBookings(value);
  };

  const positionStyle = getModalPositionStyle(bookingChipPositionRef.current, isMobile);

  const handleOpenVideoModal = () => {
    trackEvent(
      'videoTutorialPlayed',
      {
        actionName: 'activeExperiments',
      },
      {
        videoUrl: ONBOARDING_VIDEO_URL,
      }
    );
    setIsVideoModalOpen(true);
  };

  const handleCloseVideoModal = () => {
    trackEvent(
      'videoTutorialPlayed',
      {
        actionName: 'activeExperiments',
      },
      {
        videoUrl: ONBOARDING_VIDEO_URL,
      }
    );
    setIsVideoModalOpen(false);
  };

  const handleConnectCalendar = () => {
    history.push('/my-account?scrollTo=calendarSync');
    localStorage.setItem(DISPLAY_CALENDAR_SYNC, 'true');
  };

  const shouldDisplayCalendarSyncBanner =
    !localStorage.getItem(DISPLAY_CALENDAR_SYNC) && (syncBookingsON || syncAvailabilityON);

  return (
    <Container>
      <View style={{ flex: '1 0 auto' }}>
        {shouldDisplayCalendarSyncBanner ? (
          <OnBoardingBanner
            primaryButton={{
              title: 'Connect calendar',
              callback: handleConnectCalendar,
              icon: <CalendarVOutlineIcon color={colors.white} />,
            }}
            secondaryButton={{
              title: 'Learn more',
              callback: () => ssoHelper.openZendesk(LEARN_MORE_CALENDAR_SYNC_LINK),
              icon: <CircleQuestionMark />,
            }}
            illustration={<CalendarSync />}
            storageKey={DISPLAY_CALENDAR_SYNC}
            subtitle="Enable Google Calendar sync to automatically send your live sessions and availability directly to your Google Calendar."
            title="Stay in control of your schedule with calendar sync"
          />
        ) : (
          <OnBoardingBanner
            primaryButton={{
              title: 'Add availability',
              callback: () => handleOpenAvailabilityModal('tutorial'),
              icon: <CalendarPlusIcon color={colors.white} />,
            }}
            secondaryButton={{
              title: 'Watch tutorial',
              callback: handleOpenVideoModal,
              icon: <PlayVideoIcon />,
            }}
            placeholderImage={onBoardingBackgroundImage}
            storageKey={CALENDAR_ONBOARDING_COMPLETE}
            videoUrl={ONBOARDING_VIDEO_URL}
            subtitle="Set up recurring availability so your clients can send live session requests."
            title="Add and edit live session availability on your calendar"
            isVideoModalOpen={isVideoModalOpen}
            onOpenVideoModal={handleOpenVideoModal}
            onCloseVideoModal={handleCloseVideoModal}
          />
        )}

        <ActionsPanel
          handleAddAvailability={() => handleOpenAvailabilityModal('header')}
          handleWatchTutorial={() => {
            setIsVideoModalOpen(true);
          }}
        />
        <ToolBar
          handlePreviousPress={handlePrevClick}
          handleNextPress={handleNextClick}
          handleTodayPress={handleTodayClick}
          dateStart={dateRange.start}
          dateEnd={dateRange.end}
          handleChecked={(value) => handleHideBookings(value)}
          isChecked={hideBookings}
          handleCalendarView={handleCalendarView}
          currentView={calendarView}
        />
      </View>
      <Wrapper ref={calendarContainerRef}>
        <View style={{ background: colors.permaSolitude }}>
          <FullCalendar
            eventClassNames="v2"
            allDaySlot={false}
            plugins={[timeGridPlugin, interactionPlugin]}
            nowIndicator
            nowIndicatorClassNames="v2"
            initialView={calendarView}
            views={{
              timeGridThreeDays: {
                type: 'timeGrid',
                duration: { days: 3 },
              },
            }}
            events={renderEvents()}
            eventContent={getEventComponent}
            eventClick={handleEventClick}
            dayHeaderContent={getDayHeaderComponent}
            slotLabelContent={getSlotLabelComponent}
            slotLaneContent={<SlotLane />}
            height="auto"
            eventColor="transparent"
            eventBackgroundColor="transparent"
            headerToolbar={{ end: '', center: '', start: '' }}
            slotDuration="01:00:00"
            ref={calendarRef}
            dateClick={handleUnAvailabiltyClick}
          />
        </View>
      </Wrapper>
      <CalendarModal
        showModal={showCalendarModal}
        booking={bookingContent}
        timezone={moment().tz(browserTimezone.current).format('zz')}
        positioningStyle={positionStyle}
        handleModalClosePress={handleModalClosePress}
      />
      {addAvailabilityModalOpen && (
        <AddAvailabilityModal
          source={sourceRef.current}
          dateRange={dateRange}
          action={previewEvent && !isNewAvailability.current ? 'edit' : 'create'}
          handlePreviewEndDate={handlePreviewEndDate}
          handlePreviewStartDate={handlePreviewStartDate}
          previewBlock={previewEvent}
          handleClose={handleCloseAvailabilityModal}
          positioningStyle={positionStyle}
          calendarRef={calendarRef?.current}
          handleDateRange={handleDateRange}
          handlePreviewIsReccuring={handlePreviewIsReccuring}
        />
      )}
    </Container>
  );
};

export default ProviderCalendarV2;
