import {
  CircledCheckMark,
  RectangleExclamationPoint,
  CircledPause,
  View,
  ExtraHuge,
  useEmotionTheme,
  SquareCheckMark,
  Tiny,
  ExtraTiny,
  PeopleOutline,
  CheckAndXMarks,
  BaseButton,
  Large,
  FloatingMenu,
  CloseButton,
  useWindowWidthState,
  EmotionStyle,
  TooltipV2,
} from '@talkspace/react-toolkit';
import { useMemo, useState, VoidFunctionComponent } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { BookingsV4Response } from 'hooks/liveSessions/useQueryProviderBookings';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import styled from '../../../../core/styled';
import AudioModalityIcon from '../../../Icons/AudioModalityIcon';
import ChatModalityIcon from '../../../Icons/ChatModalityIcon';
import VideoModalityIcon from '../../../Icons/VideoModalityIcon';
import AvatarWithStatus from '../../../Reusable/AvatarWithStatus/AvatarWithStatus';
import capitalize from '../../../../utils/capitalize';
import trackEvent from '../../../../modules/analytics/trackEvent';
import ClientHoldTooltip from '../../../common/ClientHoldTooltip';

interface FormattedBookingsV4Response extends Omit<BookingsV4Response, 'startTime'> {
  beginsAt: Date;
  start: Date;
  end: Date;
}

interface CalendarModalProps {
  showModal: boolean;
  positioningStyle?: EmotionStyle;
  booking: FormattedBookingsV4Response | null;
  timezone?: string;
  handleModalClosePress: () => void;
}

const ModalContainer = styled(View)(({ theme: { colors } }) => {
  return {
    background: colors.white,
    textAlign: 'center',
    width: 428,
  };
});

const TitleText = styled(ExtraHuge)({
  fontSize: '21px',
  lineHeight: '31px',
  fontWeight: 500,
});

const DetailText = styled(Tiny)(({ theme: { colors } }) => {
  return {
    marginLeft: 16,
    color: colors.black,
  };
});
const BookingStateText = styled(ExtraTiny)(({ theme: { colors } }) => {
  return {
    color: colors.permaEastBay,
  };
});

const CheckMark = styled(CircledCheckMark)({
  width: 11,
  height: 11,
});

const ExclamationPoint = styled(RectangleExclamationPoint)({
  width: 11,
  height: 11,
});

const CalendarModal: VoidFunctionComponent<CalendarModalProps> = ({
  showModal,
  booking,
  positioningStyle,
  timezone,
  handleModalClosePress,
}) => {
  const {
    roomID,
    bookingID,
    modality = '',
    status = '',
    creditMinutes,
    beginsAt,
    hasBreakAfterSession,
    clients,
    scheduledByUserType,
    timekitBookingState: state,
    provider,
    roomProviderID,
  } = booking || {};
  const {
    firstName: providerFirstName,
    lastName: providerLastName,
    userID: providerID,
  } = provider || {};
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const isNoShow = ['client-no-show', 'therapist-no-show'].includes(status);
  const sessionOccured =
    ['completed', 'completed-after-late-start'].includes(status) && state === 'completed';
  const isClientNoShow = status === 'client-no-show';
  const isTherapistNoShow = status === 'therapist-no-show';
  const isNewClientHold = state === 'new_client_hold';
  const beginsAtMoment = moment(beginsAt);
  const beginsAtMomentToMutate = moment(beginsAt);
  const isInNativeWebView = ReactFrameService.instance().isInFrame();

  const history = useHistory();

  const getCustomerNameString = (client) =>
    client.firstName && client.lastName
      ? `${client.firstName} ${client.lastName}`
      : `${client.pseudonym}`;

  const modalityIcon = useMemo(() => {
    switch (true) {
      case modality === 'video' && isNoShow:
        return (
          <VideoModalityIcon
            backgroundColor={colors.permaSelago}
            foregroundColor={colors.permaDeepViolet}
          />
        );
      case modality === 'audio' && isNoShow:
        return (
          <AudioModalityIcon
            backgroundColor={colors.permaSelago}
            foregroundColor={colors.permaDeepViolet}
          />
        );
      case modality === 'chat' && isNoShow:
        return (
          <ChatModalityIcon
            backgroundColor={colors.permaSelago}
            foregroundColor={colors.permaDeepViolet}
          />
        );
      case modality === 'video':
        return <VideoModalityIcon />;
      case modality === 'audio':
        return <AudioModalityIcon />;
      case modality === 'chat':
        return <ChatModalityIcon />;
      default:
        return <VideoModalityIcon />;
    }
  }, [colors.permaDeepViolet, colors.permaSelago, isNoShow, modality]);

  const detailLine = useMemo(() => {
    switch (true) {
      case sessionOccured:
        return (
          <View row align="center">
            <View style={{ marginRight: 4 }}>
              <SquareCheckMark width={15} height={15} />
            </View>{' '}
            <TitleText>Session occurred</TitleText>
          </View>
        );
      case isClientNoShow:
        return <TitleText>Client no-show</TitleText>;
      case isTherapistNoShow:
        return <TitleText>Provider no-show</TitleText>;
      case isNewClientHold:
        return <TitleText>New client hold</TitleText>;
      default:
        return <></>;
    }
  }, [isClientNoShow, isTherapistNoShow, sessionOccured, isNewClientHold]);

  const breakText = useMemo(() => {
    if (state === 'confirmed') {
      if (hasBreakAfterSession) return 'Yes';
      return 'No';
    }
    return 'Optional';
  }, [hasBreakAfterSession, state]);

  const clientState = useMemo(() => {
    switch (true) {
      case status === 'client-no-show':
        return { text: 'Client no-show', icon: <ExclamationPoint /> };
      case state === 'tentative':
        return { text: 'Awaiting client confirmation', icon: <></> };
      case state === 'new_client_hold':
        return {
          text: (
            <View
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              row
              align="center"
            >
              New client hold
              <TooltipV2
                toolTipText={ClientHoldTooltip}
                isTooltipOpen={isHovering}
                containerStyle={{ right: -200, top: -50 }}
                circleStyle={{
                  width: 17,
                  height: 17,
                  marginTop: isMobile ? 0 : 2,
                  marginLeft: 3,
                }}
                circleColor="#5F7A9B"
                hoverColor="#005C65"
              />
            </View>
          ),
          icon: <></>,
        };
      case state === 'confirmed':
        return { text: 'Confirmed', icon: <CheckMark /> };
      case scheduledByUserType === 'client':
        return { text: 'Booked by client', icon: <CheckMark /> };
      default:
        return { text: 'Booked by client', icon: <CheckMark /> };
    }
  }, [isHovering, isMobile, scheduledByUserType, state, status]);

  const providerState = useMemo(() => {
    switch (true) {
      case status === 'therapist-no-show':
        return { text: 'Provider no-show', icon: <ExclamationPoint /> };
      case state === 'tentative':
        return { text: 'Awaiting your confirmation', icon: <></> };
      case state === 'new_client_hold':
        return { text: '', icon: <></> };
      case state === 'confirmed':
        return { text: 'Confirmed', icon: <CheckMark /> };
      case scheduledByUserType === 'provider':
        return { text: 'Booked by you', icon: <CheckMark /> };
      default:
        return { text: 'Booked by you', icon: <CheckMark /> };
    }
  }, [scheduledByUserType, state, status]);

  const footerState = useMemo(() => {
    switch (true) {
      case scheduledByUserType === 'client' && state === 'tentative':
        return { text: 'Confirm this session?', link: `/room/${roomID}/lvs-tab` };
      case scheduledByUserType === 'client' &&
        ['completed', 'completed-after-late-start'].includes(status) &&
        state === 'confirmed':
        return { text: 'Submit session report?', link: `/room/${roomID}/notes-tab` };
      case scheduledByUserType === 'provider' && state === 'tentative':
        return { text: 'Remind client to confirm?', link: `/room/${roomID}/lvs-tab` };
      default:
        return { text: '', link: `/room/${roomID}/lvs-tab` };
    }
  }, [roomID, scheduledByUserType, state, status]);

  const handleOpenRoomOnPress = () => {
    if (bookingID) {
      trackEvent(
        'calendarEventModalOpened',
        {
          actionName: 'liveSessionInteraction',
        },
        {
          timekitUUID: bookingID,
          updatedBy: 'provider',
        }
      );
      trackEvent(
        'calendarEventModalClicked',
        { actionName: 'liveSessionInteraction' },
        { timekitUUID: bookingID, action: 'open room', updatedBy: 'provider' }
      );
    }
    if (isInNativeWebView) {
      handleModalClosePress();
    } else {
      history.push(footerState?.link);
    }
  };

  return (
    <FloatingMenu
      hide={!showModal}
      onBackdropPress={handleModalClosePress}
      style={{
        width: isMobile ? 355 : 428,
        paddingTop: 23,
        paddingLeft: 23,
        paddingRight: 23,
        height: 'fit-content',
        maxHeight: 'unset',
        zIndex: 21,
        top: '50%',
        left: '50%',
        ...positioningStyle,
      }}
    >
      <CloseButton
        onPress={handleModalClosePress}
        style={{ position: 'relative', left: isMobile ? 290 : 365, bottom: 10 }}
        width={31}
        height={31}
      />
      {bookingID && (
        <ModalContainer>
          <View row>
            {modalityIcon}
            <View
              row
              align="start"
              justify="start"
              style={{ marginLeft: 16, position: 'relative', top: -7, marginBottom: 30 }}
            >
              <View style={{ width: isMobile ? 265 : undefined, textAlign: 'left' }}>
                <TitleText>
                  {`${getCustomerNameString(clients?.[0])} - Live ${capitalize(
                    modality
                  )} (${creditMinutes}min)${
                    isNoShow || sessionOccured || isNewClientHold ? ',' : ''
                  }`}{' '}
                  {detailLine}
                </TitleText>
                <Tiny style={{ textAlign: 'left' }}>
                  {beginsAtMoment.format('dddd, MMMM Do  •  h:mm')} -
                  {beginsAtMomentToMutate.add(creditMinutes, 'minutes').format('LT')} {timezone}
                </Tiny>
              </View>
            </View>
          </View>
          {isNewClientHold && (
            <View row style={{ marginBottom: 15 }}>
              <View
                style={{
                  marginTop: -20,
                  width: 330,
                  borderRadius: 5,
                  backgroundColor: '#E8F8FC',
                  padding: 12,
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 32,
                }}
              >
                <View style={{ width: 28, flex: 1, justifyContent: 'center' }} align="center">
                  <CheckAndXMarks />
                </View>
                <DetailText style={{ fontSize: 12 }}>
                  Once client onboarding is complete, we’ll notify you so you can confirm or decline
                  this session.
                </DetailText>
              </View>
            </View>
          )}
          <View row style={{ marginBottom: 15 }}>
            <View style={{ width: 28 }} align="center">
              <CircledPause />
            </View>
            <DetailText>15 min break after session: {breakText}</DetailText>
          </View>
          <View row style={{ marginBottom: 15 }}>
            <View style={{ width: 28 }} align="center">
              <PeopleOutline />
            </View>
            <DetailText>Participants</DetailText>
          </View>
          <View style={{ marginLeft: 43, marginBottom: 29 }}>
            <View style={{ marginBottom: 13 }}>
              {clients?.map((client) => (
                <View row align="center" key={client.userID}>
                  <AvatarWithStatus icon={clientState?.icon} />
                  <View justify="start" style={{ textAlign: 'left', marginLeft: 9 }}>
                    <Tiny variant="tinyBlack" style={{ fontWeight: 500 }}>
                      {getCustomerNameString(client)}
                    </Tiny>
                    <BookingStateText>{clientState?.text}</BookingStateText>
                  </View>
                </View>
              ))}
            </View>
            <View row align="center">
              <AvatarWithStatus therapistID={providerID} icon={providerState?.icon} />
              <View justify="start" style={{ textAlign: 'left', marginLeft: 9 }}>
                <Tiny style={{ fontWeight: 500, color: 'black' }}>
                  {providerFirstName}&nbsp;{providerLastName}
                </Tiny>
                <BookingStateText>{providerState?.text}</BookingStateText>
              </View>
            </View>
          </View>
        </ModalContainer>
      )}
      <View
        row
        style={{
          height: 60,
          width: isMobile ? 355 : 428,
          borderTop: `1px solid rgba(5, 5, 29, 0.1)`,
          alignSelf: 'center',
        }}
        align="center"
      >
        <Tiny variant="tinyBlack" style={{ marginLeft: 24, marginRight: 'auto' }}>
          {footerState.text}
        </Tiny>
        {providerID === roomProviderID && (
          <BaseButton
            style={{
              height: 30,
              width: 114,
              backgroundColor: colors.accessibilityGreenDark,
              borderRadius: 5,
              marginRight: 15,
              marginLeft: 'auto',
            }}
            onPress={handleOpenRoomOnPress}
            onContextMenu={(event) => event.preventDefault()}
            onMouseDown={(event) => {
              // handle right click
              if (event.nativeEvent.button === 2) {
                window.open(footerState?.link, '_blank');
              }
            }}
          >
            <Large variant="largeBoldWhite">{isInNativeWebView ? 'Close' : 'Open room'}</Large>
          </BaseButton>
        )}
      </View>
    </FloatingMenu>
  );
};

export default CalendarModal;
