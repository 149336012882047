import {
  Standard,
  View,
  useWindowWidthState,
  useEmotionTheme,
  Small,
  TouchableView,
  TicketIcon,
  CalendarPlusIcon,
  PlayVideoIcon,
  ListIcon,
} from '@talkspace/react-toolkit';
import { useEffect, useState } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useHistory } from '@/core/routerLib';
import styled from '@/core/styled';
import trackEvent from '../../../../modules/analytics/trackEvent';
import storage from '@/core/storage';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { CALENDAR_ONBOARDING_COMPLETE } from '../utils';

interface ActionsPanelProps {
  handleAddAvailability: () => void;
  handleWatchTutorial: () => void;
}
const Container = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      flex: 1,
      paddingTop: 20,
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: isMobile ? 20 : 50,
      paddingRight: isMobile ? 20 : 50,
    };
  }
);

const ActionWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: 5,
      justifyContent: isMobile ? 'flex-start' : 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 14,
    };
  }
);

const Label = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    marginRight: 14,
    marginLeft: 4,
    marginTop: -2,
  };
});

const Line = styled(View)(({ theme: { colors } }) => {
  return {
    width: 1,
    background: colors.geyser,
    marginRight: 14,
  };
});

const MobileButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    height: 48,
    width: 168,
    background: colors.accessibilityGreenDark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 40,
    boxShadow: '0px 4px 19px rgba(0, 9, 32, 0.27)',
    position: 'fixed',
    bottom: 30,
    right: 30,
    zIndex: 5,

    '&:focus': {
      zIndex: 999,
    },
  };
});

const ButtonTitle = styled(Standard)(({ theme: { colors } }) => {
  return {
    color: colors.white,
    fontSize: 15,
    fontWeight: 700,
    marginLeft: 5,
  };
});

const shouldNotDisplayOnBoardingStorage = storage.getItem(CALENDAR_ONBOARDING_COMPLETE);

const Action = ({
  title,
  icon,
  handleClick,
  shouldDisplayLine = true,
}: {
  title: string;
  icon: any;
  handleClick?: () => void;
  shouldDisplayLine?: boolean;
}) => (
  <TouchableView row onPress={handleClick}>
    {icon}
    <Label>{title}</Label>
    {shouldDisplayLine && <Line />}
  </TouchableView>
);

const ActionsPanel = ({ handleAddAvailability, handleWatchTutorial }: ActionsPanelProps) => {
  const { calendarSync: { syncBookingsON, syncAvailabilityON } = {} } = useFlags();
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const isWebview = ReactFrameService.instance().isInFrame();
  const history = useHistory();
  const [isOnBoardingDisplayed, setIsOnBoardingDisplayed] = useState<boolean>(
    !shouldNotDisplayOnBoardingStorage
  );

  useEffect(() => {
    const handleStorage = () => {
      setIsOnBoardingDisplayed(!storage.getItem(CALENDAR_ONBOARDING_COMPLETE));
    };
    window.addEventListener('storage', handleStorage);
    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, []);

  const handleRedirectToTimeOff = () => {
    trackEvent(
      'calendarHeaderButtonClicked',
      { actionName: 'activeExperiments' },
      { action: 'click Schedule Time Off' }
    );
    history.push('/my-account?scrollTo=timeOff');
  };

  const handleCalendarSync = () => {
    history.push('/my-account?scrollTo=calendarSync');
  };
  return (
    <>
      <Container>
        <View>
          {(!isOnBoardingDisplayed || isWebview) && (
            <Small style={{ color: colors.purple600, textAlign: 'center' }}>
              View your schedule and click to add your availability so clients can request live
              sessions.
            </Small>
          )}
          <ActionWrapper row>
            {!isMobile && (
              <Action
                title="Add availability"
                icon={<CalendarPlusIcon />}
                handleClick={handleAddAvailability}
              />
            )}
            <Action
              title="Watch tutorial"
              icon={<PlayVideoIcon />}
              handleClick={handleWatchTutorial}
            />
            <Action
              title="Schedule time off"
              icon={<TicketIcon />}
              handleClick={handleRedirectToTimeOff}
              shouldDisplayLine={syncBookingsON || syncAvailabilityON}
            />
            {(syncBookingsON || syncAvailabilityON) && (
              <>
                {isMobile && <br />}
                <Action
                  title="Calendar sync"
                  icon={<ListIcon />}
                  handleClick={handleCalendarSync}
                  shouldDisplayLine={false}
                />
              </>
            )}
          </ActionWrapper>
        </View>
      </Container>
      {isMobile && (
        <MobileButton row onPress={handleAddAvailability}>
          <CalendarPlusIcon color={colors.white} />
          <ButtonTitle>Add availability</ButtonTitle>
        </MobileButton>
      )}
    </>
  );
};

export default ActionsPanel;
