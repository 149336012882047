import { useHistory, useLocation, useParams, withRouter } from 'react-router';

import { useFlags } from 'launchDarkly/FlagsProvider';
import { CaretRight, styled, TouchableView, View } from '@talkspace/react-toolkit';
import ThreeDotsMenu from '@talkspace/react-toolkit/src/designSystems/components/ThreeDotsMenu';
import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import useQueryQuestionnaires, {
  Questionnaire,
} from '../../../Questionnaire/useQueryQuestionnaires';
import useMutationSendQuestionnaireMessage from '../../../Questionnaire/useMutationSendQuestionnaireMessage';
import { getUserData } from '../../../../../utils/token';
import useQueryTherapistAvailability from '../../../../../hooks/availability/useQueryTherapistAvailability';

const RiskAndSafeyCategory = styled(View)({
  lineHeight: '45px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #E8E8E8',
});

const QuestionnaireTouchableView = styled(TouchableView)({
  borderBottom: 'solid 1px #D1D6E1',
  justifyContent: 'space-between',
  height: 44,
  alignItems: 'center',
  flexDirection: 'row',
});

const RiskAndSafetyContent = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const { mutate: sendQuestionnaireMessage } = useMutationSendQuestionnaireMessage();
  const history = useHistory();

  const { data: questionnaires } = useQueryQuestionnaires({
    roomID: Number(roomID),
  });

  const safetyPlanMenuOptions = useMemo(() => {
    const safetyPlan = questionnaires?.find(
      (questionnaire) => questionnaire.type === 'safety_plan'
    );
    return safetyPlan // both edit and create will lead to the create safety plan form
      ? [
          {
            text: 'edit',
            onOptionPress: async () => {
              history.push(`/room/${roomID}/safety-plan/create`);
            },
          },
        ]
      : [
          {
            text: 'Create safety plan',
            onOptionPress: async () => {
              history.push(`/room/${roomID}/safety-plan/create`);
            },
          },
          {
            text: 'send to client',
            onOptionPress: async () => {
              sendQuestionnaireMessage({
                roomID: Number(roomID),
                questionnaireType: 'safety_plan',
              });
            },
          },
        ];
  }, [history, questionnaires, roomID, sendQuestionnaireMessage]);

  const onQuestionnairePress = useCallback(
    (questionnaire: Questionnaire) => {
      history.push(
        `/room/${roomID}/${
          questionnaire.type === 'cssrs_screener' ? 'cssrs-screener' : 'safety-plan'
        }/${questionnaire.type === 'cssrs_screener' ? `${questionnaire.id}/` : ''}${
          questionnaire.type === 'cssrs_screener' ? 'view' : 'create'
        }`
      );
    },
    [history, roomID]
  );

  return (
    <View>
      <p style={{ paddingRight: 20 }}>
        Visit our{' '}
        <a href="https://www.talkspace.com/public/terms" style={{ fontWeight: 700 }}>
          Help Center
        </a>{' '}
        to learn more about using risk assessments and safety plans.
      </p>
      <RiskAndSafeyCategory>
        <View>Columbia-Suicide Severity Rating Scale</View>
        <ThreeDotsMenu
          sectionName="cssrs"
          options={[
            {
              text: 'Create and fill out',
              onOptionPress: async () => {
                history.push(`/room/${roomID}/cssrs-screener/create`);
              },
            },
            {
              text: 'send to client',
              onOptionPress: async () => {
                sendQuestionnaireMessage({
                  roomID: Number(roomID),
                  questionnaireType: 'cssrs_screener',
                });
              },
            },
          ]}
        />
      </RiskAndSafeyCategory>
      <RiskAndSafeyCategory style={{ marginBottom: 20 }}>
        <View>Stanley-Brown Safety Plan</View>
        <ThreeDotsMenu sectionName="safetyPlan" options={safetyPlanMenuOptions} />
      </RiskAndSafeyCategory>

      {questionnaires !== undefined && questionnaires.length > 0 && (
        <>
          <View style={{ flexDirection: 'row', width: '90%' }}>
            <View style={{ width: '40%' }}>Name</View>
            <View style={{ width: '60%' }}>Date</View>
          </View>
          <View style={{ marginTop: 10, flexDirection: 'column' }}>
            {questionnaires?.map((questionnaire) => (
              <QuestionnaireTouchableView
                onPress={() => {
                  onQuestionnairePress(questionnaire);
                }}
              >
                <View style={{ flexDirection: 'row ', width: '90%' }}>
                  <View key={questionnaire.id} style={{ width: '40%' }}>
                    {questionnaire.type === 'safety_plan' ? 'S-BSP' : 'CSSRS'}
                  </View>
                  <View style={{ width: '60%' }}>
                    {moment(questionnaire.createdAt).format('MMM DD, YYYY')}
                  </View>
                </View>

                <CaretRight height={14} width={8} />
              </QuestionnaireTouchableView>
            ))}
          </View>
        </>
      )}
    </View>
  );
};
const RiskAndSafety = () => {
  const { riskAssessments } = useFlags();

  const location = useLocation<{ section?: 'risk-and-safety' }>();
  const therapistID = getUserData().id;
  const { data: therapistAvailability } = useQueryTherapistAvailability(therapistID);
  return (
    <>
      {therapistAvailability?.therapistType === 'primary' && riskAssessments && (
        <AccordionContainer
          open={location?.state?.section === 'risk-and-safety'}
          title="Risk and Safety"
          childComponents={[<RiskAndSafetyContent />]}
          dataQa="risk-and-safety-accordion"
        />
      )}
    </>
  );
};

export default withRouter(RiskAndSafety);
