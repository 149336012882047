import moment from 'moment';
import { Tag, TextDS, Tooltip, useEmotionTheme, View } from '@talkspace/react-toolkit';
import CircleInfo from '@talkspace/react-toolkit/src/designSystems/icons/CircleInfo';
import { elevation2Style } from '@talkspace/react-toolkit/src/designSystems/styles/elevation';
import { useParams } from '@/core/routerLib';
import useQueryRiskIndications from '../../../../hooks/useQueryRiskIndications';
import { RiskIndicationSource, RiskIndicationType } from '../../../../types/clinical';

const riskLabels: Record<RiskIndicationType, string> = {
  'suicidal-ideation': 'Suicidal ideation',
  'suicidal-planning': 'Suicidal planning',
  'homicidal-ideation': 'Homicidal ideation',
  'homicidal-planning': 'Homicidal planning',
  'risk-mentioned': 'Risk mentioned',
};

const formatRiskSourceAndDate = (riskDate: string, source: RiskIndicationSource) => {
  const riskMoment = moment(riskDate);
  const currentYear = moment().year();

  const formattedDate =
    riskMoment.year() === currentYear
      ? riskMoment.format('MMM D')
      : riskMoment.format('MMM D, YYYY');

  if (source === 'intake') {
    return `${formattedDate} on intake`;
  }

  if (source === 'session') {
    return `${formattedDate} in the chat`;
  }

  return `${formattedDate} in a progress note`;
};

export default function RiskInfo() {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: riskIndications = [] } = useQueryRiskIndications(Number(roomID));

  const { colorRoles } = useEmotionTheme();

  return (
    <View style={{ gap: 4 }}>
      <View row align="center" style={{ gap: 2 }}>
        <TextDS variant="headingMd">Risk</TextDS>
        <Tooltip
          place="top"
          buttonStyle={{ display: 'flex', alignItems: 'center' }}
          arrowColor={`${colorRoles.surfaces.defaultSubtle} !important`}
          messageStyle={{
            backgroundColor: `${colorRoles.surfaces.defaultSubtle} !important`,
            color: `${colorRoles.icons.iconDefaultDefault} !important`,
            borderRadius: '12px !important',
            padding: '16px !important',
            opacity: '1 !important',
            ...elevation2Style,
          }}
          messageComponent={
            <TextDS variant="bodySm">
              Risk information is based on the client’s intake, messages sent in the chat, and
              information in progress notes.
            </TextDS>
          }
        >
          <CircleInfo colorType="subtlest" />
        </Tooltip>
      </View>

      <View style={{ gap: 4 }}>
        {riskIndications.length > 0 ? (
          riskIndications.map((riskIndication) => (
            <View row key={riskIndication.type} style={{ gap: 4 }}>
              <Tag text={riskLabels[riskIndication.type]} color="criticalDefault" />

              <TextDS variant="bodySm" colorRole="textSubtle">
                {formatRiskSourceAndDate(riskIndication.date, riskIndication.source)}
              </TextDS>
            </View>
          ))
        ) : (
          <TextDS variant="bodySm" colorRole="textSubtle">
            None indicated
          </TextDS>
        )}
      </View>
    </View>
  );
}
