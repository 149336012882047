import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  A11yPhoneInput,
  isPhoneNumberValid,
  OptionType,
  Select,
  TextInput,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { teenContactRelationship } from '@talkspace/configs';
import useQueryParentalConsent from 'ts-frontend/hooks/parentalConsent/useQueryParentalConsent';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useParams } from '@/core/routerLib';

import {
  getCustomerInformation as getCustomerInformationAction,
  setCustomerInformation as setCustomerInformationAction,
} from '../../../../../actions/CustomerInformationActions';
import {
  getEmergencyContact as getEmergencyContactAction,
  setEmergencyContact as setEmergencyContactAction,
} from '../../../../../actions/EmergencyContactActions';
import UserBadge from '../../../../Reusable/UserBadge/UserBadge';
import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import SocketService from '../../../../../utils/socket/SocketService';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import { checkIfShouldBeSearchable } from '../../../../../utils/optionsHelpers';
import { CustomerInformation } from '../../../../../types/customer';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';

const MIN_CLIENT_AGE = 13;
const MAX_CLIENT_AGE = 120;

interface FormValues {
  dateOfBirthIsValid: boolean;
  phoneNumber: string;
  contactPhoneNumber: string;
  phoneEditedByProvider: boolean;
  isPhoneNumberError: boolean;
  isContactPhoneNumberError: boolean;
}

const initialFormValues: FormValues = {
  dateOfBirthIsValid: true,
  phoneNumber: '',
  contactPhoneNumber: '',
  phoneEditedByProvider: false,
  isPhoneNumberError: false,
  isContactPhoneNumberError: false,
};

interface Props {
  customerInformation: CustomerInformation;
  emergencyContact: Record<string, any>;
  getEmergencyContact: (clientUserID: number) => void;
  setEmergencyContact: (clientUserID: number, data: Record<string, any>) => void;
  getCustomerInformation: (clientUserID: number) => void;
  setCustomerInformation: (clientUserID: number, data: Record<string, any>) => void;
}

const CustomerInformationContainer = ({
  customerInformation,
  emergencyContact,
  getEmergencyContact,
  setEmergencyContact,
  getCustomerInformation,
  setCustomerInformation,
}: Props) => {
  const [formValues, setFormValues] = useState(initialFormValues);

  const { isMobile } = useWindowWidthState();

  const { roomID } = useParams<{ roomID: string }>();

  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);

  const { data: parentalConsent } = useQueryParentalConsent(clientUserID);

  const { data: participants = [] } = useQueryParticipants(Number(roomID));

  useEffect(() => {
    if (clientUserID) {
      getEmergencyContact(clientUserID);
    }
  }, [clientUserID, getEmergencyContact]);

  useEffect(() => {
    const handleSubmittedIntakeSurveyEvent = (data) => {
      if (Number(data.roomID) !== Number(roomID) || !clientUserID) {
        return;
      }

      getCustomerInformation(clientUserID);
      getEmergencyContact(clientUserID);
    };

    SocketService.instance().on('submittedIntakeSurvey', handleSubmittedIntakeSurveyEvent);

    return () => {
      SocketService.instance().off('submittedIntakeSurvey', handleSubmittedIntakeSurveyEvent);
    };
  }, [clientUserID, getCustomerInformation, getEmergencyContact, roomID]);

  const getClientDisplayName = () => {
    if (!clientUserID) {
      return '';
    }

    return customerInformation.preferredName === null
      ? participants.find((client) => client.userID === clientUserID)?.displayName
      : customerInformation.preferredName;
  };

  const createHandleEmergencyContactFieldOnBlur = (field: string) => (e) => {
    let { value } = e.target;
    if (field === 'phone') {
      value = formValues.phoneNumber;
      const isPhoneNumberError = !!(formValues.phoneNumber && !isPhoneNumberValid(value));
      setFormValues((prev) => {
        return { ...prev, isPhoneNumberError };
      });
      if (isPhoneNumberError) return;
    } else if (field === 'contactPhone') {
      value =
        formValues.contactPhoneNumber ||
        emergencyContact?.emergencyContactByClientUserID[customerInformation.clientUserID]
          ?.contactPhone;
      const isContactPhoneNumberError = !!(
        formValues.contactPhoneNumber && !isPhoneNumberValid(value)
      );
      setFormValues((prev) => {
        return { ...prev, isContactPhoneNumberError };
      });
      if (isContactPhoneNumberError) return;
    }

    setEmergencyContact(customerInformation.clientUserID, { [field]: value });
  };

  const createHandleEmergencyContactSelectOnChange =
    (field: string) => (selectObject: OptionType | null) => {
      setEmergencyContact(customerInformation.clientUserID, {
        [field]: selectObject,
      });
    };

  const createHandleSelectChange =
    (field: string, sideEffect?: (option: OptionType | null) => Record<string, unknown>) =>
    (selectObject: OptionType | null) => {
      setCustomerInformation(customerInformation.clientUserID, {
        [field]: selectObject,
        ...(sideEffect && sideEffect(selectObject)),
      });
    };

  const createHandleTextInputBlur = (field: string) => (e) => {
    setCustomerInformation(customerInformation.clientUserID, {
      [field]: e.target.value,
    });
  };

  const handleUserToggle = (customerUserID: number) => {
    getCustomerInformation(customerUserID);
    getEmergencyContact(customerUserID);
  };

  const createUserButtons = () => (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {participants.map((customer) => (
        <UserBadge
          key={customer.userID}
          onClick={() => {
            if (customer.userID !== clientUserID) {
              handleUserToggle(customer.userID);
            }
          }}
          preferredName={customer.displayName}
          active={customer.userID === clientUserID}
        />
      ))}
    </div>
  );

  const handleCountrySideEffect = (selectObject: OptionType | null) => {
    if (!selectObject || selectObject.value !== 'US') {
      return { state: null };
    }
    return {};
  };

  const handleDateOfBirthOnBlur = (e) => {
    const format = 'MM/DD/YYYY';
    const { value } = e.target;
    if (!value) {
      setFormValues((prev) => {
        return { ...prev, dateOfBirthIsValid: true };
      });
      if (value !== customerInformation.dateOfBirth) {
        setCustomerInformation(customerInformation.clientUserID, { dateOfBirth: value });
      }
      return;
    }
    if (!moment(value, format).isValid()) {
      setFormValues((prev) => {
        return { ...prev, dateOfBirthIsValid: false };
      });
      return;
    }
    const years = moment().diff(moment(value, format), 'years');
    if (years >= MIN_CLIENT_AGE && years <= MAX_CLIENT_AGE) {
      setFormValues((prev) => {
        return { ...prev, dateOfBirthIsValid: true };
      });
      setCustomerInformation(customerInformation.clientUserID, {
        dateOfBirth: moment(value, format).format('Y-MM-DD'),
      });
    } else {
      setFormValues((prev) => {
        return { ...prev, dateOfBirthIsValid: false };
      });
    }
  };

  const handleOnChangePhoneNumber = (phoneNumber: string) =>
    setFormValues((prev) => {
      return { ...prev, phoneNumber, phoneEditedByProvider: true };
    });

  const handleOnChangeContactPhoneNumber = (contactPhoneNumber: string) => {
    setFormValues((prev) => {
      return { ...prev, contactPhoneNumber };
    });
  };

  const isDisabled = (componentName: string) => {
    const { country } = customerInformation;
    if (componentName === 'state') {
      return country && country.value !== 'US';
    }
    return false;
  };

  const {
    gender,
    dateOfBirth,
    country,
    state,
    relationshipStatus,
    educationLevel,
    workStatus,
    ethnicity,
  } = customerInformation;

  const clientEmergencyContact =
    emergencyContact.emergencyContactByClientUserID[customerInformation.clientUserID] || {};
  const {
    firstName,
    middleName,
    lastName,
    address,
    address2,
    city,
    zipcode,
    phone,
    contactName,
    contactPhone,
    relationship,
  } = clientEmergencyContact;
  const { dateOfBirthIsValid } = formValues;
  const preferredName = getClientDisplayName();
  const showUserButtons = !!participants && !!participants.length && participants.length > 1;

  return (
    <AccordionContainer title="Customer Information">
      {showUserButtons && createUserButtons()}
      <TextInput
        value={preferredName}
        placeholder="Preferred name"
        onBlur={createHandleTextInputBlur('preferredName')}
        disabled={isDisabled('preferredName')}
      />
      <TextInput
        value={firstName}
        placeholder="First name"
        onBlur={createHandleEmergencyContactFieldOnBlur('firstName')}
      />
      <TextInput
        value={middleName}
        placeholder="Middle name"
        onBlur={createHandleEmergencyContactFieldOnBlur('middleName')}
      />
      <TextInput
        value={lastName}
        placeholder="Last name"
        onBlur={createHandleEmergencyContactFieldOnBlur('lastName')}
      />
      <Select
        value={gender}
        options={getOptionsByField('gender')}
        placeholder="Gender"
        onChange={createHandleSelectChange('gender')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('gender')}
      />
      <TextInput
        placeholder="Date of birth"
        value={(dateOfBirth && moment(dateOfBirth).format('MM/DD/Y')) || undefined}
        isValid={dateOfBirthIsValid}
        onBlur={handleDateOfBirthOnBlur}
        maskType="date"
        alwaysShowPlaceholder
      />
      <Select
        value={country}
        options={getOptionsByField('country')}
        placeholder="Country"
        onChange={createHandleSelectChange('country', handleCountrySideEffect)}
        isDisabled={isDisabled('country')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('country')}
      />
      <TextInput
        value={address}
        placeholder="Street address"
        onBlur={createHandleEmergencyContactFieldOnBlur('address')}
        multiline
      />
      <TextInput
        value={address2}
        placeholder="Street address line 2"
        onBlur={createHandleEmergencyContactFieldOnBlur('address2')}
        multiline
      />
      <TextInput
        value={city}
        placeholder="City"
        onBlur={createHandleEmergencyContactFieldOnBlur('city')}
      />
      <Select
        value={state}
        options={getOptionsByField('state')}
        placeholder="State"
        onChange={createHandleSelectChange('state')}
        isDisabled={isDisabled('state')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('state')}
      />
      <TextInput
        value={zipcode}
        placeholder="Zipcode"
        onBlur={createHandleEmergencyContactFieldOnBlur('zipcode')}
        maskType="zipcode"
        alwaysShowPlaceholder
      />
      <A11yPhoneInput
        height={33}
        dataQa="customerInformationPhoneInput"
        value={phone || formValues.phoneNumber}
        disabled={!!phone && !formValues.phoneEditedByProvider}
        onBlur={createHandleEmergencyContactFieldOnBlur('phone')}
        handleInputValueChange={handleOnChangePhoneNumber}
        isError={formValues.isPhoneNumberError}
        isTherapistCRM
      />
      <Select
        value={relationshipStatus}
        options={getOptionsByField('relationshipStatus')}
        placeholder="Relationship Status"
        onChange={createHandleSelectChange('relationshipStatus')}
        isDisabled={isDisabled('relationshipStatus')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('relationshipStatus')}
      />
      <Select
        value={educationLevel}
        options={getOptionsByField('educationLevel')}
        placeholder="Level of education"
        onChange={createHandleSelectChange('educationLevel')}
        isDisabled={isDisabled('educationLevel')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('educationLevel')}
      />
      <Select
        value={workStatus}
        options={getOptionsByField('workStatus')}
        placeholder="Work status"
        onChange={createHandleSelectChange('workStatus')}
        isDisabled={isDisabled('workStatus')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('workStatus')}
      />
      <Select
        value={ethnicity}
        options={getOptionsByField('ethnicity')}
        placeholder="Race or ethinicity"
        onChange={createHandleSelectChange('ethnicity')}
        isDisabled={isDisabled('ethnicity')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('ethnicity')}
      />
      {parentalConsent && (
        <>
          <div className="ts-component-accordion-container-title" style={{ marginTop: 40 }}>
            Parent or guardian contact details
          </div>
          <TextInput
            value={`${parentalConsent?.consenterFirstName || ''} ${
              parentalConsent?.consenterLastName || ''
            }`}
            placeholder="Parent or guardian's full name"
            disabled
          />
          <TextInput
            value={
              parentalConsent?.consenterRelationship &&
              teenContactRelationship[parentalConsent?.consenterRelationship]
            }
            placeholder="Relationship to client"
            disabled
          />
          <TextInput
            value={parentalConsent?.consenterEmail}
            placeholder="Parent or guardian's email address"
            disabled
          />
        </>
      )}
      <div className="ts-component-accordion-container-title" style={{ marginTop: 40 }}>
        Emergency contact details
      </div>
      <TextInput
        value={contactName}
        placeholder="Emergency contact's full name"
        onBlur={createHandleEmergencyContactFieldOnBlur('contactName')}
      />
      <Select
        value={relationship || null}
        options={getOptionsByField('relationship')}
        placeholder="Relationship to client"
        onChange={createHandleEmergencyContactSelectOnChange('relationship')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('relationship')}
      />
      <A11yPhoneInput
        height={33}
        dataQa="customerInformationEmergencyContactPhoneInput"
        value={contactPhone || formValues.contactPhoneNumber}
        onBlur={createHandleEmergencyContactFieldOnBlur('contactPhone')}
        handleInputValueChange={handleOnChangeContactPhoneNumber}
        label="Emergency contact's phone number"
        isError={formValues.isContactPhoneNumberError}
        isTherapistCRM
      />
    </AccordionContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    emergencyContact: state.emergencyContact,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCustomerInformation: setCustomerInformationAction,
      getCustomerInformation: getCustomerInformationAction,
      getEmergencyContact: getEmergencyContactAction,
      setEmergencyContact: setEmergencyContactAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInformationContainer);
