import { FunctionComponent, useEffect, useRef, useState, useCallback } from 'react';
import {
  Large,
  View,
  Small,
  styled,
  ExtraTiny,
  useEmotionTheme,
  useWindowWidthState,
  BannerCard,
} from '@talkspace/react-toolkit';
import useMutationSubmitProgressNote from 'hooks/notes/useMutationSubmitProgressNote';
import useMutationSaveDraftProgressNote from 'hooks/notes/useMutationSaveDraftProgressNote';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useMutationUpdateProgressNote from 'hooks/notes/useMutationUpdateProgressNote';
import Submenu from 'components/Reusable/Submenu';
import useQueryAdminConfigValue from 'hooks/useQueryUsersAdminConfigValue';
import {
  ProgressNoteDetailsParams,
  useInRoomSchedulingActions,
  useInRoomSchedulingState,
} from 'inRoomScheduling';
import { useFlags } from 'launchDarkly/FlagsProvider';
import moment from 'moment';
import { useHistory, useLocation, useParams } from '@/core/routerLib';
import {
  getSubmenuTitleAndSubtitle,
  getSessionReportPosition,
  ACTION_SETTLED_TIMEOUT,
  HOW_AM_I_PAID_ARTICLE,
  QUALITY_DOCUMENTATION_ARTICLE,
} from '../../utils';
import { NoteError, ProgressNoteFormContainerLocationState, ProgressNoteTabs } from '../../types';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/TherapistProgressNoteFormContext';
import { FormSection, FormSections } from '../context/types';
import SessionDateAndModalitySection from './SessionDateAndModalitySection';
import SessionInformationSection from './SessionInformationSection';
import DiagnosisSection from './DiagnosisSection';
import RiskAssessmentSection from './RiskAssessmentSection';
import SubstanceUseSection from './SubstanceUseSection';
import trackEvent from '../../../../../../modules/analytics/trackEvent';
import TreatmentPlanProgressSection from './TreatmentPlanProgressSection';
import SessionSummarySectionV2 from './SessionSummarySectionV2';
import NoteFormFooterV2 from '../../NoteFormFooterV2';
import { SaveProgressNoteDialogLocationState } from '../../SaveProgressNoteDialog';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';
import SectionContainer from '../containers/SectionContainer';
import SessionSection from './SessionLiveAndMessaging';
import ProgressNoteDialog from './ProgressNoteDialog';
import useQueryTherapistAvailability from '../../../../../../hooks/availability/useQueryTherapistAvailability';
import { getUserData } from '../../../../../../utils/token';
import SessionSummarySectionV3 from './SessionSummarySectionV3';
import SmartNoteTooltip from './SmartNote/SmartNoteTooltip';
import { UnsubmittedSessionTask } from '../../../../../../hooks/dashboard/useQueryTaskListV3';
import ProgressNoteTabsNavigator from './ProgressNoteTabsNavigator';
import TransferredClientSection from './TransferredClientSection';
import useQuerySessionMessages from '../../../../../../hooks/notes/useQuerySessionMessages';
import SessionMessagesChat from './SessionMessagesChat';

interface CheckIfCanSubmitParams {
  formSections: FormSections;
  isPsych: boolean;
  isClientDischarged?: boolean;
  serviceStartDate?: Date | null;
  serviceEndDate?: Date | null;
  isEAP?: boolean;
  isTherapist?: boolean;
  showGlobalError?: boolean;
}

type Action = 'submit' | 'save' | 'draft' | 'back' | 'navigateToScheduler';

const cannotSubmitReasons = {
  discharged: 'Client is discharged',
  futureStartDate: 'Service start date is in the future',
  futureEndDate: 'Service end date is in the future',
  sectionIncomplete: 'To submit the note, make sure each section is confirmed with a check mark.',
};

const Seperator = styled(View)(({ theme: { colors } }) => {
  return {
    height: 1,
    background: colors.catskillWhite,
    marginBottom: 10,
    marginTop: 10,
  };
});

const CertifyText = styled(ExtraTiny)(({ theme: { colors } }) => {
  return {
    fontSize: 11,
    textAlign: 'center',
    color: colors.permaRiverBed,
    marginTop: 10,
    marginBottom: 15,
    whiteSpace: 'pre-line',
  };
});

const checkCanSubmitErrorMessage = ({
  formSections,
  isPsych,
  serviceStartDate,
  serviceEndDate,
  showGlobalError,
}: CheckIfCanSubmitParams) => {
  const now = new Date();
  now.setHours(23, 59, 59, 999); // latest time for today

  if (serviceStartDate && serviceStartDate > now) {
    return cannotSubmitReasons.futureStartDate;
  }
  if (serviceEndDate && serviceEndDate > now) {
    return cannotSubmitReasons.futureEndDate;
  }

  const sharedSections: Array<FormSection> = [
    'sessionDateAndModality',
    'sessionInformation',
    'diagnosis',
    'riskAssessment',
    'substanceUse',
  ];
  const nonPsychOnlySections: Array<FormSection> = ['treatmentPlanProgress', 'sessionSummary'];
  const psychOnlySections: Array<FormSection> = [
    'medicalInformation',
    'mentalStatusExam',
    'psychSessionSummary',
    'psychPlan',
  ];
  const sectionNames = isPsych
    ? [...sharedSections, ...psychOnlySections]
    : [...sharedSections, ...nonPsychOnlySections];

  let sectionNotCompleteMessage = showGlobalError
    ? cannotSubmitReasons.sectionIncomplete
    : undefined;
  sectionNames.forEach((sectionName) => {
    if (!formSections[sectionName].completed) {
      sectionNotCompleteMessage = cannotSubmitReasons.sectionIncomplete;
    }
  });
  if (sectionNotCompleteMessage) {
    return sectionNotCompleteMessage;
  }

  return null;
};

const checkIfChanged = ({
  formSections,
  isClientDischarged,
  isEAP,
}: Omit<CheckIfCanSubmitParams, 'isPsych'>) => {
  if (isClientDischarged && !isEAP) {
    return false;
  }
  const changedSections = Object.keys(formSections).filter(
    (sectionName) => !!formSections[sectionName].changed
  );
  return !!changedSections.length;
};

const ProgressNoteFormContainer: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation<ProgressNoteFormContainerLocationState | undefined>();
  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [error, setError] = useState<NoteError>({ title: '', message: '' });
  const [shouldDisplayNonPaybleDialog, setShouldDisplayNonPaybleDialog] = useState<boolean>(false);
  const [shortSessionSummaryDialog, setShortSessionSummaryDialog] = useState<boolean>(false);
  const [shouldDisplayTransferredDraftDialog, setShouldDisplayTransferredDraftDialog] =
    useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<ProgressNoteTabs>(ProgressNoteTabs.WriteNote);
  const userAction = useRef<Action | null>(null);
  const formState = useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();

  const {
    accessUnsubmittedSessions: { maxTaskListDays } = {
      maxTaskListDays: 90,
    },
  } = useFlags();
  const { data: noSessionPaymentOverDaysLimit } = useQueryAdminConfigValue<Record<string, number>>({
    key: 'no_session_payment_over_days_limit',
    json: true,
  });

  const {
    showGlobalError,
    formMode,
    currentSection,
    isSaveAsDraftPressed,
    isSubmissionTooLateForPayout,
    submissionStatus,
    draftProgressNoteID,
    unsubmittedSession,
    unsubmittedFreeLiveCalls,
    unsubmittedSessionID,
    clientUserID,
    ...restFormState
  } = formState;

  const progressNoteID = noteID || draftProgressNoteID?.toString();

  const { isTransferred, asyncSessionID } = (unsubmittedSession as UnsubmittedSessionTask) || {
    isTransferred: false,
  };
  const shouldDisplayChat = isTransferred && !!asyncSessionID;
  const { data: sessionMessagesData, isLoading: sessionMessagesLoading } = useQuerySessionMessages({
    roomID,
    asyncSessionID,
    enabled: shouldDisplayChat,
  });

  const accountType = unsubmittedSession?.accountType;

  const { isMobile } = useWindowWidthState();
  const { cutoffDate } = noSessionPaymentOverDaysLimit || {};

  const shouldFetchTheNote = formState.formMode === 'edit' || formState.formMode === 'view';
  const { data: progressNoteData, isLoading: isProgressNoteLoading } = useQueryProgressNote(
    clientUserID,
    progressNoteID,
    shouldFetchTheNote
  );
  const isReadOnly = progressNoteData?.readOnly;
  const therapistID = getUserData()?.id;

  const { dispatchSetProgressNoteDetails, dispatchSetNavigateToScheduler } =
    useInRoomSchedulingActions();
  const { navigateToSchedulerEventDate } = useInRoomSchedulingState();
  const { colors } = useEmotionTheme();
  const { mutate: saveDraftProgressNote, isLoading: isSaveDraftLoading } =
    useMutationSaveDraftProgressNote();

  const { mutate: submitProgressNote, isLoading: isSubmitLoading } =
    useMutationSubmitProgressNote();

  const { mutate: updateProgressNote, isLoading: isUpdateLoading } =
    useMutationUpdateProgressNote();

  const { data: therapistAvailability } = useQueryTherapistAvailability(therapistID);

  const {
    aiDocumentation: { treatmentPlanBasedDocumentation } = {
      treatmentPlanBasedDocumentation: false,
    },
  } = useFlags();

  const postActionRoute = location?.state?.postActionRoute || 'notes-tab';
  const [smartNoteError, setSmartNoteError] = useState<boolean>(false);

  const isLoading = isSaveDraftLoading || isSubmitLoading || isUpdateLoading;

  useEffect(() => {
    if (!isProgressNoteLoading && !isTransferred) {
      if (!unsubmittedSessionID && progressNoteData?.status !== 'submitted') {
        history.push(`/room/${roomID}/notes-tab`);
      }
    }
  }, [
    history,
    roomID,
    progressNoteData?.status,
    isProgressNoteLoading,
    unsubmittedSessionID,
    isTransferred,
  ]);

  useEffect(() => {
    const payload: ProgressNoteDetailsParams = {
      isInCreateMode: formMode === 'create',
    };

    dispatchSetProgressNoteDetails(payload);

    return () => {
      dispatchSetProgressNoteDetails({
        isInCreateMode: false,
      });
    };
  }, [dispatchSetProgressNoteDetails, formMode]);

  useEffect(() => {
    if (!isMobile) {
      setSelectedTab(ProgressNoteTabs.WriteNote);
    }
  }, [isMobile]);

  const { modalityID } = formState;
  // do not allow text notes to have attached video calls
  const videoCalls = modalityID === 1 ? [] : restFormState.videoCalls;

  const handleCloseProgressNoteForm = () => {
    if (isTransferred) {
      history.push('/tasks');
    }
  };

  const trackNoteEvent = (
    action: 'saveNoteDraft' | 'clinicalNoteSubmitted',
    success: boolean,
    errorData?: NoteError
  ) => {
    let actionLabel: string;

    if (action === 'saveNoteDraft') {
      actionLabel = success ? 'Provider saves draft success' : 'Provider saves draft error';
    } else {
      actionLabel = success ? 'Provider submits note success' : 'Provider submits note error';
    }

    const eventProperties = {
      action: actionLabel,
      roomID,
      serviceStartDate: formState.serviceStartDate,
      serviceEndDate: formState.serviceEndDate,
      modalityID: formState.modalityID,
      submittedSource: location?.state?.source || 'task_list',
      noteStatus: submissionStatus,
      unsubmittedSessionID,
      generatedSessionSummaryID: formState.generatedSessionSummaryID,
      ...(errorData && { errorMessage: errorData.message }),
    };

    trackEvent(
      action,
      {
        actionName: 'progressNotesInteraction',
      },
      eventProperties
    );
  };

  const handleSmartNoteError = (isError: boolean) => {
    setSmartNoteError(isError);
  };

  const handleActionSuccess = (action?: 'saveNoteDraft' | 'clinicalNoteSubmitted') => {
    if (action) {
      trackNoteEvent(action, true);
    }
    setShouldDisplayNonPaybleDialog(false);
    setShortSessionSummaryDialog(false);
    setTimeout(() => {
      setShowSpinner(false);
      if (isTransferred) {
        setShouldDisplayTransferredDraftDialog(false);
        handleCloseProgressNoteForm();
      } else {
        history.push(`/room/${roomID}/${postActionRoute}`);
      }
    }, ACTION_SETTLED_TIMEOUT);
  };

  const handleActionError = (err, action?: 'saveNoteDraft' | 'clinicalNoteSubmitted') => {
    userAction.current = null;
    setShouldDisplayNonPaybleDialog(false);
    setShortSessionSummaryDialog(false);
    setShowSpinner(false);
    const errorData: NoteError = err?.data || {};
    if (errorData.generatedSessionSummaryID) {
      setSmartNoteError(true);
    }
    setShowErrorAlert(true);
    setError(errorData);
    if (action) {
      trackNoteEvent(action, false, errorData);
    }
  };

  const handleCloseErrorBannerPress = () => {
    setFormState({ showGlobalError: false });
  };

  const handleErrorAlertPress = () => {
    setShowErrorAlert(false);
    setShowSpinner(false);
  };

  const handleDeleteDraftPress = () => {
    if (progressNoteID) {
      history.push(`/room/${roomID}/progress-notes/${progressNoteID}/edit/delete-dialog`);
    }
  };

  const handleSaveDraftPress = () => {
    setShowSpinner(true);
    saveDraftProgressNote(
      {
        roomID,
        noteID: progressNoteID,
        clientUserID,
        isTherapist: true,
        ...restFormState,
        videoCalls,
      },
      {
        onSuccess: () => handleActionSuccess('saveNoteDraft'),
        onError: (err) => handleActionError(err, 'saveNoteDraft'),
      }
    );
  };

  const getDefualtValuesBeforeSubmit = (): FormSections => {
    const fields = [
      'sessionDateAndModality',
      'sessionInformation',
      'diagnosis',
      'riskAssessment',
      'substanceUse',
      'sessionSummary',
    ];
    const formSections = { ...formState.formSections };
    for (const key in fields) {
      if (formSections[key]) formSections[key].open = false;
    }
    return formSections;
  };
  const cannotSubmitReason = checkCanSubmitErrorMessage({
    formSections: formState.formSections,
    isPsych: false,
    serviceStartDate: formState.serviceStartDate,
    serviceEndDate: formState.serviceEndDate,
    showGlobalError,
  });
  const canSubmit = !cannotSubmitReason;

  const submitNote = () => {
    const formSections = getDefualtValuesBeforeSubmit();
    const dataToAdd = {
      ...restFormState,
      formSections: {
        ...restFormState.formSections,
        ...formSections,
      },
    };
    setShowSpinner(true);
    submitProgressNote(
      {
        roomID,
        noteID: progressNoteID,
        isTherapist: true,
        clientUserID,
        isTransferred,
        therapistID,
        ...dataToAdd,
        videoCalls,
      },
      {
        onSuccess: () => handleActionSuccess('clinicalNoteSubmitted'),
        onError: (err) => handleActionError(err, 'clinicalNoteSubmitted'),
      }
    );
  };

  const handleSubmit = () => {
    if (canSubmit) {
      const hasReachedMaxDays = moment(formState.serviceStartDate).isBefore(
        moment().subtract(maxTaskListDays, 'days'),
        'days'
      );
      const isICP = therapistAvailability?.isW2 === 0;

      const showICPLateSubmissionScreen = cutoffDate
        ? moment().isSameOrAfter(moment(cutoffDate), 'day')
        : false;

      const isSubmissionTooLateForPayoutV1 =
        showICPLateSubmissionScreen &&
        (accountType === 'bh' || (accountType === 'eap' && draftProgressNoteID)) &&
        isICP &&
        hasReachedMaxDays;

      if (isSubmissionTooLateForPayout || isSubmissionTooLateForPayoutV1) {
        setShouldDisplayNonPaybleDialog(true);
      } else if (formState.isSessionSummaryTooShort) {
        setShortSessionSummaryDialog(true);
      } else {
        submitNote();
      }
    } else {
      userAction.current = null;
      setFormState({ showGlobalError: true });
    }
  };

  const handleSavePress = () => {
    if (progressNoteID && canSubmit) {
      setShowSpinner(true);
      updateProgressNote(
        {
          roomID,
          noteID: progressNoteID,
          clientUserID,
          isTherapist: true,
          ...restFormState,
        },
        { onSuccess: () => handleActionSuccess(), onError: (err) => handleActionError(err) }
      );
    }
  };

  const handleBack = () => {
    const saveProgressNoteDialogLocationState: SaveProgressNoteDialogLocationState = {
      action: {
        type: 'navigate-to-notes-tab',
      },
    };
    if (progressNoteID) {
      history.push(
        `/room/${roomID}/progress-notes/${progressNoteID}/edit/save-draft-dialog`,
        saveProgressNoteDialogLocationState
      );
    } else {
      history.push(
        `/room/${roomID}/progress-notes/new/save-draft-dialog`,
        saveProgressNoteDialogLocationState
      );
    }
  };

  const handleNavigateToScheduler = useCallback(() => {
    history.push(`/room/${roomID}/progress-notes/new/save-draft-dialog`, {
      action: {
        type: 'navigate-to-scheduling',
      },
    });
  }, [history, roomID]);

  useDidUpdateEffect(() => {
    if (currentSection === 'finished') {
      switch (userAction.current) {
        case 'submit':
          handleSubmit();
          break;
        case 'save':
          handleSavePress();
          break;
        case 'back':
          handleBack();
          break;
        case 'draft':
          handleSaveDraftPress();
          setFormState({ isSaveAsDraftPressed: false });
          break;
        case 'navigateToScheduler':
          handleNavigateToScheduler();
          break;
        default:
          break;
      }
    }
  }, [currentSection]);

  const handleCancelPress = () => {
    history.goBack();
  };

  const handleEditPress = () => {
    history.push(`/room/${roomID}/progress-notes/${progressNoteID}/edit`);
  };

  const hasChanged = checkIfChanged({
    formSections: formState.formSections,
    isClientDischarged: false,
    isEAP: accountType === 'eap',
  });

  const handleUserAction = useCallback(
    (action: Action, isSaveAsDraft = false) => {
      setFormState({
        currentSection: 'sessionDateAndModality',
        isSaveAsDraftPressed: isSaveAsDraft,
      });
      userAction.current = action;
    },
    [setFormState]
  );

  useEffect(() => {
    if (navigateToSchedulerEventDate && !isTransferred) {
      handleUserAction('navigateToScheduler', true);
      dispatchSetNavigateToScheduler(null);
    }
  }, [
    navigateToSchedulerEventDate,
    dispatchSetNavigateToScheduler,
    handleUserAction,
    isTransferred,
  ]);

  const handleDisplayTransferredDraftModal = () => {
    if (isTransferred) {
      handleCloseErrorBannerPress();
      setShouldDisplayTransferredDraftDialog(true);
    }
  };

  const renderFooter = () => (
    <NoteFormFooterV2
      formMode={formMode}
      noteStatus={progressNoteData?.status}
      canSubmit
      hasChanged={hasChanged}
      canDeleteDraft={!!progressNoteID && !isTransferred}
      isError={showErrorAlert}
      isLoading={isLoading}
      showSpinner={showSpinner}
      spinnerMessage="Saved successfully"
      showErrorBanner={showGlobalError}
      editHidden={isReadOnly}
      handleCloseErrorBannerPress={handleCloseErrorBannerPress}
      handleDeleteDraftPress={handleDeleteDraftPress}
      handleSaveDraftPress={() => handleUserAction('draft', true)}
      handleSubmitPress={() => handleUserAction('submit')}
      handleCancelPress={handleCancelPress}
      handleSavePress={() => handleUserAction('save')}
      handleEditPress={handleEditPress}
      handleErrorAlertPress={handleErrorAlertPress}
      showErrorAlert={showErrorAlert}
      error={error}
      handleClose={handleDisplayTransferredDraftModal}
      displayCloseButton={isTransferred && selectedTab === 'write-note'}
      displaySaveDraft={!isTransferred}
      displaySubmit={selectedTab === 'write-note'}
    />
  );

  const sessionReportPosition = getSessionReportPosition({
    progressNoteData,
    formMode,
    accountType,
  });

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    noteType: 'progress',
    sessionReportPosition,
    serviceStartDate: formState.serviceStartDate,
    serviceEndDate: formState.serviceEndDate,
    formMode,
    isTherapist: true,
  });

  const shouldDisplaySessionV2 = progressNoteData?.status !== 'submitted' && unsubmittedSessionID;

  const renderSessionSummary = () => {
    if (unsubmittedSession?.generatedSessionSummaryEnabled || formState.generatedSessionSummaryID) {
      return (
        <SessionSummarySectionV3
          isError={smartNoteError}
          nextSection="diagnosis"
          isEap={accountType === 'eap'}
          isSmartNoteDisabled={progressNoteData?.status === 'submitted'}
          handleSmartNoteError={handleSmartNoteError}
        />
      );
    }

    return <SessionSummarySectionV2 nextSection="diagnosis" isEap={accountType === 'eap'} />;
  };

  const noteView = (
    <View style={{ height: '100%' }}>
      {isTransferred && (
        <>
          <SectionContainer containerStyle={{ margin: '20px 0' }}>
            <BannerCard
              variant="info"
              title="Client transferred off your caseload"
              subtitle="You no longer have full access to the chart. Please submit this progress note to ensure compliance and/or payment."
            />
          </SectionContainer>

          <SectionContainer title="Client">
            <TransferredClientSection />
          </SectionContainer>
          <Seperator />
        </>
      )}

      <SectionContainer
        containerStyle={{ paddingTop: 10 }}
        title="Session"
        subtitle={
          shouldDisplaySessionV2
            ? undefined
            : 'Select the date of async messaging or live session(s) for this progress note'
        }
      >
        {shouldDisplaySessionV2 ? (
          <SessionSection nextSection="sessionInformation" />
        ) : (
          <SessionDateAndModalitySection nextSection="sessionInformation" disabled />
        )}
        <SessionInformationSection nextSection="sessionSummary" />
      </SectionContainer>
      {treatmentPlanBasedDocumentation && (
        <>
          <Seperator />
          <TreatmentPlanProgressSection nextSection="finished" />
        </>
      )}
      <Seperator />
      <SectionContainer
        title="Summarize session"
        tooltip={
          unsubmittedSession?.generatedSessionSummaryEnabled && (
            <SmartNoteTooltip modality={unsubmittedSession?.sessionModality} />
          )
        }
        subtitle={
          unsubmittedSession?.generatedSessionSummaryEnabled
            ? 'Start from scratch, add a template, or kickstart your note with a '
            : 'Describe behavior and status, clinical interventions, response to interventions, next steps, etc'
        }
      >
        {renderSessionSummary()}
        <DiagnosisSection nextSection="riskAssessment" />
        <RiskAssessmentSection nextSection="substanceUse" />
        <SubstanceUseSection nextSection="treatmentPlanProgress" />
      </SectionContainer>
      {!treatmentPlanBasedDocumentation && (
        <>
          <Seperator />
          <TreatmentPlanProgressSection nextSection="finished" />
        </>
      )}
      <Seperator />
      <CertifyText>
        {
          'I have reviewed the documentation above to the best\nof my clinical ability and attest that it is accurate.'
        }
      </CertifyText>
    </View>
  );

  const renderMainView = () => (
    <View style={{ width: 375, marginTop: shouldDisplayChat && isMobile && 40 }}>
      {shouldDisplayChat && isMobile && (
        <ProgressNoteTabsNavigator
          handleSelect={(value: ProgressNoteTabs) => setSelectedTab(value)}
          currentTab={selectedTab}
        />
      )}
      {selectedTab === 'write-note' ? (
        noteView
      ) : (
        <SessionMessagesChat isLoading={sessionMessagesLoading} data={sessionMessagesData} />
      )}
    </View>
  );

  const handleBackButtonPress = () => {
    if (shouldDisplayNonPaybleDialog) {
      setShouldDisplayNonPaybleDialog(false);
    } else if (shortSessionSummaryDialog) {
      setShortSessionSummaryDialog(false);
    } else if (formMode !== 'view' && progressNoteData?.status !== 'submitted') {
      handleUserAction('back', true);
    } else {
      history.push(`/room/${roomID}/${postActionRoute}`);
    }
  };

  const renderProgressNoteDialog = () => {
    if (shouldDisplayNonPaybleDialog) {
      return (
        <ProgressNoteDialog
          primaryButtonAction={submitNote}
          primaryButtonText="I understand"
          isLoading={isSubmitLoading}
          title="This session is not eligible for payment"
          text="This session occurred over 90 days ago and is no longer eligible for payment. However, it is
                still important to submit the progress note to keep clinical records up-to-date. Learn more
                about our"
          link={HOW_AM_I_PAID_ARTICLE}
          textLink="Reimbursement Policy."
        />
      );
    }
    if (shortSessionSummaryDialog) {
      return (
        <ProgressNoteDialog
          primaryButtonAction={submitNote}
          primaryButtonText="Submit anyway"
          secondaryButtonAction={() => setShortSessionSummaryDialog(false)}
          secondaryButtonText="Edit progress note"
          isLoading={isSubmitLoading}
          title="Your progress note is short"
          text="We recommend at least 20 words in the session summary for progress notes without a treatment plan. This helps prevent internal clinical quality audits and rejected insurance claims. Learn more about"
          link={QUALITY_DOCUMENTATION_ARTICLE}
          textLink="quality documentation."
        />
      );
    }
    if (shouldDisplayTransferredDraftDialog) {
      return (
        <ProgressNoteDialog
          secondaryButtonAction={() => setShouldDisplayTransferredDraftDialog(false)}
          secondaryButtonText="Go back"
          primaryButtonAction={handleCloseProgressNoteForm}
          primaryButtonText="Close without saving"
          title="Draft will not be saved"
          text="If you close this progress note instead of submitting, progress won't be saved."
        />
      );
    }
    return renderMainView();
  };

  return (
    <View row={!isMobile} style={{ height: '100%' }}>
      <Submenu
        showBackButton={!isTransferred}
        showCloseButton={(isTransferred && !asyncSessionID) || (shouldDisplayChat && isMobile)}
        onClose={handleDisplayTransferredDraftModal}
        caretColor={colors.accessibilityGreenDark}
        bodyStyle={{ paddingRight: 0, paddingLeft: 0, paddingTop: 5, paddingBottom: 15 }}
        titleComponent={
          <View>
            <Large variant="largeBoldWide" style={{ fontSize: 17 }}>
              {title}
            </Large>
            <Small variant="smallBoldGrey">{subtitle}</Small>
          </View>
        }
        childComponents={[renderProgressNoteDialog()]}
        footerComponent={
          !shouldDisplayNonPaybleDialog && !shortSessionSummaryDialog ? renderFooter() : undefined
        }
        onBackAlt={handleBackButtonPress}
      />
      {!isMobile && shouldDisplayChat && (
        <SessionMessagesChat
          isLoading={sessionMessagesLoading}
          data={sessionMessagesData}
          handleClose={handleDisplayTransferredDraftModal}
        />
      )}
    </View>
  );
};

export default ProgressNoteFormContainer;
