import { View, useWindowWidthState, TSLogo, Image } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';

import styled from '../../core/styled';
import logo from './logoV2.svg';
import './Login.css';

const Header = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 40,
    borderBottom: `1px solid ${colors.extraLightGrey}`,
  };
});

const Navbar = () => {
  const { isMobile } = useWindowWidthState();
  const { brandRefreshChanges } = useFlags();

  if (isMobile) {
    return null;
  }
  return (
    <Header style={{ paddingLeft: 8 }}>
      {brandRefreshChanges ? (
        <TSLogo variant={brandRefreshChanges ? '2024' : 'old'} />
      ) : (
        <Image src={logo} alt="logo" width={133} height={36} />
      )}
    </Header>
  );
};
export default Navbar;
