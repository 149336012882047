import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormControl,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Panel,
  Row,
  Tooltip,
} from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  styled,
  formatSelectOptionsFromConfig,
  View,
  AddressComponent,
} from '@talkspace/react-toolkit';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { countries, states } from '@talkspace/configs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import '../Account.css';

import { SetIsSectionLoadedFn } from '../accountTypes';
import { getUserData } from '../../../utils/token';
import { TherapistHomeAddress } from '../../../types/account';
import useQueryTherapistHomeAddress from '../../../hooks/account/useQueryTherapistHomeAddress';
import useMutationUpdateTherapistHomeAddress from '../../../hooks/account/useMutationUpdateTherapistHomeAddress';

const DEFAULT_COUNTRY_CODE = 'US';

const Label = styled('label')({
  fontFamily: "'Roboto', sans-serif",
  fontSize: 12,
  color: '#b8b8b8',
  letterSpacing: -0.15,
  fontWeight: 400,
});

const tooltip = (
  <Tooltip id="HomeAddressTooltip" className="tooltip ts-tooltip ts-profile-text-white">
    Your Home Address should represent your permanent residence
  </Tooltip>
);

const parseAddress = (addressComponents: AddressComponent[]): TherapistHomeAddress => {
  let address: string | undefined;
  let addressStreet: string | undefined;

  const result: TherapistHomeAddress = {
    city: '',
    country: '',
    state: '',
    streetAddress: '',
    zipcode: '',
  };

  addressComponents.forEach(({ long_name: longName, short_name: shortName, types }) => {
    // this is the street number portion of the address i.e. 70
    if (types.includes('street_number')) {
      address = longName;
    }

    // street name portion of the address
    if (types.includes('route')) {
      addressStreet = longName;
    }

    // city portion of the address
    if (types.includes('locality') && longName) {
      result.city = longName;
    }

    // city sometimes appears in 'sublocality', like when the address is in Brooklyn, NY (go BK!)
    if (
      (types.includes('sublocality') || types.includes('postal_town')) &&
      !result.city &&
      longName
    ) {
      result.city = longName;
    }

    // state
    if (types.includes('administrative_area_level_1') && shortName) {
      result.state = shortName;
    }

    // zipcode
    if (types.includes('postal_code') && longName) {
      result.zipcode = longName;
    }

    // country
    if (types.includes('country') && shortName) {
      result.country = shortName;
    }
  });

  // addressLine1 needed so the address can be presented like people are used to seeing
  result.streetAddress = `${address ?? ''} ${addressStreet ?? ''}`.trim();

  result.state = result.country === DEFAULT_COUNTRY_CODE ? result.state : '';

  return result;
};

const stateOptions = [{ value: '', label: '' }, ...formatSelectOptionsFromConfig(states)];

const countryOptions = formatSelectOptionsFromConfig(countries);

const validationSchema = yup.object({
  streetAddress: yup.string().required('Street address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().when('country', {
    is: DEFAULT_COUNTRY_CODE,
    then: yup.string().required('State is required'),
    otherwise: yup.string(),
  }),
  country: yup.string().required('Country is required'),
  zipcode: yup
    .string()
    .required('Zipcode is required')
    .min(5, 'Zipcode must be at least 5 characters long')
    .test(
      'zipcode-length',
      'Zipcode must be 9 characters long unless it includes a dash (-), E.g. xxxx-xxxx',
      (value) => {
        if (!value) return false;
        if (value.includes('-')) {
          return value.length <= 10;
        }
        return value.length <= 9;
      }
    ),
});

type FormMode = 'view' | 'edit';

interface Props {
  setIsSectionLoaded: SetIsSectionLoadedFn;
}

export default function HomeAddress({ setIsSectionLoaded }: Props): JSX.Element {
  const [isSectionReady, setIsSectionReady] = useState(false);

  const [formMode, setFormMode] = useState<FormMode>('view');

  const { mutate: updateTherapistHomeAddress } = useMutationUpdateTherapistHomeAddress();

  const userID = getUserData().id;

  const {
    data: homeAddressData,
    isSuccess: isHomeAddressLoaded,
    isError: homeAddressError,
  } = useQueryTherapistHomeAddress(userID);

  useEffect(() => {
    if (homeAddressError) {
      toast(
        <div
          className="toaster toaster-error"
          style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
        >
          Failed to retrieve home address
        </div>,
        { autoClose: 3000 }
      );
    }
  }, [homeAddressError]);

  useEffect(() => {
    if (!isHomeAddressLoaded || !homeAddressData) {
      return;
    }

    setIsSectionLoaded({ homeAddress: true });
    setIsSectionReady(true);
  }, [isHomeAddressLoaded, homeAddressData, setIsSectionLoaded]);

  const handleCountryChange = (
    e: FormEvent<FormControl>,
    setFieldValue: (
      key: keyof TherapistHomeAddress,
      value: string,
      shouldValidate?: boolean
    ) => void
  ) => {
    const newValue = (e as unknown as ChangeEvent<HTMLInputElement>).target.value;

    setFieldValue('country', newValue);

    if (newValue !== DEFAULT_COUNTRY_CODE) {
      setFieldValue('state', '', false);
    }
  };

  const handleAddressSelect = async (
    address: string,
    setValues: (data: TherapistHomeAddress) => void
  ) => {
    const results = await geocodeByAddress(address);

    const [{ address_components: addressComponents = [] } = {}] = results;

    const parsedAddress = parseAddress(addressComponents);

    setValues(parsedAddress);
  };

  const handleEditClick = () => {
    setFormMode('edit');
  };

  const handleCancelClick = (setValues: (data: TherapistHomeAddress) => void) => {
    if (!homeAddressData) {
      return;
    }
    setValues(homeAddressData);
    setFormMode('view');
  };

  const handleSubmit = (values: TherapistHomeAddress) => {
    const therapistUserID = getUserData().id;
    updateTherapistHomeAddress(
      { therapistUserID, data: values },
      {
        onSuccess: () => {
          setFormMode('view');
          toast(<div className="toaster toaster-success">Changes are saved successfully!</div>, {
            autoClose: 3000,
          });
        },
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        streetAddress: homeAddressData?.streetAddress || '',
        city: homeAddressData?.city || '',
        state: homeAddressData?.state || '',
        country: homeAddressData?.country || DEFAULT_COUNTRY_CODE,
        zipcode: homeAddressData?.zipcode || '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, setValues, isValid }) => (
        <Form>
          <div>
            <Col
              xs={12}
              className={`ts-panel-title ${isSectionReady ? 'show-panel' : 'hidden-panel'}`}
            >
              <Col xs={5} className="ts-font-black">
                Home address
                <OverlayTrigger
                  placement="top"
                  trigger={['click', 'focus', 'hover']}
                  overlay={tooltip}
                >
                  <i className="fa fa-fw fa-question-circle fa-lg" />
                </OverlayTrigger>
              </Col>

              <Col xs={6} />

              {formMode === 'view' ? (
                <Button className="ts-edit-button pull-right" onClick={handleEditClick}>
                  Edit
                </Button>
              ) : (
                <>
                  <Button
                    className="btn-ts-default btn-ts-green ts-profile-text-white pull-right save-btn"
                    disabled={!isValid}
                    type="submit"
                  >
                    Save Changes
                  </Button>

                  <Button
                    className="btn-ts-default ts-profile-btn-text-green cancel-btn pull-right"
                    onClick={() => handleCancelClick(setValues)}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Col>

            <Col xs={12}>
              <Panel
                className={`ts-my-account-panel ${isSectionReady ? 'show-panel' : 'hidden-panel'}`}
              >
                <div className="ts-profile-container">
                  <Row style={{ marginBottom: '10px' }}>
                    <Col md={10} xs={12}>
                      <Row style={{ marginBottom: '10px' }}>
                        <Col md={4}>
                          <Label>Street</Label>

                          <PlacesAutocomplete
                            value={values.streetAddress}
                            onChange={(address: string) => setFieldValue('streetAddress', address)}
                            onSelect={(address: string) => handleAddressSelect(address, setValues)}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <View style={{ position: 'relative' }}>
                                <Field
                                  name="address"
                                  render={({ field }) => (
                                    <FormControl
                                      {...field}
                                      {...getInputProps({
                                        placeholder: 'Search address...',
                                        className: 'form-control',
                                      })}
                                      disabled={formMode === 'view'}
                                    />
                                  )}
                                />

                                <ListGroup
                                  style={{
                                    position: 'absolute',
                                    zIndex: 1000,
                                    top: 40,
                                    width: 360,
                                  }}
                                >
                                  {loading && <ListGroupItem>Loading...</ListGroupItem>}
                                  {suggestions.map((suggestion) => (
                                    <ListGroupItem
                                      {...getSuggestionItemProps(suggestion)}
                                      key={suggestion.placeId}
                                    >
                                      {suggestion.description}
                                    </ListGroupItem>
                                  ))}
                                </ListGroup>
                              </View>
                            )}
                          </PlacesAutocomplete>

                          <ErrorMessage
                            name="streetAddress"
                            component="div"
                            className="text-danger"
                          />
                        </Col>

                        <Col md={4} xs={6}>
                          <Label>City</Label>

                          <Field
                            name="city"
                            render={({ field }) => (
                              <FormControl
                                {...field}
                                placeholder="Name"
                                disabled={formMode === 'view'}
                              />
                            )}
                          />

                          <ErrorMessage name="city" component="div" className="text-danger" />
                        </Col>

                        <Col md={4} xs={6}>
                          <Label>State</Label>

                          <Field
                            name="state"
                            render={({ field }) => (
                              <FormControl
                                {...field}
                                componentClass="select"
                                disabled={
                                  formMode === 'view' || values.country !== DEFAULT_COUNTRY_CODE
                                }
                                onChange={(e) =>
                                  setFieldValue(
                                    'state',
                                    (e as unknown as ChangeEvent<HTMLInputElement>).target.value
                                  )
                                }
                                placeholder="State"
                              >
                                {stateOptions.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </FormControl>
                            )}
                          />

                          <ErrorMessage name="state" component="div" className="text-danger" />
                        </Col>
                      </Row>

                      <Row>
                        <Col md={4} xs={6}>
                          <Label>Country</Label>

                          <Field
                            name="country"
                            render={({ field }) => (
                              <FormControl
                                {...field}
                                componentClass="select"
                                disabled={formMode === 'view'}
                                onChange={(e) => handleCountryChange(e, setFieldValue)}
                                placeholder="Country"
                              >
                                {countryOptions.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </FormControl>
                            )}
                          />

                          <ErrorMessage name="country" component="div" className="text-danger" />
                        </Col>

                        <Col md={4} xs={6}>
                          <Label>Zip code</Label>

                          <Field
                            name="zipcode"
                            render={({ field }) => (
                              <FormControl
                                {...field}
                                placeholder="Zip code"
                                disabled={formMode === 'view'}
                              />
                            )}
                          />

                          <ErrorMessage name="zipcode" component="div" className="text-danger" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Panel>
            </Col>

            <ToastContainer
              closeButton={false}
              position={toast.POSITION.BOTTOM_RIGHT}
              hideProgressBar
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
