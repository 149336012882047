import { useMutation } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

export interface CreateInsightFeedbackParams {
  roomID: number;
  insightID: number;
  payload: any;
}

const createInsightFeedback = async ({
  roomID,
  insightID,
  payload,
}: CreateInsightFeedbackParams): Promise<void> => {
  await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/smart-insights/${insightID}/feedback`,
    payload
  );
};

export default function useMutationCreateInsightFeedback() {
  return useMutation<void, Error, CreateInsightFeedbackParams>(createInsightFeedback);
}
