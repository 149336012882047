import { styled, TextDS, useWindowWidthState, View } from '@talkspace/react-toolkit';
import { CircleCheck } from '@talkspace/react-toolkit/src/designSystems/icons';

type CalloutType = 'default' | 'success';

const MainView = styled(View)<{ type: CalloutType }>(
  ({
    type,
    theme: {
      colorRoles,
      window: { isMobile },
      spacing,
    },
  }) => {
    return {
      flexDirection: isMobile ? 'row' : 'column',
      gap: spacing('space050'),
      width: isMobile ? 343 : 224,
      height: isMobile ? 52 : 120,
      padding: spacing(isMobile ? 'space200' : 'space400'),
      justifyContent: isMobile ? 'space-between' : 'center',
      alignItems: 'flex-start',
      borderRadius: spacing('space100'),
      background:
        type === 'success'
          ? colorRoles.surfaces.successSubtleDefault
          : colorRoles.surfaces.neutralDefault,
    };
  }
);

const MetricView = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing('space100'),
  };
});

interface Props {
  title: string;
  metric: string;
  type: CalloutType;
  showIcon?: boolean;
}

const CalloutNumber = ({ title, metric, type, showIcon = true }: Props) => {
  const { isMobile } = useWindowWidthState();

  const textColorType = type === 'success' ? 'textSuccessDefault' : 'textDefault';

  return (
    <MainView type={type}>
      <TextDS colorRole={textColorType}>{title}</TextDS>

      <MetricView>
        <TextDS variant={isMobile ? 'headingMd' : 'heading2xl'} colorRole={textColorType}>
          {metric}
        </TextDS>
        {type === 'success' && showIcon && <CircleCheck colorType="success" />}
      </MetricView>
    </MainView>
  );
};

export default CalloutNumber;
