import { Large, Spinner, Standard, styled, TouchableView, View } from '@talkspace/react-toolkit';
import { useEffect } from 'react';
import { useHistory, useParams } from '../../../../../core/routerLib';
import Submenu from '../../../../Reusable/Submenu';
import PinkAlert from '../../../../Icons/PinkAlert';
import { RiskAndSafetyDialogLocationState } from './types';
import useLocationStateOnMount from '../../../../../hooks/useLocationStateOnMount';

const MainContainer = styled(View)(({ theme: { colors } }) => {
  return {
    alignSelf: 'center',
    width: 334,
    borderColor: colors.pink,
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 5,
    marginTop: 8,
    padding: 20,
  };
});

const MainButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.permaBlueStoneNew,
    fontWeight: 'bold',
    height: 32,
    padding: '6px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    borderRadius: 8,
  };
});

const AlternativeButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.permaBlueStoneNew,
    fontWeight: 'bold',
    height: 32,
    padding: '6px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    borderRadius: 8,
  };
});

const TitleContainer = styled(View)({
  flexDirection: 'row',
  marginBottom: 10,
});

interface ButtonOptions {
  text: string;
  onPress: () => void;
  dataQa?: string;
}

interface NotesDialogV2Props {
  title: string;
  text: string;
  subtextContainer: JSX.Element;
  mainButtonOptions: ButtonOptions;
  alternativeButtonOptions: ButtonOptions;
  showSpinner?: boolean;
}
const RiskAndSafetyDialog = ({
  title,
  text,
  subtextContainer,
  mainButtonOptions,
  alternativeButtonOptions,
  showSpinner = false,
}: NotesDialogV2Props) => {
  const locationState = useLocationStateOnMount<RiskAndSafetyDialogLocationState>();
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();

  useEffect(() => {
    if (locationState === null || locationState?.answers === null) {
      history.push(`/room/${roomID}/clinical-tab`, { section: 'risk-and-safety' });
    }
  }, [history, locationState, locationState?.answers, roomID]);
  return (
    <Submenu
      childComponents={[
        <MainContainer>
          <View style={{ marginBottom: 22 }}>
            <TitleContainer>
              <PinkAlert style={{ marginRight: 10 }} />
              <Standard variant="standardBoldTSBlack">{text} </Standard>
            </TitleContainer>
            {subtextContainer}
          </View>
          <View style={{ flexDirection: 'row-reverse' }}>
            <MainButton dataQa={mainButtonOptions.dataQa} onPress={mainButtonOptions.onPress}>
              {mainButtonOptions.text}
            </MainButton>
            <AlternativeButton
              dataQa={alternativeButtonOptions.dataQa}
              onPress={alternativeButtonOptions.onPress}
            >
              {alternativeButtonOptions.text}
            </AlternativeButton>
          </View>
          {showSpinner && <Spinner />}
        </MainContainer>,
      ]}
      title={title}
      titleComponent={
        <View>
          <Large variant="largeBoldWide">{title}</Large>
        </View>
      }
    />
  );
};

export default RiskAndSafetyDialog;
