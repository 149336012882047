import { useHistory, useParams } from 'react-router';
import { View } from '@talkspace/react-toolkit';
import useMutationCreateQuestionnaire from '../../../Questionnaire/useMutationCreateQuestionnaire';
import { getUserData } from '../../../../../utils/token';
import RiskAndSafetyDialog from './RiskAndSafetyDialog';
import { RiskAndSafetyDialogLocationState } from './types';
import useLocationStateOnMount from '../../../../../hooks/useLocationStateOnMount';

const RiskAndSafetySaveDialog = () => {
  const { mutate: createQuestionnaire } = useMutationCreateQuestionnaire();
  const { roomID } = useParams<{ roomID: string }>();
  const therapistID = getUserData().id;
  const history = useHistory();
  const locationState = useLocationStateOnMount<RiskAndSafetyDialogLocationState>();

  const getFormUrl = () => {
    const substringsArr = locationState?.questionnaireType.split('_');

    return `/room/${roomID}/${substringsArr?.join('-')}/create`;
  };

  const handleConfirm = () => {
    createQuestionnaire(
      {
        questionnaireType: locationState?.questionnaireType || 'cssrs_screener',
        roomID: Number(roomID),
        userID: therapistID,
        answers: locationState?.answers || [],
      },
      {
        onSuccess: () => {
          history.push(`/room/${roomID}/clinical-tab`, { section: 'risk-and-safety' });
        },
      }
    );
  };
  return (
    <RiskAndSafetyDialog
      title={
        locationState?.questionnaireType === 'cssrs_screener'
          ? 'C-SSRS'
          : 'Stanley-Brown Safety Plan'
      }
      text="Are you sure you want to save?"
      subtextContainer={<View>No changes can be made once this is saved.</View>}
      mainButtonOptions={{
        text: 'Save',
        onPress: () => {
          handleConfirm();
        },
        dataQa: 'saveRiskAndSafetySaveButton',
      }}
      alternativeButtonOptions={{
        text: 'Cancel',
        onPress: () => {
          history.push(`${getFormUrl()}`, locationState);
        },
        dataQa: 'saveRiskAndSafetyCancelButton',
      }}
    />
  );
};

export default RiskAndSafetySaveDialog;
