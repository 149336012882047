import {
  Standard,
  View,
  BaseButton,
  useEmotionTheme,
  EnvelopeIcon,
  TooltipV2,
  ChatModalityV2,
  VideoModalityV2,
  AudioModalityV2,
  useWindowWidthState,
  Tag,
  TagColorName,
  EmotionThemeProvider,
} from '@talkspace/react-toolkit';
import { useCallback, useState } from 'react';
import moment from 'moment';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { LiveSessionModality } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useParams, useHistory, useRouteMatch } from '@/core/routerLib/routerLib';
import styled from '@/core/styled';
import {
  getSubmissionStatus,
  getModalitySubtitle,
  getShouldDisplayStatusAndTooltip,
} from '../utils';
import { SessionSubmissionStatus } from '../../../../../hooks/notes/useQueryUnsubmittedSessions';
import CTATooltip from './Tooltip';

interface UnsubmittedSessionProps {
  submissionStatus: SessionSubmissionStatus | null;
  serviceStartDate: Date | null;
  sessionModality: 'live' | 'messaging';
  wordCount?: number | null;
  wordDuration?: number | null;
  liveSessionType?: LiveSessionModality;
  id: string;
  draftProgressNoteID: number | null;
  liveCallDuration?: number | null;
  minSessionWords?: number | null;
  maxSessionWords?: number | null;
  serviceEndDate: Date | null;
  asyncSessionID?: number;
  liveCallID?: number;
}
interface CTACardProps {
  unsubmittedSessionItem: UnsubmittedSessionProps;
}

const SubmitButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    fontWeight: 700,
    color: colors.white,
    background: colors.accessibilityGreenDark,
    maxHeight: 30,
    height: 30,
    borderRadius: 5,
    padding: '5px 9px',
    minWidth: 100,
  };
});

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    padding: 15,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  };
});

const StartDateTitle = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    fontWeight: 500,
    color: colors.black,
  };
});

const Subtitle = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 400,
    color: colors.purple600,
  };
});

const WordCountText = styled(Standard)<{ color: string }>(({ color, theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 700,
    color,
    margin: '2px 6px 0 6px',
  };
});

const containerTooltipStyle = {
  padding: '15px 20px',
  width: 350,
  marginTop: 58,
  marginRight: -3,
};

export const getIcon = (
  sessionModality: 'live' | 'messaging',
  liveSessionType?: LiveSessionModality
): React.ReactNode => {
  if (sessionModality === 'live') {
    if (liveSessionType === 'chat') {
      return <ChatModalityV2 />;
    }
    if (liveSessionType === 'audio') {
      return <AudioModalityV2 />;
    }
    return <VideoModalityV2 style={{ marginTop: 5 }} />;
  }
  return <EnvelopeIcon style={{ marginTop: 4 }} />;
};

const CTACard = ({ unsubmittedSessionItem }: CTACardProps) => {
  const {
    submissionStatus,
    sessionModality,
    liveSessionType,
    draftProgressNoteID,
    id: unsubmittedSessionID,
    serviceStartDate,
    wordCount,
    wordDuration,
    minSessionWords,
    maxSessionWords,
    serviceEndDate,
    liveCallID,
    asyncSessionID,
  } = unsubmittedSessionItem;
  const { colors } = useEmotionTheme();
  const { roomID } = useParams<{ roomID: string }>();
  const match = useRouteMatch<{ roomID: string }>();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLine } = useQueryBusinessLine(roomID, clientUserID);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const submittionStatusObject = getSubmissionStatus(submissionStatus, colors);
  const modalitySubtitle = getModalitySubtitle(sessionModality, liveSessionType);
  const history = useHistory();
  const { isMobile } = useWindowWidthState();

  const handleSubmit = (): void => {
    const base = match.url.replace(/\/notes-tab|\/all-unsubmitted-sessions/g, '');
    let postActionRoute = 'notes-tab';
    if (history.location.pathname.includes('all-unsubmitted-sessions')) {
      postActionRoute = 'all-unsubmitted-sessions';
    }

    if (draftProgressNoteID) {
      history.push(
        `${base}/progress-notes/${draftProgressNoteID}/edit?id=${unsubmittedSessionID}`,
        { postActionRoute, source: 'notes_tab' }
      );
    } else {
      history.push(`${base}/progress-notes/new?id=${unsubmittedSessionID}`, {
        postActionRoute,
        source: 'notes_tab',
      });
    }
  };

  const getButtonTitle = (): string => {
    if (draftProgressNoteID) {
      return 'Continue draft';
    }
    return submissionStatus === 'readyToStart' ? 'Start note' : 'Submit';
  };

  const { shouldDisplayStatus, shouldDisplayTooltip } = getShouldDisplayStatusAndTooltip({
    sessionModality,
    businessLine,
    submissionStatus,
  });

  const icon = getIcon(sessionModality, liveSessionType);
  const buttonTitle = getButtonTitle();

  const getTitle = () => {
    if (
      sessionModality === 'messaging' &&
      businessLine?.isEAP &&
      serviceEndDate &&
      submissionStatus !== 'reopenedAndReadyForReview'
    ) {
      return `${moment(serviceStartDate).format('MMM D')} - ${moment(serviceEndDate).format(
        'MMM D'
      )}`;
    }
    if (moment(serviceStartDate).isSame(moment(), 'year')) {
      return sessionModality === 'messaging'
        ? `Started ${moment(serviceStartDate).format('MMMM D')}`
        : moment(serviceStartDate).format('dddd, MMMM D');
    }
    return sessionModality === 'messaging'
      ? `Started ${moment(serviceStartDate).format('MMM D, YYYY')}`
      : moment(serviceStartDate).format('dddd, MMM D, YYYY');
  };

  const shouldDisplayWordCount =
    businessLine?.isBH && wordCount !== null && wordCount !== undefined;

  const {
    calculatingAndCommunicatingAsyncEngagement: { featureOn: shouldShowMinutesOnly },
  } = useFlags();

  const { color, background, text, wordCountColor } = submittionStatusObject;

  const getWordCountText = useCallback(
    () =>
      shouldShowMinutesOnly
        ? wordDuration && `${wordDuration.toString()} min`
        : wordCount && `${wordCount.toString()} words`,
    [shouldShowMinutesOnly, wordCount, wordDuration]
  );

  return (
    <EmotionThemeProvider version="2.0.0">
      <Container>
        <View row justify="space-between">
          <View row>
            {icon}
            <View style={{ marginLeft: 10 }}>
              <StartDateTitle>{getTitle()}</StartDateTitle>
              <Subtitle>{modalitySubtitle}</Subtitle>
            </View>
          </View>
          <SubmitButton
            onPress={handleSubmit}
            dataQa={`progressNoteSubmitButton-id-${unsubmittedSessionID}`}
          >
            {buttonTitle}
          </SubmitButton>
        </View>

        <View align="center" row style={{ marginLeft: 28, marginTop: 5, height: 35 }}>
          {shouldDisplayStatus && color && background && text && (
            <View onMouseEnter={() => !isMobile && setIsTooltipOpen(true)}>
              <Tag text={text} color={background as TagColorName} />
            </View>
          )}

          {shouldDisplayWordCount && (
            <WordCountText color={wordCountColor}>{getWordCountText()}</WordCountText>
          )}

          {shouldDisplayTooltip && (
            <TooltipV2
              onTouchStart={() => setIsTooltipOpen(true)}
              circleColor={colors.permaWildBlueYonder}
              questionMarkColor={colors.white}
              isTooltipOpen={isTooltipOpen}
              styles={{ height: 15, width: 15, position: 'none' }}
              toolTipText={
                <CTATooltip
                  roomID={roomID.toString()}
                  submissionStatus={submissionStatus}
                  businessLine={businessLine}
                  clientUserID={clientUserID}
                  minSessionWords={minSessionWords}
                  maxSessionWords={maxSessionWords}
                  asyncSessionID={asyncSessionID}
                  liveCallID={liveCallID}
                />
              }
              containerStyle={containerTooltipStyle}
              handleCloseButton={() => setIsTooltipOpen(false)}
            />
          )}
        </View>
      </Container>
    </EmotionThemeProvider>
  );
};

export default CTACard;
