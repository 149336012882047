import { useCallback } from 'react';
import {
  Standard,
  Large,
  BaseButton,
  View,
  useWindowWidthState,
  CaretLeft,
  CaretRight,
  useEmotionTheme,
  Checkbox,
  SelectRounded,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { getSelectStyleV2 } from '../modals/style.helpers';
import { getCheckboxLabelStyle, getCheckboxStyle, mobileViewOptions } from '../utils';
import { CalendarView } from '../types';

interface ToolBarProps {
  dateStart: Date;
  dateEnd: Date;
  isChecked: boolean;
  currentView: CalendarView;
  handlePreviousPress: () => void;
  handleNextPress: () => void;
  handleTodayPress: () => void;
  handleChecked: (value: boolean) => void;
  handleCalendarView: (value: CalendarView) => void;
}
const ToolBarWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: isMobile ? 6 : 30,
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 10,
    };
  }
);

const TodayButton = styled(BaseButton)<{ isMobile?: boolean; isMedium?: boolean }>(
  ({ theme: { colors }, isMedium, isMobile }) => {
    return {
      width: 65,
      height: 44,
      borderRadius: 7,
      border: `solid ${colors.accessibilityGreenDark} 1px`,
      color: colors.accessibilityGreenDark,
      fontWeight: 700,
      fontSize: 15,
      marginLeft: 10,
      '&:hover': {
        background: '#F6F8FB',
      },
      '&:active': {
        background: colors.permaSolitude,
      },
      ...(isMobile && {
        width: 40,
        border: 'none',
      }),
      ...(isMedium && {
        width: 77,
      }),
    };
  }
);

const TodayText = styled(Standard)(({ theme: { colors } }) => {
  return {
    color: colors.permaDarkForest,
    fontWeight: 700,
    fontSize: 15,
  };
});

const CaretButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    width: 30,
    height: 30,
    paddingTop: 3,
    borderRadius: '50%',
    marginRight: 10,
    '&:hover': {
      background: '#F6F8FB',
    },
    '&:active': {
      background: colors.permaSolitude,
    },
  };
});

const MonthYearText = styled(Large)<{ isMobile: boolean }>(({ isMobile }) => {
  const desktopStyle = !isMobile
    ? {
        fontSize: 21,
        maxWidth: 'initial',
        whiteSpace: 'initial',
        overflowX: 'initial',
        overflowY: 'initial',
        textOverFlow: 'initial',
      }
    : { fontSize: 19 };
  return {
    fontWeight: 700,
    ...desktopStyle,
  };
});

const DateNavigatorContainer = styled(View)<{ isMobile }>(({ isMobile }) => {
  return {
    marginLeft: 30,
    marginRight: 15,
  };
});

const CheckMark = styled(View)(({ theme: { colors } }) => {
  return {
    width: 11,
    height: 11,
    borderRadius: 1,
    background: colors.accessibilityGreenDark,
  };
});

const ToolBar = ({
  dateStart,
  dateEnd,
  isChecked,
  currentView,
  handlePreviousPress,
  handleNextPress,
  handleTodayPress,
  handleChecked,
  handleCalendarView,
}: ToolBarProps) => {
  const { isMobile, isMedium } = useWindowWidthState();
  const { colors } = useEmotionTheme();

  const buildDateRange = useCallback(() => {
    const startMonth = dateStart?.toLocaleString('default', { month: 'long' });
    const endMonth = dateEnd?.toLocaleString('default', { month: 'long' });
    const yearStart = dateStart?.getFullYear().toString();
    const yearEndString = dateEnd?.getFullYear().toString();
    const yearStartString = yearStart < yearEndString ? yearStart : '';
    const dateRangeText =
      startMonth === endMonth
        ? `${startMonth} ${yearEndString}`
        : `${startMonth.slice(0, 3)} ${yearStartString} - ${endMonth.slice(0, 3)} ${yearEndString}`;
    return dateRangeText;
  }, [dateEnd, dateStart]);

  return (
    <ToolBarWrapper>
      <View row={!isMobile} justify="space-between" align={isMobile ? 'start' : 'center'}>
        <View row align="center" style={{ marginBottom: 10 }}>
          <TodayButton onPress={handleTodayPress} isMedium={isMedium} isMobile={isMobile}>
            <TodayText>Today</TodayText>
          </TodayButton>
          <DateNavigatorContainer row isMobile={isMobile}>
            <CaretButton onPress={handlePreviousPress}>
              <CaretLeft height={14} width={8} color={colors.permaEden} />
            </CaretButton>
            <CaretButton onPress={handleNextPress}>
              <CaretRight height={14} width={8} color={colors.permaEden} />
            </CaretButton>
          </DateNavigatorContainer>
          <MonthYearText isMobile={isMobile}>{buildDateRange()}</MonthYearText>
        </View>
        <View
          row
          justify={isMobile ? 'space-between' : 'end'}
          style={{ width: isMobile && '100%' }}
        >
          {isMobile && (
            <SelectRounded
              value={mobileViewOptions.find((it) => it.value === currentView)}
              wrapperStyle={{ width: 109, marginTop: 0 }}
              styles={getSelectStyleV2({ colors, shouldFloatLeft: true })}
              options={mobileViewOptions}
              onChange={(option: any) => handleCalendarView(option?.value)}
            />
          )}
          <Checkbox
            checkComponent={<CheckMark />}
            isChecked={isChecked}
            setIsChecked={handleChecked}
            label="Hide sessions"
            isLabelOnRight
            checkboxStyle={getCheckboxStyle(colors)}
            labelStyle={getCheckboxLabelStyle(colors)}
          />
        </View>
      </View>
    </ToolBarWrapper>
  );
};

export default ToolBar;
