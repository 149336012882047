import {
  AnimatedSwipeDownModal,
  CloseButton,
  Modal,
  View,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import useScrollTracking from '@talkspace/react-toolkit/src/hooks/useScrollTracking';
import ModalGraphWidget from './ModalGraphWidget';
import styled from '../../core/styled';
import { Offset } from './Milestone';

const getContentViewStyle = (isTabletAndBelow: boolean) => {
  return {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'fit-content',
    maxWidth: 844,
    maxHeight: '90vh',
    width: '80%',
    zIndex: 20,
    borderRadius: 10,
    padding: 0,
    background: 'white',
    overflow: 'hidden',
    ...(isTabletAndBelow && {
      width: '100%',
      maxWidth: '95%',
    }),
  };
};

const GraphWidgetTitle = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: '100%',
      font: 'Roboto',
      fontWeight: 500,
      color: colors.permaOuterSpaceNew,
      fontSize: 21,
      marginBottom: 16,
      marginLeft: isMobile ? 16 : 0,
    };
  }
);

const GraphWidgetSubtitle = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginBottom: 15,
      fontWeight: 400,
      fontSize: 16,
      marginLeft: isMobile ? 16 : 0,
      paddingRight: isMobile ? 60 : 0,
    };
  }
);

const ContentWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      paddingBottom: 48,
      paddingTop: 15,
      paddingLeft: isMobile ? 0 : 48,
      paddingRight: isMobile ? 0 : 48,
    };
  }
);

interface BonusModalProps {
  onBackdropPress: () => void;
  hide: boolean;
  year: number;
  month: number;
}

const ModalContent = ({ onBackdropPress, month, year }: Omit<BonusModalProps, 'hide'>) => {
  const bonusModalRef = useRef<HTMLDivElement>(null);
  const scrollableElementRef = useRef<HTMLDivElement>(null);

  const [isBonusModalScrolling, setIsBonusModalScrolling] = useState(false);

  const [modalOffset, setModalOffset] = useState<Offset>({ top: 0, left: 0 });
  const { width, height, isMobile } = useWindowWidthState();

  useEffect(() => {
    const { top, left } = bonusModalRef?.current?.getBoundingClientRect() || {
      top: 0,
      left: 0,
    };
    setModalOffset({ top, left });
  }, [height, width]);

  const handleScrollStart = () => {
    setIsBonusModalScrolling(true);
  };

  const handleScrollStop = () => {
    setIsBonusModalScrolling(false);
  };

  const trackedElement = scrollableElementRef.current;

  useScrollTracking({ trackedElement, handleScrollStart, handleScrollStop });

  return (
    <View ref={bonusModalRef}>
      {!isMobile && (
        <View row justify="end" style={{ marginTop: 12, marginRight: 12 }}>
          <CloseButton onPress={onBackdropPress} width={31} height={31} />
        </View>
      )}
      <View
        ref={scrollableElementRef}
        style={{
          height: isMobile ? 'calc(100vh - 120px)' : 'calc(90vh - 31px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <ContentWrapper>
          <GraphWidgetTitle>
            {`Your ${moment().month(month).format('MMMM')}`} Bonus
          </GraphWidgetTitle>
          <GraphWidgetSubtitle>
            You can earn monthly bonuses by accumulating clinical hours
          </GraphWidgetSubtitle>
          <ModalGraphWidget
            isBonusModalScrolling={isBonusModalScrolling}
            modalOffset={modalOffset}
            year={year}
            month={month}
          />
        </ContentWrapper>
      </View>
    </View>
  );
};

const BonusModal = ({ onBackdropPress, hide, year, month }: BonusModalProps) => {
  const {
    isMobile = false,
    isSmall = false,
    isMedium = false,
    isLarge = false,
  } = useWindowWidthState();
  const isTabletAndBelow = isSmall || isMedium || isLarge;

  return (
    <>
      {isMobile ? (
        <AnimatedSwipeDownModal
          isDraggable={false}
          containerStyles={{ padding: 0, paddingTop: 50, overflow: 'hidden' }}
          isOpen={!hide}
          onClose={onBackdropPress}
        >
          <ModalContent onBackdropPress={onBackdropPress} year={year} month={month} />
        </AnimatedSwipeDownModal>
      ) : (
        <Modal
          isVisible={!hide}
          onBackdropPress={onBackdropPress}
          underlayStyle={{ background: `rgba(104, 118, 141, 0.50)` }}
        >
          <Modal.Panel
            onBackdropPress={onBackdropPress}
            contentViewStyle={getContentViewStyle(isTabletAndBelow)}
            isMobileFullscreen={false}
            showCloseButton={false}
          >
            <ModalContent onBackdropPress={onBackdropPress} year={year} month={month} />
          </Modal.Panel>
        </Modal>
      )}
    </>
  );
};

export default BonusModal;
