import {
  Button,
  EmotionThemeProvider,
  Select,
  useEmotionTheme,
  View,
} from '@talkspace/react-toolkit';
import { useCallback, useEffect, useState } from 'react';
import Footer from 'components/Reusable/Footer/Footer';
import moment from 'moment';
import { useHistory, useLocation, useParams } from '../../../../../core/routerLib';
import Submenu from '../../../../Reusable/Submenu';
import useQueryQuestionnaireQuestions, {
  Question,
} from '../../../Questionnaire/useQueryQuestionnaireQuestions';
import { RiskAndSafetyDialogLocationState } from './types';
import { QuestionnaireAnswerData, QuestionnaireQuestionOption } from '../../../Questionnaire/types';
import useQueryQuestionnaires, {
  Questionnaire,
} from '../../../Questionnaire/useQueryQuestionnaires';

interface CSSRSScreenerFormParams {
  mode: 'create' | 'view';
}

type SelectedOption = { label: string; value: string };

const CSSRSScreenerForm = (params: CSSRSScreenerFormParams) => {
  const { mode } = params;
  const { roomID, cssrsScreenerID } = useParams<{ roomID: string; cssrsScreenerID: string }>();

  const history = useHistory();

  const { data: cssrsScreenerActiveQuestions } = useQueryQuestionnaireQuestions({
    roomID: Number(roomID),
    questionnaireType: 'cssrs_screener',
  });

  const [cssrsScreener, setCssrsScreener] = useState<Questionnaire>();
  const location = useLocation<RiskAndSafetyDialogLocationState | undefined>();

  const [answers, setAnswers] = useState<Array<QuestionnaireAnswerData>>([]);

  const { data: cssrsScreeners } = useQueryQuestionnaires({
    roomID: Number(roomID),
    questionnaireTypes: ['cssrs_screener'],
  });

  const [cssrsScreenerQuestions, setCssrsScreenerQuestions] = useState<Array<Question>>([]);

  useEffect(() => {
    if (cssrsScreenerActiveQuestions) {
      setCssrsScreenerQuestions(
        mode === 'create'
          ? cssrsScreenerActiveQuestions
          : cssrsScreener?.questionnaireAnswers.map((it) => {
              return {
                description: it.questionnaireQuestion.description,
                order: it.questionnaireQuestion.order,
                id: it.questionnaireQuestion.id,
                text: it.questionnaireQuestion.text,
                questionnaireQuestionOptions:
                  it.questionnaireQuestionOptions as Array<QuestionnaireQuestionOption>,
                type: it.questionnaireQuestion.type as 'boolean' | 'multi',
                deletedAt: it.questionnaireQuestion.deletedAt || null,
              };
            }) || []
      );
    }
  }, [cssrsScreener, cssrsScreener?.questionnaireAnswers, cssrsScreenerActiveQuestions, mode]);

  useEffect(() => {
    setCssrsScreener(cssrsScreeners?.find((it) => it.id === Number(cssrsScreenerID)));
  }, [cssrsScreenerID, cssrsScreeners]);

  useEffect(() => {
    if (location?.state?.answers) {
      setAnswers(location.state.answers);
    } else if (mode === 'view') {
      const formattedAnswers =
        cssrsScreener?.questionnaireAnswers.map((answer) => {
          return {
            questionID: answer.questionnaireQuestionID,
            booleanAnswer: answer.booleanAnswer,
          };
        }) || [];
      setAnswers(formattedAnswers);
    }
  }, [cssrsScreener?.questionnaireAnswers, cssrsScreeners, location?.state?.answers, mode]);

  const handleBackButtonPressed = useCallback(() => {
    mode === 'create'
      ? history.push(`/room/${roomID}/risk-and-safety/leave`, {
          questionnaireType: 'cssrs_screener',
          title: 'C-SSRS',
          answers,
        })
      : history.push(`/room/${roomID}/clinical-tab`, { section: 'risk-and-safety' });
  }, [answers, history, mode, roomID]);

  const parseQuestionAnswersByQuestionID = useCallback(
    (id) => {
      const answer = answers.filter(({ questionID }) => id === questionID)?.[0];
      if (answer?.booleanAnswer !== undefined && answer?.booleanAnswer !== null) {
        return {
          label: answer.booleanAnswer ? 'Yes' : 'No',
          value: answer.booleanAnswer ? 'true' : 'false',
        };
      }
      return [];
    },
    [answers]
  );

  const updateQuestionAnswerByQuestionID = useCallback(
    ({ selectedOption, id }: { selectedOption: SelectedOption; id: number }) => {
      const answersClone = [...answers];
      const answer = answersClone?.find(({ questionID }) => id === questionID);

      if (!answer) {
        setAnswers([
          ...answers,
          {
            questionID: id,
            booleanAnswer: selectedOption?.value === 'true',
          },
        ]);
      } else if (selectedOption?.value === undefined) {
        setAnswers(answersClone.filter(({ questionID }) => id !== questionID));
      } else {
        answer.booleanAnswer = selectedOption?.value === 'true';
        setAnswers(answersClone);
      }
    },
    [answers]
  );

  const { colors } = useEmotionTheme();

  const renderFooter = useCallback(
    () => (
      <Footer
        childComponents={[
          <View style={{ justifyContent: 'end', padding: 15 }}>
            <EmotionThemeProvider version="2.0.0">
              <Button
                variant="tertiary"
                text="Save"
                onPress={() => {
                  history.push(`/room/${roomID}/risk-and-safety/save`, {
                    questionnaireType: 'cssrs_screener',
                    title: 'C-SSRS',
                    answers: (() => {
                      if (!cssrsScreenerQuestions) {
                        return [];
                      }
                      const newAnswers = answers.filter((it) => {
                        const answersExistInQuestions = cssrsScreenerQuestions
                          .map((question) => question.id)
                          .includes(it.questionID);
                        return answersExistInQuestions;
                      });
                      return newAnswers;
                    })(),
                  });
                }}
              />
            </EmotionThemeProvider>
          </View>,
        ]}
        isError={false}
        isUpdating={false}
        showSpinner={false}
        spinnerMessage=""
      />
    ),
    [answers, cssrsScreenerQuestions, history, roomID]
  );

  const getSubmenuChildComponent = useCallback(
    () => (
      <View style={{ paddingTop: 16, color: colors.permaSlateGrayApprox, fontSize: 14 }}>
        {mode === 'view' && (
          <View style={{ alignSelf: 'center', marginBottom: 16 }}>
            {`${moment(cssrsScreener?.createdAt).format('MMM DD, YYYY')}`}
          </View>
        )}
        <View>Over the past month...</View>
        {cssrsScreenerQuestions
          ?.sort((a, b) => a.order - b.order)
          .map((question, i) => (
            <View style={{ marginBottom: 10 }}>
              <Select
                isDisabled={mode === 'view'}
                isSearchable={false}
                placeholderIsTwoLines
                placeholder={`${question.order}. ${question.text}`}
                value={parseQuestionAnswersByQuestionID(question.id)}
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
                onChange={(selectedOption: SelectedOption) => {
                  updateQuestionAnswerByQuestionID({
                    selectedOption,
                    id: question.id,
                  });
                }}
              />
            </View>
          ))}
      </View>
    ),
    [
      colors.permaSlateGrayApprox,
      cssrsScreener?.createdAt,
      cssrsScreenerQuestions,
      mode,
      parseQuestionAnswersByQuestionID,
      updateQuestionAnswerByQuestionID,
    ]
  );

  return (
    <View style={{ height: '100%' }}>
      <Submenu
        title="CSSR Screener Form"
        titleComponent={<View>C-SSRS</View>}
        childComponents={[getSubmenuChildComponent()]}
        bodyStyle={{ padding: 10 }}
        childComponentsWrapperStyle={{ height: '100%' }}
        footerComponent={mode === 'view' ? undefined : renderFooter()}
        onBackAlt={() => {
          handleBackButtonPressed();
        }}
      />
    </View>
  );
};

export default CSSRSScreenerForm;
