import { Button, TextDS, View } from '@talkspace/react-toolkit';
import StarsIcon from '@talkspace/react-toolkit/src/components/icons/StarsIcon';

interface Props {
  onGeneratePress: () => void;
}

export default function SmartInsightsAvailable({ onGeneratePress }: Props) {
  return (
    <>
      <TextDS variant="bodySm" colorRole="textSubtle">
        Smart insights are available!
      </TextDS>

      <View>
        <Button
          text="Generate insights"
          Icon={StarsIcon}
          sizeDS="slim"
          dataQa="generateSmartInsightsButton"
          onPress={onGeneratePress}
          style={{ maxWidth: 200 }}
        />
      </View>
    </>
  );
}
