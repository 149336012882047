import React from 'react';
import Svg, { Path } from 'svgs';

const ThreeDotsIcon = () => (
  <Svg width="21" height="20" viewBox="0 0 21 20" fill="none">
    <Path
      d="M10.8716 13.25C10.4075 13.25 9.96233 13.4344 9.63415 13.7626C9.30596 14.0908 9.12158 14.5359 9.12158 15C9.12158 15.4641 9.30596 15.9092 9.63415 16.2374C9.96233 16.5656 10.4075 16.75 10.8716 16.75C11.3357 16.75 11.7808 16.5656 12.109 16.2374C12.4372 15.9092 12.6216 15.4641 12.6216 15C12.6216 14.5359 12.4372 14.0908 12.109 13.7626C11.7808 13.4344 11.3357 13.25 10.8716 13.25ZM10.8716 8.25C10.4075 8.25 9.96233 8.43437 9.63415 8.76256C9.30596 9.09075 9.12158 9.53587 9.12158 10C9.12158 10.4641 9.30596 10.9092 9.63415 11.2374C9.96233 11.5656 10.4075 11.75 10.8716 11.75C11.3357 11.75 11.7808 11.5656 12.109 11.2374C12.4372 10.9092 12.6216 10.4641 12.6216 10C12.6216 9.53587 12.4372 9.09075 12.109 8.76256C11.7808 8.43437 11.3357 8.25 10.8716 8.25ZM12.6216 5C12.6216 4.53587 12.4372 4.09075 12.109 3.76256C11.7808 3.43437 11.3357 3.25 10.8716 3.25C10.4075 3.25 9.96233 3.43437 9.63415 3.76256C9.30596 4.09075 9.12158 4.53587 9.12158 5C9.12158 5.46413 9.30596 5.90925 9.63415 6.23744C9.96233 6.56563 10.4075 6.75 10.8716 6.75C11.3357 6.75 11.7808 6.56563 12.109 6.23744C12.4372 5.90925 12.6216 5.46413 12.6216 5Z"
      fill="#68768D"
    />
  </Svg>
);

export default ThreeDotsIcon;
