import { useHistory, useParams } from 'react-router';
import { View } from '@talkspace/react-toolkit';

import RiskAndSafetyDialog from './RiskAndSafetyDialog';
import { RiskAndSafetyDialogLocationState } from './types';
import useLocationStateOnMount from '../../../../../hooks/useLocationStateOnMount';

const RiskAndSafetyLeaveDialog = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const history = useHistory();

  const handleConfirm = () => {
    history.push(`/room/${roomID}/clinical-tab`, { section: 'risk-and-safety' });
  };

  const locationState = useLocationStateOnMount<RiskAndSafetyDialogLocationState>();

  const getFormUrl = () => {
    const substringsArr = locationState?.questionnaireType.split('_');

    return `/room/${roomID}/${substringsArr?.join('-')}/create`;
  };
  return (
    <RiskAndSafetyDialog
      title={
        locationState?.questionnaireType === 'cssrs_screener'
          ? 'C-SSRS'
          : 'Stanley-Brown Safety Plan'
      }
      text="Are you sure you want to save?"
      subtextContainer={<View>No changes can be made once this is saved.</View>}
      mainButtonOptions={{
        text: 'Leave',
        onPress: () => {
          handleConfirm();
        },
        dataQa: 'saveRiskAndSafetySaveButton',
      }}
      alternativeButtonOptions={{
        text: 'Stay',
        onPress: () => {
          history.push(`${getFormUrl()}`, locationState);
        },
        dataQa: 'saveRiskAndSafetyCancelButton',
      }}
    />
  );
};

export default RiskAndSafetyLeaveDialog;
