import { Descendant, Node } from 'slate';

export const serialize = (input: Descendant[]): string =>
  input.map((n) => Node.string(n)).join('\n');

export const deserialize = (input: string): Descendant[] =>
  input.split('\n').map((line) => {
    return {
      children: [{ text: line }],
    };
  });

export const MAX_MESSAGE_LENGTH = 3500;
