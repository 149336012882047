import { useMutation } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { QuestionnaireType } from './types';

export interface SendQuestionnaireMessageParams {
  roomID: number;
  questionnaireType: QuestionnaireType;
}

const sendQuestionnaireMessage = async (params: SendQuestionnaireMessageParams): Promise<void> => {
  const { roomID, questionnaireType } = params;
  await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/questionnaires/types/${questionnaireType}/send`,
    {}
  );
};

const useMutationCreateQuestionnaireMessage = () =>
  useMutation<void, Error, SendQuestionnaireMessageParams>(sendQuestionnaireMessage, {});

export default useMutationCreateQuestionnaireMessage;
