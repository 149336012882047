import { useMutation, useQueryClient } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { CreateQuestionnaireParams } from './types';

const createQuestionnaire = async (params: CreateQuestionnaireParams): Promise<void> => {
  const { roomID, userID, answers, questionnaireType } = params;
  await apiWrapper.post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/questionnaires/create`, {
    createdBy: userID,
    roomID,
    type: questionnaireType,
    answers,
  });
};

const useMutationCreateQuestionnaire = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, CreateQuestionnaireParams>(createQuestionnaire, {
    onSuccess: (_, { roomID, questionnaireType }) => {
      queryClient.invalidateQueries([questionnaireType, roomID]);
    },
  });
};

export default useMutationCreateQuestionnaire;
