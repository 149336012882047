import {
  View,
  Large,
  Standard,
  Link,
  BoxArrowIcon,
  useEmotionTheme,
  PlayVideoIcon,
  CloseRoundIcon,
  TouchableView,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useEffect, useMemo, useState } from 'react';
import useQueryTaskList from 'hooks/dashboard/useQueryTaskListV3';
import styled from '../../../core/styled';
import { getUserData } from '../../../utils/token';
import OnBoardingBanner from '../../Reusable/TutorialBanner/OnBoardingBanner';
import Footer from '../../Footer/Footer';
import {
  TASK_LIST_TUTORIAL_VIDEO_URL,
  TASK_LIST_TUTORIAL_COMPLETE,
  DOCUMENTATION_URL,
  getSortedArray,
  FieldType,
  getSortDropdownTitles,
} from './utils';
import tutorialBackgroundImage from './assets/tutorialBackground.png';
import tutorialBackgroundTablet from './assets/tutorialBackgroundTablet.png';
import TasksTable from './components/TaskTable';
import SortDropdown from '../../Reusable/SortDropdown/SortDropdown';
import ssoHelper from '../../../modules/utils/sso';
import storage from '../../../core/storage';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import trackEvent from '../../../modules/analytics/trackEvent';

import ProgressNoteModal from './components/ProgressNoteModal';

const TASK_LIST_SORT_FIELD = 'task_list_sort_field';

const TableTitle = styled(Large)(({ theme: { colors } }) => {
  return {
    fontWeight: 400,
    fontSize: 18,
    color: colors.black,
    marginBottom: 10,
  };
});

const TableSubTitle = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontWeight: 400,
    fontSize: 14,
    color: colors.black,
    marginBottom: -8,
  };
});

const StyledLink = styled(Link)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 700,
    color: colors.accessibilityGreenDark,
    marginLeft: 5,
    textDecoration: 'none',
    '&:hover': {
      color: colors.accessibilityGreenDark,
    },
  };
});

const Container = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      paddingLeft: isMobile ? 15 : 30,
      paddingTop: 32,
      paddingRight: isMobile ? 15 : 30,
    };
  }
);

const Line = styled(View)(({ theme: { colors } }) => {
  return {
    height: 18,
    width: 1,
    background: colors.geyser,
    marginLeft: 5,
    marginRight: 10,
  };
});

const Text = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    marginLeft: 5,
    fontWeight: 700,
    color: colors.accessibilityGreenDark,
    cursor: 'pointer',
    fontSize: 14,
  };
});

const HeaderActions = styled(View)(() => {
  return {
    justifyContent: 'space-between',
    alignItems: 'end',
    flexWrap: 'wrap',
    marginTop: 16,
    gap: 24,
  };
});

const Tasks = () => {
  const { data: tasks, isError, isLoading } = useQueryTaskList(getUserData().id);
  const { colors } = useEmotionTheme();
  const [sortField, setSortField] = useState<FieldType>('oldest');

  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);

  const sortedTasks = useMemo(() => getSortedArray(sortField, tasks as any), [sortField, tasks]);
  const { isLarge, isMobile } = useWindowWidthState();

  const shouldDisplaySortButton = !isLoading && !isError && tasks?.length;

  useEffect(() => {
    setSortField((storage.getItem(TASK_LIST_SORT_FIELD) as FieldType) || 'oldest');
  }, []);

  useDidUpdateEffect(() => {
    if (tasks) {
      trackEvent(
        'tasksAppeared',
        {
          actionName: 'providerTaskInteraction',
        },
        {
          tasks,
          tasksAmount: tasks.length,
        }
      );
    }
  }, [tasks]);

  const handleSort = (value: FieldType) => {
    setSortField(value);
    storage.setItem(TASK_LIST_SORT_FIELD, value);
  };

  const dropDownContent = getSortDropdownTitles();

  const sortButtonTitle = dropDownContent.find((item) => item.id === sortField)?.title || 'Oldest';

  const taskListHeader = (
    <Container>
      <TableTitle>Submit progress notes</TableTitle>
      <TableSubTitle>
        Submit progress notes in a timely manner to ensure that clinical records stay up-to-date.
      </TableSubTitle>
      <HeaderActions row>
        <View row style={{ minWidth: 450 }} align="center">
          <BoxArrowIcon />
          <StyledLink
            target="_blank"
            onClick={() => ssoHelper.openZendesk(DOCUMENTATION_URL)}
            text="Documentation FAQs"
          />
          <Line />
          <PlayVideoIcon />
          <Text onPress={() => setIsVideoModalOpen(true)}>Watch tutorial</Text>
        </View>
        {shouldDisplaySortButton ? (
          <SortDropdown
            buttonTitle={sortButtonTitle}
            dropDownContent={dropDownContent}
            sortField={sortField}
            handleChangeSortField={(field: FieldType) => handleSort(field)}
          />
        ) : null}
      </HeaderActions>
    </Container>
  );

  return (
    <>
      {' '}
      <View>
        <OnBoardingBanner
          primaryButton={{
            title: 'Watch tutorial',
            callback: () => setIsVideoModalOpen(true),
            icon: <PlayVideoIcon color={colors.white} />,
          }}
          secondaryButton={{
            title: "I'll watch later",
            icon: <CloseRoundIcon />,
          }}
          placeholderImage={isLarge ? tutorialBackgroundTablet : tutorialBackgroundImage}
          storageKey={TASK_LIST_TUTORIAL_COMPLETE}
          videoUrl={TASK_LIST_TUTORIAL_VIDEO_URL}
          title="Staying on top of documentation just got easier"
          subtitle='Smart suggestions — like word count and "ready for review" statuses — help you take care of notes efficiently.'
          isVideoModalOpen={isVideoModalOpen}
          onOpenVideoModal={() => setIsVideoModalOpen(true)}
          onCloseVideoModal={() => setIsVideoModalOpen(false)}
        />
        {taskListHeader}
        <Container style={{ paddingTop: isMobile ? 8 : 24 }}>
          <TasksTable tasks={sortedTasks || []} isLoading={isLoading} isError={isError} />
        </Container>
        <Footer hidden={false} />
      </View>
      <ProgressNoteModal />
    </>
  );
};

export default Tasks;
