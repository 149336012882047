import { useCallback, useMemo, useState } from 'react';
import { Editable, Slate, withReact } from 'slate-react';
import { createEditor } from 'slate';
import Lottie from 'react-lottie';
import { Button, styled, TextDS, View } from '@talkspace/react-toolkit';
import { ArrowRotateRight, CircleInfo } from '@talkspace/react-toolkit/src/designSystems/icons';
import { elevation2Style } from '@talkspace/react-toolkit/src/designSystems/styles/elevation';
import { fontWeight } from '@talkspace/react-toolkit/src/designSystems/tokens';
import { useParams } from '@/core/routerLib';
import useMutationCreateSmartInsight from '../../../../hooks/useMutationCreateSmartInsight';
import SmartInsightsError from './SmartInsightsError';
import NotReadyView from './NotReadyView';
import StarLoaderData from '../../../../assets/animations/starLoader.json';
import FeedbackSection from './FeedbackSection';

const SmartInsightsLoading = () => (
  <>
    <Lottie
      options={{ loop: true, autoplay: true, animationData: StarLoaderData }}
      height={66}
      width={66}
    />

    <TextDS variant="bodySm" colorRole="textSubtle" style={{ width: 247, textAlign: 'center' }}>
      Sit back and relax. This can take up to 10 seconds
    </TextDS>
  </>
);

const TooltipView = styled(View)(({ theme: { spacing, colorRoles } }) => {
  return {
    width: 268,
    height: 72,
    padding: spacing('space200'),
    borderRadius: 12,
    position: 'absolute',
    backgroundColor: colorRoles.surfaces.defaultSubtle,
    top: 'auto',
    right: 'auto',
    bottom: 36,
    gap: spacing('space050'),
    ...elevation2Style,
  };
});

const ButtonView = styled(View)({
  position: 'relative',
  width: 'fit-content',
});

const LowerBannerView = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    backgroundColor: colorRoles.surfaces.infoSubtle,
    padding: spacing('space200'),
    gap: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: spacing('space100'),
  };
});

const StyledEditable = styled(Editable)<{ canGenerate: boolean }>(
  ({ canGenerate, theme: { spacing, colorRoles } }) => {
    return {
      outline: 'none',
      backgroundColor: canGenerate
        ? colorRoles.surfaces.neutralSubtleDefault
        : colorRoles.surfaces.surfaceInteractiveDefault,
      borderRadius: spacing('space100'),
      padding: spacing('space150'),
    };
  }
);

const EditorView = styled(View)(({ theme: { spacing, colorRoles } }) => {
  return {
    border: `1px solid ${colorRoles.borders.borderDefault}`,
    borderRadius: spacing('space100'),
  };
});

const HeadingOne = styled('p')<{ canGenerate: boolean }>(
  ({ canGenerate, theme: { spacing, colorRoles } }) => {
    return {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: fontWeight.semibold,
      letterSpacing: 0.123,
      margin: 0,
      marginBottom: spacing('space050'),
      color: canGenerate ? colorRoles.typography.textSubtlest : colorRoles.typography.textDefault,
    };
  }
);

const Paragraph = styled('p')<{ canGenerate: boolean }>(
  ({ canGenerate, theme: { colorRoles } }) => {
    return {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: fontWeight.regular,
      letterSpacing: 0.123,
      color: canGenerate ? colorRoles.typography.textSubtlest : colorRoles.typography.textSubtle,
    };
  }
);

const BulletedList = styled('ul')(({ theme: { spacing } }) => {
  return {
    paddingLeft: spacing('space200'),
  };
});

const ListItem = styled('li')<{ canGenerate: boolean }>(
  ({ canGenerate, theme: { colorRoles } }) => {
    return {
      color: canGenerate ? colorRoles.typography.textSubtlest : colorRoles.typography.textSubtle,
    };
  }
);

interface Props {
  insight: string;
  insightID: number;
  canGenerate: boolean;
}

export default function SmartInsightsReady({ insight, insightID, canGenerate }: Props) {
  const { roomID } = useParams<{ roomID: string }>();

  const [isDisabledButtonTooltipOpen, setIsDisabledButtonTooltipOpen] = useState(false);

  const { mutate: createSmartInsight, status: createInsightStatus } =
    useMutationCreateSmartInsight();

  const handleUpdatePress = () => {
    createSmartInsight({ roomID: Number(roomID) });
  };

  const editor = useMemo(() => withReact(createEditor()), []);

  const insightsJSON = useMemo(() => JSON.parse(insight), [insight]);

  const renderElement = useCallback(
    ({ attributes, children, element }) => {
      switch (element.type) {
        case 'heading-one':
          return (
            <HeadingOne canGenerate={canGenerate} {...attributes}>
              {children}
            </HeadingOne>
          );
        case 'bulleted-list':
          return <BulletedList {...attributes}>{children}</BulletedList>;
        case 'list-item':
          return (
            <ListItem canGenerate={canGenerate} {...attributes}>
              {children}
            </ListItem>
          );
        default:
          return (
            <Paragraph canGenerate={canGenerate} {...attributes}>
              {children}
            </Paragraph>
          );
      }
    },
    [canGenerate]
  );

  const isUpdateButtonDisabled =
    !canGenerate || createInsightStatus === 'loading' || createInsightStatus === 'error';

  return (
    <View rowGap={12}>
      <ButtonView
        onMouseEnter={() => setIsDisabledButtonTooltipOpen(true)}
        onMouseLeave={() => setIsDisabledButtonTooltipOpen(false)}
      >
        <Button
          text="Update insights"
          Icon={() => <ArrowRotateRight colorType="inverse" />}
          sizeDS="slim"
          dataQa="updateInsightsButton"
          onPress={handleUpdatePress}
          disabled={isUpdateButtonDisabled}
          isLoading={createInsightStatus === 'loading'}
          style={{ maxWidth: 200 }}
        />

        {isUpdateButtonDisabled && isDisabledButtonTooltipOpen && (
          <TooltipView>
            <TextDS variant="bodySm">
              Smart Insights can be updated again once you complete a session.
            </TextDS>
          </TooltipView>
        )}
      </ButtonView>

      {(() => {
        if (createInsightStatus === 'loading') {
          return (
            <NotReadyView>
              <SmartInsightsLoading />
            </NotReadyView>
          );
        }

        if (createInsightStatus === 'error') {
          return (
            <NotReadyView>
              <SmartInsightsError onTryAgainPress={handleUpdatePress} />
            </NotReadyView>
          );
        }

        return (
          <EditorView>
            <Slate initialValue={insightsJSON} editor={editor}>
              <StyledEditable canGenerate={canGenerate} readOnly renderElement={renderElement} />
            </Slate>

            <LowerBannerView>
              <View>
                <CircleInfo colorType="brand" />
              </View>
              <TextDS variant="bodyXs">
                This is an AI powered beta feature, so we may not get everything right. Double check
                important info.
              </TextDS>
            </LowerBannerView>
          </EditorView>
        );
      })()}

      <FeedbackSection insightID={insightID} />
    </View>
  );
}
