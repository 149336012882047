import { useEffect } from 'react';
import { Divider, EmotionThemeProvider, styled, TextDS, View } from '@talkspace/react-toolkit';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useParams, useRouteMatch, useHistory, useLocation } from '@/core/routerLib';
import JourneyTab from '../JourneyTab/JourneyTab';
import SmartInsights from './SmartInsights';
import ClientInfo from './ClientInfo';
import RiskInfo from './RiskInfo';

const MainView = styled(View)(({ theme: { spacing } }) => {
  return {
    width: '100%',
    alignSelf: 'flex-start',
    paddingRight: spacing('space200'),
    paddingLeft: spacing('space200'),
  };
});

const ClientInfoAndRiskView = styled(View)(({ theme: { spacing } }) => {
  return {
    paddingTop: spacing('space200'),
    paddingBottom: spacing('space200'),
    gap: spacing('space150'),
  };
});

const RemindersView = styled(View)(({ theme: { spacing } }) => {
  return {
    paddingTop: spacing('space200'),
  };
});

export default function InsightsTab() {
  const { roomID } = useParams<{ roomID: string }>();

  const { url } = useRouteMatch();

  const history = useHistory();

  const location = useLocation();

  const { data: { isSmartInsightsActive } = {}, isLoading } = useQueryRoomDetails(roomID);

  useEffect(() => {
    if (!isLoading && !isSmartInsightsActive) {
      const roomRootURL = url.split('/').slice(0, -1).join('/');
      history.replace(`${roomRootURL}/case-tab`, location.state);
    }
  }, [history, isLoading, isSmartInsightsActive, location.state, url]);

  if (isLoading) {
    return <JourneyTab />;
  }

  return (
    <>
      <EmotionThemeProvider version="2.0.0">
        <MainView>
          <ClientInfoAndRiskView>
            <ClientInfo />

            <RiskInfo />
          </ClientInfoAndRiskView>

          <Divider />

          <SmartInsights />

          <Divider />

          <RemindersView>
            <TextDS variant="headingMd">Reminders</TextDS>
          </RemindersView>
        </MainView>
      </EmotionThemeProvider>

      <JourneyTab />
    </>
  );
}
