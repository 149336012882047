import Svg, { Path } from 'svgs';
import { COLORS } from '@talkspace/react-toolkit';

interface LVSIconProps {
  color?: string;
  width?: number;
  isHovering?: boolean;
  isActive?: boolean;
  height?: number;
  style?: {};
}

const LVSIcon = ({
  isHovering,
  isActive,
  color = isActive ? COLORS.accessibilityGreenDark : COLORS.slateGrey,
  width = 30,
  height = 24,
  ...otherProps
}: LVSIconProps) => {
  const fillColor = isHovering ? '#4D6386' : color;
  return (
    <Svg width={width} height={height} viewBox="0 0 30 24" {...otherProps}>
      <Path
        d={
          isActive
            ? 'M18.08 10.446v8.108c0 .421-.17.803-.447 1.079a1.521 1.521 0 01-1.08.447H8.447c-.421 0-.803-.17-1.079-.447a1.521 1.521 0 01-.447-1.08v-8.107c0-.421.17-.803.447-1.079a1.521 1.521 0 011.08-.447h8.107c.421 0 .803.17 1.079.447.276.276.447.658.447 1.08zm7 .996v6.117a.578.578 0 01-.872.5l-3.259-1.897a.058.058 0 01-.029-.05v-3.223c0-.02.011-.04.029-.05l3.26-1.899a.578.578 0 01.439-.06c.405.358.432.46.432.562z'
            : 'M16.554 8A2.446 2.446 0 0119 10.446v8.108A2.446 2.446 0 0116.554 21H8.446A2.446 2.446 0 016 18.554v-8.108A2.446 2.446 0 018.446 8h8.108zm0 1.84H8.446a.606.606 0 00-.598.508l-.008.098v8.108c0 .301.22.551.508.598l.098.008h8.108c.301 0 .551-.22.598-.508l.008-.098v-8.108a.606.606 0 00-.606-.606zm9.242.847c.134.229.204.49.204.755v6.117a1.5 1.5 0 01-1.356 1.493l-.144.007a1.5 1.5 0 01-.755-.204l-3.259-1.898A.978.978 0 0120 16.11V12.89c0-.348.185-.67.486-.846l3.26-1.898c.21-.122.438-.188.665-.201l.137-.002c.499.017.978.282 1.248.745zm-1.637 1.346l-2.319 1.351v2.231l2.319 1.351v-4.933z'
        }
        stroke={isActive ? fillColor : 'none'}
        strokeWidth={1.84000003}
        fill={isHovering ? '#4D6386' : color}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default LVSIcon;
