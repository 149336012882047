import { COLORS } from '@talkspace/react-toolkit/src/designSystems';
import Svg, { Circle, Path } from 'svgs';

interface JourneyIconProps {
  color?: string;
  width?: number;
  isHovering?: boolean;
  isActive?: boolean;
  height?: number;
  showBadge?: boolean;
  style?: {};
}

const JourneyIcon = ({
  isHovering,
  isActive,
  color = isActive ? COLORS.accessibilityGreenDark : COLORS.slateGrey,
  width = 30,
  height = 24,
  showBadge = false,
  ...otherProps
}: JourneyIconProps) => (
  <Svg width={width} height={height} viewBox="0 0 30 24" {...otherProps}>
    <Path
      d="M5.418 17.95a1.418 1.418 0 11-.002 2.836 1.418 1.418 0 01.002-2.836zm17.251.068c.603 0 1.109.464 1.17 1.06l.006.12-.006.122a1.175 1.175 0 01-1.05 1.054l-.12.006H10.036a1.183 1.183 0 01-1.176-1.181c0-.616.463-1.115 1.056-1.175l.12-.006h12.633zM5.418 11.875a1.418 1.418 0 11-.002 2.836 1.418 1.418 0 01.002-2.836zm17.251.236c.603 0 1.109.465 1.17 1.06l.006.122-.006.121a1.175 1.175 0 01-1.05 1.054l-.12.006H10.036a1.183 1.183 0 01-1.176-1.181c0-.616.463-1.116 1.056-1.176l.12-.006h12.633zM5.418 5.8a1.418 1.418 0 11-.002 2.836A1.418 1.418 0 015.418 5.8zm17.251.405c.603 0 1.109.465 1.17 1.06l.006.121-.006.122a1.175 1.175 0 01-1.05 1.053l-.12.007H10.036A1.183 1.183 0 018.86 7.386c0-.616.463-1.115 1.056-1.175l.12-.006h12.633z"
      fill={color}
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
    {showBadge && (
      <Circle cx="24" cy="5" r="5.5" fill={COLORS.red700} stroke="white" strokeWidth="3" />
    )}
  </Svg>
);

export default JourneyIcon;
