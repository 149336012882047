import {
  AnimatedSwipeDownModal,
  Tag,
  TooltipV2,
  View,
  useEmotionTheme,
  useWindowWidthState,
  TagColorName,
  EmotionThemeProvider,
} from '@talkspace/react-toolkit';
import QuestionRound from '@talkspace/react-toolkit/src/components/Svgs/QuestionRound';
import { useCallback, useState } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { AccountType } from 'ts-frontend/entities/Room';
import styled from '../../../../core/styled';
import { getSubmissionStatus } from '../../../Room/CRMContainer/NotesTab/utils';
import { SessionStatus } from '../../../../hooks/dashboard/useQueryTaskListV3';
import Tooltip from '../../../Room/CRMContainer/NotesTab/CTA/Tooltip';
import { getShouldDisplayTooltip } from '../utils';
import { SessionSubmissionStatus } from '../../../../hooks/notes/useQueryUnsubmittedSessions';

interface SessionStatusCellProps {
  sessionStatus: SessionStatus;
  wordCount?: number | null;
  wordDuration?: number | null;
  roomID: number;
  clientUserID: number;
  sessionModality: 'live' | 'messaging';
  minSessionWords?: number | null;
  maxSessionWords?: number | null;
  accountType: AccountType;
  isHovering: boolean;
}

const Text = styled(View)(({ theme: { colors } }) => {
  return {
    color: colors.forestGreen,
    fontSize: 14,
    fontWeight: 700,
    marginRight: 6,
    marginLeft: 6,
  };
});

const Container = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: isMobile && 11,
      height: !isMobile && 30,
      display: 'flex',
      justifyContent: 'center',
    };
  }
);

const IconContainer = styled(View)(() => {
  return {
    height: 15,
    width: 20,
    marginTop: 3,
  };
});

const containerTooltipStyle = {
  padding: '15px 20px',
  width: 350,
  left: -216,
  top: 22,
};

const SessionStatusCell = ({
  sessionStatus,
  wordCount,
  wordDuration,
  roomID,
  clientUserID,
  sessionModality,
  minSessionWords,
  maxSessionWords,
  accountType,
  isHovering,
}: SessionStatusCellProps) => {
  const { colors } = useEmotionTheme();
  const { isMobile, isLarge } = useWindowWidthState();
  const [isStatusHovering, setIsStatusHovering] = useState<boolean>(false);
  const sessionStatusData = getSubmissionStatus(sessionStatus, colors);
  const shouldDisplayTooltip = getShouldDisplayTooltip({
    sessionModality,
    accountType,
    submissionStatus: sessionStatus,
  });

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { background, color, text } = sessionStatusData;

  const {
    calculatingAndCommunicatingAsyncEngagement: { featureOn: shouldShowMinutesOnly },
  } = useFlags();

  const isNotNullOrUndefined = (val: any) => val !== null && typeof val !== 'undefined';

  const getWordCountText = useCallback(
    () =>
      shouldShowMinutesOnly
        ? isNotNullOrUndefined(wordDuration) && `${wordDuration!.toString()} min`
        : isNotNullOrUndefined(wordCount) && `${wordCount!.toString()} words`,
    [shouldShowMinutesOnly, wordCount, wordDuration]
  );

  return (
    <EmotionThemeProvider version="2.0.0">
      <Container
        onMouseEnter={() => setIsStatusHovering(true)}
        onMouseLeave={() => setIsStatusHovering(false)}
        onClick={() => {
          isMobile && shouldDisplayTooltip && setIsMenuOpen((prev) => !prev);
        }}
      >
        {sessionStatusData && (
          <View row>
            {background && color && text && (
              <View onClick={(e) => e.preventDefault()}>
                <Tag text={text} color={background as TagColorName} />
              </View>
            )}
            <Text>{getWordCountText()}</Text>
            {shouldDisplayTooltip && (isHovering || isLarge) && (
              <TooltipV2
                circleColor={colors.permaWildBlueYonder}
                questionMarkColor={colors.white}
                styles={{ height: 15, width: 15, marginTop: 4 }}
                isTooltipOpen={isStatusHovering}
                toolTipText={
                  <Tooltip
                    roomID={roomID.toString()}
                    submissionStatus={sessionStatus as SessionSubmissionStatus}
                    accountType={accountType}
                    clientUserID={clientUserID}
                    minSessionWords={minSessionWords}
                    maxSessionWords={maxSessionWords}
                  />
                }
                containerStyle={containerTooltipStyle}
              />
            )}
            {isMobile && shouldDisplayTooltip && (
              <IconContainer>
                <QuestionRound />
              </IconContainer>
            )}
          </View>
        )}
      </Container>

      <AnimatedSwipeDownModal
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        closeModalText="close"
      >
        <Tooltip
          roomID={roomID.toString()}
          submissionStatus={sessionStatus as SessionSubmissionStatus}
          accountType={accountType}
          clientUserID={clientUserID}
          minSessionWords={minSessionWords}
          maxSessionWords={maxSessionWords}
        />
      </AnimatedSwipeDownModal>
    </EmotionThemeProvider>
  );
};

export default SessionStatusCell;
