import GenericProgressBar from '@talkspace/react-toolkit/src/components/GenericProgressBar/GenericProgressBar';
import useMonthlyBonusGraphDataSetup from 'hooks/monthlyBonus/useMonthlyBonusGraphDataSetup';
import { View, useEmotionTheme, useWindowWidthState } from '@talkspace/react-toolkit';
import { useCallback, useState } from 'react';
import HorizontalScroll from '@talkspace/react-toolkit/src/components/HorizontalScroll/HorizontalScroll';
import { MonthlyBonusMappingItem } from 'hooks/monthlyBonus/useQueryMonthlyBonus';
import styled from '../../core/styled';
import GraphExplanation from './GraphExplanation';
import { PaymentCube } from './BasePayAndBonusPartitionSection';
import BonusBreakdown from './BonusBreakdown';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import MilestoneContainer, { Offset } from './Milestone';

const MOBILE_HORIZONTAL_SCROLL_BOX_WIDTH = 260;
const TABLET_HORIZONTAL_SCROLL_BOX_WIDTH = 560;
const DEFAULT_GRAPH_WIDTH = 748;
const MOBILE_GRAPH_WIDTH = 715;
const TABLET_GRAPH_WIDTH = 770;

const CUBE_WIDTH = 169;

const ExplanationsWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      height: isMobile ? 'auto' : '100%',
      display: 'flex',
      justifyContent: 'center',
      width: isMobile ? '100% ' : `calc(90% - ${CUBE_WIDTH}px)`,
      paddingLeft: isMobile ? 16 : 0,
      paddingRight: isMobile ? 16 : 0,
      flexWrap: 'wrap',
      textAlign: 'left',
    };
  }
);

const CubeAndExplanationsWrapper = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: 'center',
      borderBottom: `1px solid ${colors.permaLondonGray}`,
      paddingBottom: 30,
    };
  }
);

interface ModalGraphWidgetProps {
  year: number;
  month: number;
  modalOffset: Offset;
  isBonusModalScrolling: boolean;
}
const ModalGraphWidget = ({
  year,
  month,
  modalOffset,
  isBonusModalScrolling,
}: ModalGraphWidgetProps) => {
  const {
    isMobile = false,
    isSmall = false,
    isMedium = false,
    isLarge = false,
  } = useWindowWidthState();
  const isTabletAndBelow = isSmall || isMedium || isLarge;
  const [showMarkedPercentage, setShowMarkedPercentage] = useState<boolean>(true);

  const getHorizontalScrollBoxWidth = useCallback(() => {
    if (isMobile) {
      return MOBILE_HORIZONTAL_SCROLL_BOX_WIDTH;
    }
    if (isTabletAndBelow) {
      return TABLET_HORIZONTAL_SCROLL_BOX_WIDTH;
    }
    return 0;
  }, [isMobile, isTabletAndBelow]);

  const getGraphWidth = useCallback(() => {
    if (isMobile) {
      return MOBILE_GRAPH_WIDTH;
    }
    if (isTabletAndBelow) {
      return TABLET_GRAPH_WIDTH;
    }
    return DEFAULT_GRAPH_WIDTH;
  }, [isMobile, isTabletAndBelow]);
  const horizontalScrollBoxWidth = getHorizontalScrollBoxWidth();
  const graphWidth = getGraphWidth();
  const { colors } = useEmotionTheme();

  const {
    monthlyBonusData,
    updatedBars,

    markedPercentages,
    shouldResetHorizontalScrollOffset,
    setShouldResetHorizontalScrollOffset,
    setHorizontalScrollBoxOffset,
    setHorizontalScrollBoxOffsetUpdated,
    horizontalScrollStartPercentage,
    horizontalScrollEndPercentage,
    ...otherProps
  } = useMonthlyBonusGraphDataSetup({
    horizontalScrollBoxWidth,
    graphWidth,
    year,
    month,
  });

  const bonus = monthlyBonusData?.monthlyBonus.bonus;

  const getCubeStyle = useCallback(() => {
    return {
      width: CUBE_WIDTH,
      height: 116,
      borderRadius: 7,
      padding: 30,
      marginRight: 30,
      marginTop: 8,
      ...(isMobile
        ? {
            width: 'calc(100% - 32px)',
            marginLeft: 16,
            marginRight: 16,
            height: 52,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }
        : {}),
      ...(bonus
        ? { color: colors.forestGreen, background: colors.alabaster }
        : {
            color: colors.permaLividBlueNew,
            background: 'white',
            border: `1px dashed ${colors.permaLividBlueNew}`,
          }),
    };
  }, [bonus, colors.alabaster, colors.forestGreen, colors.permaLividBlueNew, isMobile]);

  const getBonusModalGraphMilestoneProps = useCallback(
    (it: MonthlyBonusMappingItem, index: number) => {
      return {
        percentage: it.percentage,
        bonusAmount: it.bonusAmount,
        bonusTotalHours: it.bonusTotalHours,
        index,

        isLargeMilestoneInformationStyle: false,
        setShowMarkedPercentage,
        ...otherProps,
      };
    },
    [otherProps]
  );

  const getHorizontalScrollProps = useCallback(() => {
    return {
      shouldResetHorizontalScrollOffset,
      shutDownReset: () => {
        setShouldResetHorizontalScrollOffset(false);
      },
      scrollBoxWidth: horizontalScrollBoxWidth,
      numberOfSteps:
        (monthlyBonusData?.monthlyBonusMapping?.length &&
          monthlyBonusData?.monthlyBonusMapping.length - 1) ||
        10,
      graphBoxDefaultOffsetPercentage:
        monthlyBonusData?.monthlyBonus.graphBoxDefaultOffsetPercentage || 0,
      contentWidth: graphWidth,
      handleOnScroll: (offset) => {
        setHorizontalScrollBoxOffset(offset);
        setHorizontalScrollBoxOffsetUpdated(true);
      },
    };
  }, [
    graphWidth,
    horizontalScrollBoxWidth,
    monthlyBonusData?.monthlyBonus.graphBoxDefaultOffsetPercentage,
    monthlyBonusData?.monthlyBonusMapping.length,
    setHorizontalScrollBoxOffset,
    setHorizontalScrollBoxOffsetUpdated,
    setShouldResetHorizontalScrollOffset,
    shouldResetHorizontalScrollOffset,
  ]);

  return (
    <>
      {monthlyBonusData?.monthlyBonusMapping && (
        <View>
          {isMobile ? (
            <PaymentCube text="Bonus earned" amount={bonus || 0} style={getCubeStyle()} />
          ) : (
            <CubeAndExplanationsWrapper>
              <PaymentCube text="Bonus earned" amount={bonus || 0} style={getCubeStyle()} />

              <ExplanationsWrapper>
                {monthlyBonusData?.monthlyBonus?.explanations &&
                  Object.entries<string>(monthlyBonusData.monthlyBonus.explanations as any).map(
                    ([key, value]) => <GraphExplanation iconType={key} text={value} />
                  )}
              </ExplanationsWrapper>
            </CubeAndExplanationsWrapper>
          )}

          {horizontalScrollBoxWidth ? (
            <View style={{ alignItems: isMobile ? 'center' : 'left' }}>
              <HorizontalScroll {...getHorizontalScrollProps()}>
                <GenericProgressBar
                  markedPercentages={showMarkedPercentage ? markedPercentages : []}
                  width={graphWidth}
                  bars={updatedBars}
                  milestones={monthlyBonusData.monthlyBonusMapping.map((it, index) => {
                    const milestoneProps = {
                      ...getBonusModalGraphMilestoneProps(it, index),
                      horizontalScrollPercentage: {
                        start: horizontalScrollStartPercentage || 0,
                        end: horizontalScrollEndPercentage || 100,
                      },
                    };

                    return {
                      ...it,
                      milestoneComponent: (
                        <MilestoneContainer
                          tooltipText={it.tooltipText}
                          milestoneProps={milestoneProps}
                          modalOffset={modalOffset}
                          isParentScrolling={isBonusModalScrolling}
                        />
                      ),
                    };
                  })}
                />
              </HorizontalScroll>
            </View>
          ) : (
            <GenericProgressBar
              markedPercentages={showMarkedPercentage ? markedPercentages : []}
              width={graphWidth}
              bars={updatedBars}
              milestones={monthlyBonusData.monthlyBonusMapping.map(
                (it: MonthlyBonusMappingItem, index: number) => {
                  const milestoneProps = getBonusModalGraphMilestoneProps(it, index);

                  return {
                    ...it,
                    milestoneComponent: (
                      <MilestoneContainer
                        tooltipText={it.tooltipText}
                        milestoneProps={milestoneProps}
                        modalOffset={modalOffset}
                      />
                    ),
                  };
                }
              )}
            />
          )}
          {isMobile && (
            <ExplanationsWrapper>
              {monthlyBonusData?.monthlyBonus?.explanations &&
                Object.entries<string>(monthlyBonusData.monthlyBonus.explanations as any).map(
                  ([key, value]) => <GraphExplanation iconType={key} text={value} />
                )}
            </ExplanationsWrapper>
          )}

          {monthlyBonusData?.monthlyBonus && (
            <BonusBreakdown
              {...monthlyBonusData.monthlyBonus.earningsBreakdown.messagingSession}
              timeInLiveSession={monthlyBonusData.monthlyBonus.earningsBreakdown.timeInLiveSession}
              hoursAchieved={monthlyBonusData.monthlyBonus.hoursAchieved}
            />
          )}
          <View style={{ marginLeft: isMobile ? 16 : 0 }}>
            <FrequentlyAskedQuestions year={year} month={month} />
          </View>
        </View>
      )}
    </>
  );
};

export default ModalGraphWidget;
