import { TextDS, View, Image, useWindowWidthState } from '@talkspace/react-toolkit';
import ButtonV2 from '@talkspace/react-toolkit/src/designSystems/components/Button/ButtonV2';
import React from 'react';
import styled from '@/core/styled';

interface CalendarProviderProps {
  title: string;
  syncedEmail?: string;
  subtitle: string;
  isConnected: boolean;
  logoSrc: string;
  isLoading: boolean;
  shouldReconnect: boolean;
  handleConnect: () => void;
  handleDisconnect: () => void;
  handleReconnect: () => void;
}

const Container = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    marginTop: spacing('space300'),
    marginBottom: spacing('space300'),
    backgroundColor: colorRoles.surfaces.surfaceInteractiveDefault,
    padding: '12px 16px',
    border: `1px solid ${colorRoles.borders.borderDefault}`,
    borderRadius: spacing('space100'),
    gap: spacing('space200'),
    display: 'flex',
    flexDirection: 'column',
  };
});

const StyledButton = styled(ButtonV2)(({ theme: { window: isMobile } }) => {
  return {
    alignSelf: 'end',
    padding: '10px 12px',
    minWidth: 104,
  };
});

const Row = styled(View)(({ theme: { spacing } }) => {
  return {
    display: 'flex',
    gap: spacing('space200'),
  };
});

const CalendarProviderButton = React.memo(
  ({
    isConnected,
    shouldReconnect,
    handleConnect,
    handleDisconnect,
    handleReconnect,
    isLoading,
  }: Omit<CalendarProviderProps, 'title' | 'syncedEmail' | 'subtitle' | 'logoSrc'>) => {
    if (shouldReconnect) {
      return (
        <StyledButton
          onPress={handleReconnect}
          text="Reconnect"
          sizeDS="medium"
          variant="secondary"
          isLoading={isLoading}
          disabled={isLoading}
          stretch={false}
        />
      );
    }
    if (isConnected) {
      return (
        <StyledButton
          onPress={handleDisconnect}
          text="Disconnect"
          sizeDS="medium"
          variant="secondary"
          isLoading={isLoading}
          disabled={isLoading}
          stretch={false}
        />
      );
    }
    return (
      <StyledButton
        onPress={handleConnect}
        text="Connect"
        sizeDS="medium"
        isLoading={isLoading}
        disabled={isLoading}
        stretch={false}
      />
    );
  }
);

const CalendarProvider = ({
  title,
  syncedEmail,
  subtitle,
  isConnected,
  logoSrc,
  handleConnect,
  handleDisconnect,
  isLoading,
  shouldReconnect,
  handleReconnect,
}: CalendarProviderProps) => {
  const { isMobile } = useWindowWidthState();

  return (
    <Container>
      <Row row={!isMobile} justify="space-between">
        <Row row>
          <Image src={logoSrc} alt="calendar logo" height={40} width={40} />
          <View>
            <TextDS variant="body">{title}</TextDS>
            <TextDS variant="bodySm">{syncedEmail || subtitle}</TextDS>
          </View>
        </Row>
        <CalendarProviderButton
          isConnected={isConnected}
          shouldReconnect={shouldReconnect}
          handleConnect={handleConnect}
          handleDisconnect={handleDisconnect}
          handleReconnect={handleReconnect}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};

export default CalendarProvider;
