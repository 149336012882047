import { EmotionThemeProvider, Select, useEmotionTheme, View } from '@talkspace/react-toolkit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Footer from 'components/Reusable/Footer/Footer';
import Button from '@talkspace/react-toolkit/src/components/Button';
import { useHistory, useLocation, useParams } from '../../../../../core/routerLib';
import Submenu from '../../../../Reusable/Submenu';
import useQueryQuestionnaireQuestions, {
  Question,
} from '../../../Questionnaire/useQueryQuestionnaireQuestions';
import { QuestionnaireAnswerData } from '../../../Questionnaire/types';
import { RiskAndSafetyDialogLocationState } from './types';
import useQueryQuestionnaires from '../../../Questionnaire/useQueryQuestionnaires';

type SelectedOptions = Array<{ label: string; value: string }>;

const SafetyPlanForm = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const location = useLocation<RiskAndSafetyDialogLocationState | undefined>();
  const history = useHistory();

  const { data: safetyPlanQuestions } = useQueryQuestionnaireQuestions({
    roomID: Number(roomID),
    questionnaireType: 'safety_plan',
  });

  const { data: safetyPlan } = useQueryQuestionnaires({
    roomID: Number(roomID),
    questionnaireTypes: ['safety_plan'],
  });

  const [answers, setAnswers] = useState<Array<QuestionnaireAnswerData>>([]);

  const isNotFirstSafetyPlan = useMemo(
    () =>
      safetyPlan?.[0] &&
      safetyPlan[0].firstInstanceCreatedAt &&
      safetyPlan[0].createdAt &&
      safetyPlan[0].firstInstanceCreatedAt !== safetyPlan[0]?.createdAt,
    [safetyPlan]
  );

  useEffect(() => {
    if (location?.state?.answers) {
      setAnswers(location.state.answers);
    } else if (safetyPlan?.length) {
      const formattedAnswers = safetyPlan
        .find((it) => it.type === 'safety_plan')
        ?.questionnaireAnswers.map((answer) => {
          return {
            questionID: answer.questionnaireQuestionID,
            options: answer?.questionnaireQuestionOptions?.map((option) => option.id) || [],
          };
        });
      setAnswers(formattedAnswers || []);
    }
  }, [location?.state?.answers, safetyPlan]);

  const handleBackButtonPressed = useCallback(() => {
    history.push(`/room/${roomID}/risk-and-safety/leave`, {
      questionnaireType: 'safety_plan',
      title: 'Stanley-Brown Safety Plan',
      answers,
    });
  }, [answers, history, roomID]);

  const parseQuestionAnswers = useCallback(
    (question: Question) => {
      const answer = answers.find(({ questionID }) => question.id === questionID);
      if (answer) {
        return (
          answer.options?.map((option) => {
            const optionWithText = question.questionnaireQuestionOptions.find(
              (it) => it.id === option
            );
            return {
              label: optionWithText?.text || '',
              value: option.toString(),
            };
          }) || []
        );
      }
      return [];
    },
    [answers]
  );

  const updateQuestionAnswerByQuestionID = useCallback(
    ({ selectedOptions, id }: { selectedOptions: SelectedOptions; id: number }) => {
      const answersClone = [...answers];
      const answer = answersClone?.find(({ questionID }) => id === questionID);

      if (!answer) {
        setAnswers([
          ...answers,
          {
            questionID: id,
            options:
              (selectedOptions as SelectedOptions)?.map((option) => Number(option.value)) || [],
          },
        ]);
      } else if (selectedOptions === null) {
        setAnswers(answersClone.filter(({ questionID }) => id !== questionID));
      } else {
        answer.options =
          (selectedOptions as SelectedOptions)?.map((option) => Number(option.value)) || [];
        setAnswers(answersClone);
      }
    },
    [answers]
  );

  const { colors } = useEmotionTheme();

  const renderFooter = useCallback(
    () => (
      <Footer
        childComponents={[
          <View style={{ justifyContent: 'end', padding: 15 }}>
            <EmotionThemeProvider version="2.0.0">
              <Button
                variant="tertiary"
                text="Save"
                style={{ color: colors.permaBlueStoneNew, fontWeight: 600, fontSize: 16 }}
                onPress={() => {
                  history.push(`/room/${roomID}/risk-and-safety/save`, {
                    questionnaireType: 'safety_plan',
                    title: 'Stanley-Brown Safety Plan',
                    answers: (() => {
                      if (!safetyPlanQuestions) {
                        return [];
                      }
                      const newAnswers = answers.filter((it) => {
                        const answersExistInQuestions = safetyPlanQuestions
                          .map((question) => question.id)
                          .includes(it.questionID);
                        return answersExistInQuestions;
                      });
                      return newAnswers;
                    })(),
                  });
                }}
              />
            </EmotionThemeProvider>
          </View>,
        ]}
        isError={false}
        isUpdating={false}
        showSpinner={false}
        spinnerMessage=""
      />
    ),
    [answers, colors.permaBlueStoneNew, history, roomID, safetyPlanQuestions]
  );

  const getSubmenuChildComponent = useCallback(
    () => (
      <View style={{ color: colors.permaSlateGrayApprox, fontSize: 14 }}>
        {!!(safetyPlan && safetyPlan.length > 0) && !!isNotFirstSafetyPlan && (
          <View style={{ alignSelf: 'center' }}>
            {safetyPlan &&
              safetyPlan?.[0]?.createdAt &&
              `Created ${moment(safetyPlan[0]?.firstInstanceCreatedAt).format('MMM DD, YYYY')}`}
          </View>
        )}
        <View style={{ alignSelf: 'center' }}>
          {safetyPlan &&
            safetyPlan?.[0]?.createdAt &&
            `${isNotFirstSafetyPlan ? 'Edited' : ''} ${moment(safetyPlan[0].createdAt).format(
              isNotFirstSafetyPlan ? 'MMM DD' : 'MMM DD, YYYY'
            )}`}
        </View>
        {safetyPlanQuestions
          ?.sort((a, b) => a.order - b.order)
          ?.map((question, i) => (
            <View style={{ marginBottom: 10 }}>
              <Select
                isMulti
                placeholder={`${question.text}`}
                isSearchable
                value={parseQuestionAnswers(question)} // need to deparse
                options={question.questionnaireQuestionOptions.map((option) => {
                  return { label: option.text, value: option.id.toString() };
                })}
                onChange={(selectedOptions) => {
                  updateQuestionAnswerByQuestionID({
                    selectedOptions: selectedOptions as SelectedOptions,
                    id: question.id,
                  });
                }}
              />
              <View style={{ marginTop: 10, fontSize: 12 }}>{`${question.description}`}</View>
            </View>
          ))}
      </View>
    ),
    [
      colors.permaSlateGrayApprox,
      isNotFirstSafetyPlan,
      parseQuestionAnswers,
      safetyPlan,
      safetyPlanQuestions,
      updateQuestionAnswerByQuestionID,
    ]
  );

  return (
    <View style={{ height: '100%' }}>
      <Submenu
        title="CSSR Screener Form"
        titleComponent={<View>Stanley-Brown Safety Plan</View>}
        childComponents={[getSubmenuChildComponent()]}
        bodyStyle={{ padding: 10 }}
        childComponentsWrapperStyle={{ height: '100%' }}
        footerComponent={renderFooter()}
        onBackAlt={() => {
          handleBackButtonPressed();
        }}
      />
    </View>
  );
};

export default SafetyPlanForm;
