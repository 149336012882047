import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './MessageContainer.css';
import { useWindowWidthState } from '@talkspace/react-toolkit';
import { getNavBarHeight } from 'components/Reusable/NavBar/NavBar';
import { getUserData } from '../../../utils/token';
import TherapistChat from '../../../modules/therapistChat';
import storage from '../../../core/storage';

const TherapistChatFC = ({ therapistType, shouldBlockRoom = false }) => {
  const { height, isMobile } = useWindowWidthState();
  return (
    <TherapistChat
      therapistType={therapistType}
      stopInputAutofocus={!!shouldBlockRoom}
      style={{ height: height - getNavBarHeight(isMobile), position: 'relative' }}
    />
  );
};

class TherapistMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: getUserData(),
    };
    this.tokenNotify = null;
  }

  componentDidMount() {
    this.startTokenIntervalCheck();
  }

  componentWillUnmount() {
    this.removeTokenIntervalCheck();
  }

  notifyChatIframeOfToken() {
    const token = storage.getItem('id_token');
    const iframe = document.querySelector('#messagesContainerFrame');
    if (iframe && iframe.contentWindow && iframe.contentWindow.postMessage) {
      try {
        iframe.contentWindow.postMessage(
          { type: 'refreshToken', token },
          storage.getItem('roomUrl')
        );
      } catch (error) {
        // do not log
      }
    }
  }

  removeTokenIntervalCheck() {
    clearInterval(this.tokenNotify);
  }

  startTokenIntervalCheck() {
    this.tokenNotify = setInterval(() => {
      this.notifyChatIframeOfToken();
    }, 10000);
  }

  render() {
    const { roomID, therapistType } = this.props;

    if (!roomID) return null;

    return (
      <div>
        <TherapistChatFC therapistType={therapistType} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    journey: state.journey,
    conditions: state.clinicalInformation.conditions,
    therapistType: state.availability.therapistType,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const MessageContainer = connect(mapStateToProps, mapDispatchToProps)(TherapistMessages);

export default MessageContainer;
