import { useState } from 'react';
import {
  Link,
  styled,
  Tag,
  TextDS,
  View,
  useWindowWidthState,
  AnimatedSwipeDownModal,
  TouchableView,
} from '@talkspace/react-toolkit';
import { elevation2Style } from '@talkspace/react-toolkit/src/designSystems/styles/elevation';
import ssoHelper from '@/utils/sso';

const HoverableText = styled(TouchableView)<{ isHovering: boolean }>(
  ({ isHovering, theme: { colorRoles } }) => {
    return {
      textDecoration: 'underline dotted',
      backgroundColor: isHovering ? colorRoles.surfaces.infoSubtle : 'unset',
      cursor: 'pointer',
      display: 'inline-block',
    };
  }
);

const TooltipView = styled(View)(({ theme: { spacing, colorRoles } }) => {
  return {
    width: 293,
    height: 137,
    padding: spacing('space200'),
    borderRadius: 12,
    position: 'absolute',
    backgroundColor: colorRoles.surfaces.defaultSubtle,
    top: 'auto',
    right: 'auto',
    bottom: 20,
    gap: spacing('space050'),
    ...elevation2Style,
  };
});

const TooltipTitleView = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
});

const StyledLink = styled(Link)(({ theme: { colorRoles } }) => {
  return {
    margin: 0,
    fontWeight: 'bold',
    color: colorRoles.typography.textBrandDefault,
    fontSize: 14,
    textDecoration: 'underline solid',
  };
});

const TooltipContent = () => (
  <>
    <TooltipTitleView>
      <TextDS variant="headingMd">New! Smart Insights</TextDS>
      <Tag text="Beta" color="decorativeGratitudeGreen" />
    </TooltipTitleView>

    <TextDS variant="bodySm">
      We use HIPAA-compliant technology to draft a summary of your client based on assessment data
      and previous sessions
    </TextDS>

    <StyledLink
      onClick={() => ssoHelper.openZendesk('hc/en-us/articles/31304197952795')}
      text="How does this work?"
      target="_blank"
      dataQa="wowDoesThisWorkLink"
    />
  </>
);

const AnnouncementTooltip = () => {
  const { isMobile } = useWindowWidthState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <View style={{ display: 'inline-block', position: 'relative' }}>
      <HoverableText
        isHovering={isModalOpen}
        onPress={() => setIsModalOpen(true)}
        onMouseEnter={() => !isMobile && setIsModalOpen(true)}
        onMouseLeave={() => !isMobile && setIsModalOpen(false)}
      >
        <TextDS variant="bodySm" colorRole="textSubtle">
          Smart Insights
        </TextDS>
      </HoverableText>

      {isMobile ? (
        <AnimatedSwipeDownModal
          closeModalText="close"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <TooltipContent />
        </AnimatedSwipeDownModal>
      ) : (
        <>
          {isModalOpen && (
            <TooltipView
              onMouseEnter={() => setIsModalOpen(true)}
              onMouseLeave={() => setIsModalOpen(false)}
            >
              <TooltipContent />
            </TooltipView>
          )}
        </>
      )}
    </View>
  );
};

export default AnnouncementTooltip;
